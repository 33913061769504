import React from 'react'
import {Context, ModelImageKnob, ModelToggleKnob} from '../ImageKnob'
import Needle from '../Needle'
import Overlay from '../Overlay'
import {mobx, px} from '../../utils'
import Store from '../../store'
import {Device} from '../../api'

const deflect = 0.758131701

export default mobx(
    class DistopikVCAMono extends React.Component<{ id: string; uiScale: number; store: Store, device: Device }> {
        gr?: Needle
        lastVal: any = null

        static renderTitle() {
            return 'Audio Destruction Unit'
        }

        componentDidMount() {
            this.props.store.on('playingAnimation', this.updateAnimation)
        }

        componentWillUnmount() {
            this.props.store.off('playingAnimation', this.updateAnimation)
        }

        updateAnimation = () => {
            const { measurements, position: pos } = this.props.store.player
            const m = measurements[`${this.props.id}.leftBiasMeter`]

            try {
                let val = m.getValueWithSmoothing(pos) / 1.35
                // val = Math.min((Math.max(0, 1320 / 0xfff - val) / (1 - 1320 / 0xfff)) * 2, 1)

                if (this.gr) {
                    this.gr.position = val
                }
            } catch (e) {
            }
        }

        changeToDual = () => {
            this.props.store.setSlotSettings([
                { parameter: 'linkPairs', processor: this.props.id, value: 0 },
            ], true)
        }

        toggleDual = () => {
            const { session } = this.props.store.player
            if (session) {
                const { settings } = session.slots[this.props.store.selectedSlotIndex]

                this.props.store.setSlotSettings([
                    { parameter: 'linkPairs', processor: this.props.id, value: settings[this.props.id].linkPairs ? 0 : 1 },
                ], true)
            }
        }

        changeToSingle = () => {
            this.props.store.setSlotSettings([
                { parameter: 'linkPairs', processor: this.props.id, value: 1 },
            ], true)
        }

        render() {
            const { uiScale = 0.5, store, id } = this.props
            const { api } = store

            return (
                <Context.Provider value={{ api, id } as any}>
                    <div
                        onClick={e => e.preventDefault()}
                        onDragStart={e => e.preventDefault()}
                        style={{
                            transform: `scale(${uiScale}, ${uiScale})`,
                            transformOrigin: 'left top',
                            margin: 0,
                            padding: 0,
                            width: px(1414 * uiScale),
                            height: px(456 * uiScale),
                        }}
                    >
                        <div
                            onClick={e => e.preventDefault()}
                            onDoubleClick={e => e.preventDefault()}
                            onDragStart={e => e.preventDefault()}
                            style={{
                                width: px(1414),
                                height: px(456),
                                margin: 0,
                                padding: 0,
                                background: `url(${require('./images/BG.jpg')})`,
                                backgroundAttachment: 'local',
                                backgroundSize: 'stretch',
                                boxShadow: 'black 0 4px 12px',
                                filter: store.globalBypass ? 'opacity(0.5)' : '',
                            }}
                        >
                            <ModelToggleKnob name={"leftBypass"} imageUrl={require('./images/bypass_left.png')} x={178} y={185} inverted />
                            <ModelToggleKnob name={"linkPairs"} imageUrl={require('./images/stereo.png')} x={178} y={329} />
                            <ModelToggleKnob name={"leftLowPassFilter"} imageUrl={require('./images/lpf_left.png')} x={767} y={329} />
                            <ModelToggleKnob name={'leftOverdrive'} imageUrl={require('./images/overdrive_left.png')} x={461} y={329} values={[0, 1, 2]} inverted />

                            <ModelImageKnob name={"leftDrive"} imageUrl={require('./images/left_drive.png')} nf={61} x={328} y={166} />
                            <ModelImageKnob name={"leftOutputGain"} imageUrl={require('./images/left_output.png')} nf={61} x={789} y={166} />
                            <ModelImageKnob name={"leftDistortionType"} imageUrl={require('./images/left_distortion.png')} nf={6} x={570} y={166} />

                            <Needle x={1144} y={226} length={150} startAngle={-deflect} endAngle={deflect} lineStyle="black" lineWidth={2}
                                    ref={(n: any) => (this.gr = n)}/>

                            <Overlay imageUrl={require('./images/VUMeterMask.png')} x={988} y={22} style={{ zIndex: 900 }}/>

                            <ModelImageKnob name={"leftBias"} imageUrl={require('./images/left_bias.png')} nf={61} x={1089} y={324} style={{zIndex: 10000}} />
                        </div>
                    </div>
                </Context.Provider>
            )
        }
    },
) as any
