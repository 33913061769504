import React from 'react'

import { ModelImageKnob, ModelToggleKnob, Context } from '../ImageKnob'
import { px, mobx } from '../../utils'
import Store from '../../store'
import { toJS } from 'mobx'

export default mobx(
    class ElysiaMuseq extends React.Component<{ id: string; store: Store; uiScale: number }> {
        static renderTitle() {
            return 'Elysia Museq'
        }

        render() {
            const { uiScale = 0.5, store, id } = this.props
            const { api } = store

            const { session } = this.props.store.player
            if (session) {
                const { model } = session.devices[this.props.id]
                model.setValue('linkPairs', toJS(store.slots[store.selectedSlotIndex].settings[id].linkPairs), true)
            }

            return (
                <Context.Provider value={{ api, id } as any}>
                    <div
                        style={{
                            transform: `scale(${uiScale}, ${uiScale})`,
                            transformOrigin: 'left top',
                            margin: 0,
                            padding: 0,
                            width: px(2828 * uiScale),
                            height: px(620 * uiScale),
                        }}
                        onClick={e => e.preventDefault()}
                        onDoubleClick={e => e.preventDefault()}
                    >
                        <div
                            style={{
                                width: px(2828),
                                height: px(620),
                                margin: 0,
                                padding: 0,
                                background: `url(${require('./images/elysia-bg.png')})`,
                                backgroundAttachment: 'local',
                                backgroundSize: 'stretch',
                                boxShadow: 'black 0 4px 12px',
                                filter: store.globalBypass ? 'opacity(0.5)' : '',
                            }}
                            onClick={e => e.preventDefault()}
                            onDoubleClick={e => e.preventDefault()}
                        >
                            {/* left */}
                            <ModelImageKnob x={139} y={105} nf={21} imageUrl={require('./images/leftlg.png')} name={'leftLow'}/>
                            <ModelImageKnob x={354} y={105} nf={21} imageUrl={require('./images/leftbg.png')} name={'leftBottom'}/>
                            <ModelImageKnob x={567} y={105} nf={21} imageUrl={require('./images/leftmg.png')} name={'leftMiddle'}/>
                            <ModelImageKnob x={778} y={105} nf={21} imageUrl={require('./images/lefttg.png')} name={'leftTop'}/>
                            <ModelImageKnob x={992} y={105} nf={21} imageUrl={require('./images/lefthg.png')} name={'leftHigh'}/>
                            {/* right */}
                            <ModelImageKnob x={1700} y={105} nf={21} imageUrl={require('./images/rightlg.png')} name={'rightLow'}/>
                            <ModelImageKnob x={1913} y={105} nf={21} imageUrl={require('./images/rightbg.png')} name={'rightBottom'}/>
                            <ModelImageKnob x={2126} y={105} nf={21} imageUrl={require('./images/rightmg.png')} name={'rightMiddle'}/>
                            <ModelImageKnob x={2338} y={105} nf={21} imageUrl={require('./images/righttg.png')} name={'rightTop'}/>
                            <ModelImageKnob x={2549} y={105} nf={21} imageUrl={require('./images/righthg.png')} name={'rightHigh'}/>
                            {/* left frq */}
                            <ModelImageKnob x={137} y={376} nf={21} imageUrl={require('./images/leftlf.png')} name={'leftFrqLow'}/>
                            <ModelImageKnob x={360} y={376} nf={21} imageUrl={require('./images/leftbf.png')} name={'leftFrqBottom'}/>
                            <ModelImageKnob x={584} y={376} nf={21} imageUrl={require('./images/leftmf.png')} name={'leftFrqMiddle'}/>
                            <ModelImageKnob x={805} y={376} nf={21} imageUrl={require('./images/lefttf.png')} name={'leftFrqTop'}/>
                            <ModelImageKnob x={1024} y={376} nf={21} imageUrl={require('./images/lefthf.png')} name={'leftFrqHigh'}/>
                            {/* right frq */}
                            <ModelImageKnob x={1666} y={376} nf={21} imageUrl={require('./images/rightlf.png')} name={'rightFrqLow'}/>
                            <ModelImageKnob x={1884} y={376} nf={21} imageUrl={require('./images/rightbf.png')} name={'rightFrqBottom'}/>
                            <ModelImageKnob x={2108} y={376} nf={21} imageUrl={require('./images/rightmf.png')} name={'rightFrqMiddle'}/>
                            <ModelImageKnob x={2333} y={376} nf={21} imageUrl={require('./images/righttf.png')} name={'rightFrqTop'}/>
                            <ModelImageKnob x={2553} y={376} nf={21} imageUrl={require('./images/righthf.png')} name={'rightFrqHigh'}/>
                            {/* left BC/cut settings */}
                            <ModelToggleKnob x={212} y={267} name={'leftBCLow'} imageUrl={require('./images/leftln.png')}/>
                            <ModelToggleKnob x={430} y={267} name={'leftBCBottom'} imageUrl={require('./images/leftbn.png')}/>
                            <ModelToggleKnob x={649} y={267} name={'leftBCMiddle'} imageUrl={require('./images/leftmn.png')}/>
                            <ModelToggleKnob x={866} y={267} name={'leftBCTop'} imageUrl={require('./images/lefttn.png')}/>
                            <ModelToggleKnob x={1085} y={267} name={'leftBCHigh'} imageUrl={require('./images/lefthn.png')}/>
                            {/* right BC/cut settings */}
                            <ModelToggleKnob x={1773} y={267} name={'rightBCLow'} imageUrl={require('./images/rightln.png')}/>
                            <ModelToggleKnob x={1991} y={267} name={'rightBCBottom'} imageUrl={require('./images/rightbn.png')}/>
                            <ModelToggleKnob x={2209} y={267} name={'rightBCMiddle'} imageUrl={require('./images/rightmn.png')}/>
                            <ModelToggleKnob x={2427} y={267} name={'rightBCTop'} imageUrl={require('./images/righttn.png')}/>
                            <ModelToggleKnob x={2645} y={267} name={'rightBCHigh'} imageUrl={require('./images/righthn.png')}/>
                            {/* left shelf/nerrow settings */}
                            <ModelToggleKnob x={106} y={267} name={'leftQLow'} imageUrl={require('./images/leftls.png')}/>
                            <ModelToggleKnob x={325} y={267} name={'leftQBottom'} imageUrl={require('./images/leftbs.png')}/>
                            <ModelToggleKnob x={542} y={267} name={'leftQMiddle'} imageUrl={require('./images/leftms.png')}/>
                            <ModelToggleKnob x={760} y={267} name={'leftQTop'} imageUrl={require('./images/leftts.png')}/>
                            <ModelToggleKnob x={978} y={267} name={'leftQHigh'} imageUrl={require('./images/lefths.png')}/>
                            {/* right shelf/nerrow settings */}
                            <ModelToggleKnob x={1667} y={267} name={'rightQLow'} imageUrl={require('./images/rightls.png')}/>
                            <ModelToggleKnob x={1884} y={267} name={'rightQBottom'} imageUrl={require('./images/rightbs.png')}/>
                            <ModelToggleKnob x={2103} y={267} name={'rightQMiddle'} imageUrl={require('./images/rightms.png')}/>
                            <ModelToggleKnob x={2320} y={267} name={'rightQTop'} imageUrl={require('./images/rightts.png')}/>
                            <ModelToggleKnob x={2539} y={267} name={'rightQHigh'} imageUrl={require('./images/righths.png')}/>
                            {/* rmain actions */}
                            <ModelToggleKnob x={1209} y={490} name={'enableLeft'} imageUrl={require('./images/leftbypass.png')}/>
                            <ModelToggleKnob x={1320} y={490} name={'warmLeft'} imageUrl={require('./images/righths.png')}/>
                            <ModelToggleKnob x={1425} y={490} name={'linkPairs'} imageUrl={require('./images/righths.png')}/>
                            <ModelToggleKnob x={1538} y={490} name={'enableRight'} imageUrl={require('./images/rightbypass.png')}/>

                        </div>
                    </div>
                </Context.Provider>
            )
        }
    },
)
