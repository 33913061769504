import mapValues from 'lodash/mapValues'
import throttle from 'lodash/throttle'

import axios, { AxiosInstance } from 'axios'
import EventEmitter from 'event-emitter-es6'

import { IPublicInput, ISettingValue } from 'protocol'
import { Media, MediaBinding, MediaStats } from './media'

import { ModelSpec, Model } from '../Model'
import { Slot } from './preset'
import { SocketIOTransport, RequestResponse, TransportEventsListener } from './transport'

export interface PaymentLine {
    sku: string
    description: string
    source?: 'paypal' | 'credits' | 'plan'
    mediaId?: string
    price?: number
    tax?: number
    currency?: 'EUR' | 'USD'
}

export interface Payment {
    id: string
    lines: Array<PaymentLine>
    status: 'created' | 'approved' | 'denied'
    paypalPaymentId?: string
    credits: number
    remainingCredits: number
    total: number
    totalTax: number
    totalDiscount: number
    totalTaxDiscount: number
    taxRate: number
    taxArea: string
    plan: string
    currency: string
    completedAt?: Date
}

type Decoder = 'opus' | 'flac' | 'mp3'

interface Encoding {
    short: string
    long: string
    extension: string
    bandwidth: number
    bufferSize: number
    sampleRate: number
    bitDepth: number
    sampleBuffer: number
    mimeType: string
    decoder: Decoder
    lossy: boolean
    onlySampleRate?: Array<number>
}

export const Encodings: Record<string, Encoding> = {
    experiment16: {
        short: '💎',
        long: 'Lossless 💎',
        extension: 'wav',
        bandwidth: 1200,
        bufferSize: 4096,
        sampleRate: 44100,
        bitDepth: 16,
        sampleBuffer: 0,
        mimeType: 'audio/flac',
        decoder: 'flac',
        lossy: false,
    },
    opus16lib: {
        short: 'OPUS',
        long: 'Lossy Opus (48k only)',
        extension: 'ogg',
        bandwidth: 192,
        bufferSize: 4800,
        sampleRate: 48000,
        bitDepth: 16,
        sampleBuffer: 0,
        mimeType: 'audio/opus',
        decoder: 'opus',
        lossy: true,
        onlySampleRate: [48000],
    },
    mp3stream: {
        short: 'MP3',
        long: 'Lossy MP3 192kbit (44.1k and 48k only)',
        extension: 'mp3',
        bandwidth: 192,
        bufferSize: 4096,
        sampleRate: 44100,
        bitDepth: 16,
        sampleBuffer: 0,
        mimeType: 'audio/mp3',
        decoder: 'mp3',
        lossy: true,
        onlySampleRate: [44100, 48000],
    },
    mp3hqstream: {
        short: 'MP3',
        long: 'Lossy MP3 320kbit (44.1k and 48k only)',
        extension: 'mp3',
        bandwidth: 320,
        bufferSize: 4096,
        sampleRate: 44100,
        bitDepth: 16,
        sampleBuffer: 0,
        mimeType: 'audio/mp3',
        decoder: 'mp3',
        lossy: true,
        onlySampleRate: [44100, 48000],
    },
}

interface DownloadEncoding {
    [id: string]: {
        name: string
        extension: string
    }
}

export const DownloadEncodings: DownloadEncoding = {
    wav24: {
        name: 'Lossless 24bit',
        extension: 'wav',
    },
    wav16: {
        name: 'Lossless 16bit',
        extension: 'wav',
    },
}

export interface CartItem {
    slot: Slot
    session: Session
    opts: Object
}

export interface Owner {
    id: string
    name: string
    email: string
    acceptEmails: boolean
    isEUCompany?: boolean
    vatNumber?: string
    tokens?: object // this will evolve over time
    totalTokens?: number
    plan?: string
    country?: string
    picture?: string
}

export interface BillingInfo {
    plan: string
    tokens: number
    country?: string
    state?: string
    vatNumber?: string
}

export const SampleRates = [44100, 48000, 88200, 96000, 176400, 192000]

export const BufferSizes = [1, 2, 3, 4]
export const BufferLabels = ['Low', 'Medium', 'High', 'Very High']

export type EncodingId = 'experiment16'
export type EncodingIdForDownload = keyof typeof DownloadEncodings

export function sampleRateEnabledByEncoding(encoding: EncodingId, sr: number): boolean {
    return !(Encodings[encoding].lossy && sr !== 48000)
}

export interface FlowEntry {
    fromNode: string
    toNode: string
    fromPad: string
    toPad: string
}

export interface DeviceSpec {
    model: ModelSpec
    name: string
    sidecars: Array<DeviceSpec>
}

export interface Device {
    model: Model
    name: string
    type: string
    sidecars: Array<Device>
}

export interface SessionSpec {
    id: string
    ownerId: string
    nodeId: string
    from: Date
    to: Date
    product: string
    active: boolean
    devices: {
        [id: string]: DeviceSpec
    }
    deviceOrder: Array<string | Array<string>>
    orderPreferences: Array<string>
    processors: Array<any>
    flow: Array<FlowEntry>
    slots: Array<Slot>
}

export interface Session {
    id: string
    title?: string
    ownerId: string
    from: Date
    to: Date
    product: string
    active: boolean
    devices: {
        [id: string]: Device
    }
    orderPreferences: Array<string>
    deviceOrder: Array<string | Array<string>>
    processors: Array<any>
    flow: Array<FlowEntry>
    slots: Array<Slot>
}

export interface Engine {
    nodeId: string
}

export interface Plan {
    name: string
    gearWhitelist: Array<string>
    gearBlacklist: Array<string>
    diskQuota: number
    signupCredits: number
    recurringCredits: number
    discount: number
    sampleRateLimit: number
}

export interface Preset {
    id: string
    title: string
    ownerId: string
    createdAt: Date
    updatedAt: Date
    settings: any //FIXME!
}

function translateSettings(settings: any) {
    const rv = []
    for (const processor in settings) {
        const values = settings[processor]

        for (const parameter in values) {
            rv.push({
                processor,
                parameter,
                value: values[parameter],
            })
        }
    }

    return rv
}

export interface ReloadData {
    plans: { [id: string]: Plan }
    media: Array<Media>
    presets: Array<Preset>
    owner: Owner
}

export default class Api {
    requests: RequestResponse
    webSocket: SocketIOTransport
    webRtc = null //: ?WebRTCTransport NOTE webRtc is disabled
    axios: AxiosInstance
    settingsBuffer: Array<ISettingValue> = []

    constructor(url: string, token: string, events: EventEmitter, listener: TransportEventsListener) {
        this.requests = new RequestResponse(events, listener)
        this.requests.addTransport((this.webSocket = new SocketIOTransport(url, token)))

        this.axios = axios.create({
            baseURL: `${url}/api/v1`,
            headers: {
                authorization: `Bearer ${token}`,
            },
        })

        events.on('signal', data => {
            if (this.webRtc) {
                void data
                // this.webRtc.onSignal(data)
            }
        })
    }

    connectWebRtc() {
        // if (!this.webRtc) {
        //   try {
        //     this.requests.addTransport((this.webRtc = new WebRTCTransport(null, this.requests)))
        //   } catch (e) {
        //     console.error('while connecting webRTC', e)
        //   }
        // }
    }

    updateSettings = throttle(
        sessionId => {
            // console.log('setting settings', this.settingsBuffer)
            const { settingsBuffer } = this
            this.settingsBuffer = []

            this.requests
                .call(
                    'sessions.setSettings',
                    {
                        sessionId,
                        settings: settingsBuffer,
                    },
                    this.meta,
                    {
                        noReturn: true,
                    },
                )
                .catch(console.error)
        },
        30,
        {
            leading: true,
        },
    )

    async download(sessionId: string, slot: Slot, title: string, outputCoding: EncodingIdForDownload, sampleRate: number, buffering: number) {
        await this.requests.call(
            'sessions.download',
            {
                play: {
                    outputCodingPreset: outputCoding,
                    inputs: mapValues(
                        slot.inputs,
                        (input: MediaBinding): IPublicInput => ({
                            mediaId: input.mediaId,
                        }),
                    ),
                    settings: {
                        settings: translateSettings(slot.settings),
                    },
                    sampleRate: sampleRate,
                    latencyMultiplier: buffering,
                },
                title,
                sessionId,
            },
            this.meta,
            {
                timeout: 60 * 1000,
            },
        )
    }

    async canBounce(sessionId: string, slot: Slot) {
        return this.requests.call(
            'sessions.canBounce',
            {
                play: {
                    inputs: mapValues(
                        slot.inputs,
                        (input: MediaBinding): IPublicInput => ({
                            mediaId: input.mediaId,
                        }),
                    ),
                    settings: {
                        settings: translateSettings(slot.settings),
                    },
                },
                sessionId,
            },
            this.meta,
            {
                timeout: 60 * 1000,
            },
        )
    }

    async seek(sessionId: string, position: number) {
        await this.requests.call(
            'sessions.seek',
            {
                sessionId,
                position,
            },
            this.meta,
        )
    }

    async play(sessionId: string, slot: Slot, outputCoding: EncodingId, sampleRate: number, buffering: number) {
        await this.requests.call(
            'sessions.livePlay',
            {
                play: {
                    outputCodingPreset: outputCoding,
                    inputs: mapValues(
                        slot.inputs,
                        (input: MediaBinding): IPublicInput => ({
                            mediaId: input.mediaId,
                            startPos: input.startPos,
                            endPos: input.endPos,
                        }),
                    ),
                    settings: {
                        settings: translateSettings(slot.settings),
                    },
                    sampleRate: sampleRate,
                    latencyMultiplier: buffering,
                    userAgent: navigator.appVersion,
                },
                sessionId,
            },
            this.meta(10000),
            {
                timeout: 10000,
            },
        )
    }

    meta(timeout: number = 1000) {
        void this

        return {
            timeout,
        }
    }

    async stop(sessionId: string) {
        await this.requests.call(
            'sessions.stop',
            {
                sessionId,
            },
            this.meta(10000),
        )
    }

    async getMediaWaveform(id: string, count?: number): Promise<MediaStats> {
        return this.requests.call('media.stats', {
            id,
            stats: ['rmstrough', 'rmspeak', 'peak'],
            count,
        })
    }

    async getMediaFiles(): Promise<Array<Media>> {
        const { data } = await this.requests.call('media.find', {
            limit: 100,
        })
        return data
    }

    async getSession(sessionId: string): Promise<SessionSpec> {
        return await this.requests.call('sessions.get', {
            id: sessionId,
        })
    }

    async activateSession(sessionId: string): Promise<SessionSpec> {
        return await this.requests.call('sessions.activate', {
            id: sessionId,
        })
    }

    async extendSession(sessionId: string, to: Date): Promise<SessionSpec> {
        return this.requests.call('sessions.extend', {
            sessionId,
            to,
        })
    }

    async uploadMediaFile(title: string, data: File, onUploadProgress?: ProgressEvent): Promise<Media> {
        const res = await this.axios.post(`blob/media?title=${title}`, data, {
            onUploadProgress: onUploadProgress as any,
        })

        return res.data
    }

    setSettings(sessionId: string, settings: Array<ISettingValue>): void {
        for (const s of settings) {
            let found = false
            for (const o of this.settingsBuffer) {
                if (o.processor === s.processor && o.parameter === s.parameter) {
                    o.value = s.value
                    found = true
                    break
                }
            }

            if (!found) {
                this.settingsBuffer.push(s)
            }
        }

        this.updateSettings(sessionId)
    }

    async getPaymentInfo(lines: Array<PaymentLine>): Promise<Payment> {
        return this.requests.call('billing.createPaymentDryRun', {
            lines,
        })
    }

    async createPayment(lines: Array<PaymentLine>): Promise<Payment> {
        return this.requests.call('billing.createPayment', {
            lines,
        })
    }

    async getPaymentStatus(id: string): Promise<Payment> {
        return this.requests.call('billing.get', {
            id,
        })
    }

    async getPlans(): Promise<{
        [id: string]: Plan
    }> {
        return this.requests.call('billing.getPlans')
    }

    updateSession(sessionId: string, index: number, slot: Slot): void {
        this.requests.fire('sessions.update', {
            sessionId,
            index,
            slot,
        })
    }

    async generateStats(id: string): Promise<boolean> {
        return this.requests.call(
            'media/tasks.queueExtractStats',
            {
                id,
            },
            {
                timeout: 60 * 1000,
            },
            {
                timeout: 60 * 1000,
            },
        )
    }

    async generateEngineForm(id: string): Promise<void> {
        return this.requests.call(
            'media.generateEngineForm',
            {
                id,
            },
            {
                timeout: 60 * 1000,
            },
            {
                timeout: 60 * 1000,
            },
        )
    }

    async generatePreviewForm(id: string): Promise<void> {
        return this.requests.call(
            'media.generatePreviewForm',
            {
                id,
            },
            {
                timeout: 60 * 1000,
            },
            {
                timeout: 60 * 1000,
            },
        )
    }

    async savePreset(title: string, product: string, settings: any): Promise<Preset> {
        return this.requests.call('presets.createOrUpdate', {
            title,
            settings,
            product,
        })
    }

    async listPresets(product: string): Promise<Array<Preset>> {
        return this.requests.call('presets.list', {
            product,
        })
    }

    async deletePreset(id: string): Promise<boolean> {
        return this.requests.call('presets.delete', {
            id,
        })
    }

    async getOwner(): Promise<Owner> {
        return this.requests.call('users.getUserProfile', {})
    }

    async reload(product: string): Promise<ReloadData> {
        return {
            owner: await this.getOwner(),
            plans: await this.getPlans(),
            media: await this.getMediaFiles(),
            presets: await this.listPresets(product),
        }
    }

    async setSessionSocket(sessionId: string): Promise<void> {
        return this.requests.call('sessions.updateWebsocket', { sessionId })
    }

    async changeOrderPreferences(orderPreferences: string[], sessionId: string) {
        return this.requests.call('sessions.changeOrderPreferences', {
            orderPreferences,
            sessionId,
        })
    }
}
