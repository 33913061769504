import React from 'react'
import { observable, reaction } from 'mobx'
import styled from 'styled-components'

import Store from '../store'
import { mobx, px } from '../utils'
import { MediaStats } from '../api/media'
import { Typography } from '@material-ui/core'

class PositionLine extends React.Component<{ store: Store; width: number; height: number }, { pos: number }> {
    state = { pos: 0 }

    componentDidMount() {
        this.props.store.on('playingAnimation', this.updatePosition)
    }

    componentWillUnmount() {
        this.props.store.off('playingAnimation', this.updatePosition)
    }

    updatePosition = () => {
        const { player, currentSlotMedia } = this.props.store
        if (currentSlotMedia && currentSlotMedia.duration) {
            this.setState({ pos: player.position / currentSlotMedia.duration })
        }
    }

    render() {
        const { width, height } = this.props

        return (
            <div
                style={{
                    position: 'absolute',
                    left: px((Math.round(this.state.pos * width)) | 0),
                    top: px(0),
                    height: px(height),
                    backgroundColor: 'red',
                    width: px(2),
                }}
            />
        )
    }
}

function fixup(x: number | null): number {
    if (!x) return 0
    if (isNaN(x)) return 0
    if (isFinite(x)) return x
    return 0
}

const InitialStats = { rmspeak: [], rmstrough: [], peak: [] }

class Waveform extends React.Component<{ id: string | null; store: Store }, { width?: number; height?: number }> {
    canvas: HTMLCanvasElement | null = null
    container: HTMLDivElement | null = null
    stats: MediaStats = InitialStats
    debouncer: any
    state = {}
    onPosition?: Function
    onInnerWidth?: Function
    onId?: Function

    @observable
    noMedia: boolean = true

    componentDidMount() {
        this.onInnerWidth = reaction(
            () => this.props.store.windowInnerWidth,
            () => {
                if (!this.debouncer) {
                    this.debouncer = setTimeout(() => {
                        this.resize().catch(console.error)
                        this.debouncer = null
                    }, 300)
                }
            },
        )

        this.onId = reaction(
            () => this.props.id,
            () => {
                this.resize().catch(console.error)
            },
        )

        this.resize().catch(console.error)
    }

    componentWillUnmount() {
        this.onInnerWidth && this.onInnerWidth()
        this.onId && this.onId()
        this.onPosition && this.onPosition()
    }

    async resize() {
        if (this.container) {
            const { offsetHeight: height, offsetWidth: width } = this.container
            // console.log('resize:', width, height, this.props.id)
            if (this.props.id) {
                this.stats = await this.props.store.api.getMediaWaveform(this.props.id, width * 2)
                this.noMedia = false
                // console.log(this.stats)
            } else {
                this.stats = InitialStats
                this.noMedia = true
            }
            this.setState({ width, height }, this.update)
        }
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.id !== prevProps.id) {
            this.resize().catch(console.error)
        }
    }

    update = async () => {
        if (this.canvas) {
            const { offsetHeight, offsetWidth } = this.canvas
            const ctx = this.canvas.getContext('2d')

            if (ctx === null) {
                return
            }

            ctx.lineWidth = 1
            ctx.clearRect(0, 0, offsetWidth, offsetHeight)
            for (let i = 0; i < offsetWidth; i++) {
                if (i >= this.stats.peak.length) break

                const peakHeight = this.calculateElementHeight(fixup(this.stats.peak[i]), offsetHeight)
                if (peakHeight < 0) {
                    ctx.fillStyle = 'rgba(244, 244, 244, 0.35)'
                    ctx.fillRect(i, (offsetHeight - peakHeight) / 2, 1, peakHeight)
                }

                const rmsPeakHeight = this.calculateElementHeight(fixup(this.stats.rmspeak[i]), offsetHeight)
                if (rmsPeakHeight < 0) {
                    ctx.fillStyle = 'rgba(255, 204, 0, 0.75)'
                    ctx.fillRect(i, (offsetHeight - rmsPeakHeight) / 2, 1, rmsPeakHeight)
                }

                const rmsThroughHeight = this.calculateElementHeight(fixup(this.stats.rmstrough[i]), offsetHeight)
                if (rmsThroughHeight < 0) {
                    ctx.fillStyle = 'rgba(0, 204, 0, 0.65)'
                    ctx.fillRect(i, (offsetHeight - rmsThroughHeight) / 2, 1, rmsThroughHeight)
                }
            }
        }
    }

    calculateElementHeight = (data: any, offsetHeight: any) => {
        if (!data) {
            return 0
        }

        return -(1 - Math.min(Math.abs(data / 60), 1)) * offsetHeight
    }

    onClick = (e: any) => {
        const { store } = this.props
        const { width } = this.state as any
        const { currentSlotMedia } = store
        const { offsetX } = e.nativeEvent

        // console.log('waveform::onClick', offsetX, (width || 0) / 2, currentSlotMedia)

        if (width && currentSlotMedia) {
            store.play((offsetX / (width * 2)) * currentSlotMedia.duration)
        }
    }

    render() {
        const { width, height } = this.state as any
        const { noMedia } = this

        return (
            <CanvasWrapper ref={d => (this.container = d)} onClick={this.onClick}>
                {width && height && (
                    <canvas
                        style={{
                            position: 'absolute',
                            left: '0px',
                            top: '0px',
                            transform: 'scale(0.5, 0.5)',
                            transformOrigin: 'left top',
                        }}
                        width={`${width * 2}px`}
                        height={`${height * 2}px`}
                        ref={c => {
                            this.canvas = c
                        }}
                    />
                )}
                {height && width && <PositionLine store={this.props.store} width={width} height={height}/>}
                {noMedia && (
                    <Typography style={{ width: '100%', placeSelf: 'center', textAlign: 'center' }} paragraph={false} inline={true} variant={'body1'}>
                        No source selected, please select one from the <em>Source</em> dropdown at the top of the page
                    </Typography>)}
            </CanvasWrapper>
        )
    }
}

export default mobx(Waveform)

const NoMedia = styled.div`
    display: flex;
    margin: auto auto;
`

const CanvasWrapper = styled.div`
    width: 100%;
    height: 50px;
    margin-left: 10px;
    transform: none;
    overflow: hidden;
    position: relative;
    display: flex;
`
