import 'babel-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import QS from 'query-string'
import shortid from 'shortid'

import App from './App'
import 'typeface-lato'

window.document.title = `mix:analog studio ${process.env.REACT_APP_VERSION}`

let { id_token, token_type, state } = (window.location.hash && QS.parse(window.location.hash)) as any
const hasIdTokenHash = id_token != null

const p = /\/([a-zA-Z0-9]+)/gi.exec(window.location.pathname)
const s = p && p.length >= 2 && p[1]

const clientId = 't251Lxf5QLs6FnzFHGh5RVTQClFtChMt'
const auth0Url = 'https://auth.mixanalog.com/authorize?response_type=token'
let port: any = window.location.port

if (!port) {
    port = 80
}

if (port === 80 || port === 443) port = ''
else port = `:${port}`

const redirectUrl = `${window.location.protocol}//${window.location.hostname}${port}`
const time = Date.now().valueOf() / 1000
const actualState = s ? window.btoa(JSON.stringify({ sessionId: s })) : state

function getSession() {
    try {
        const { sessionId } = JSON.parse(window.atob(actualState))
        return sessionId
    } catch (err) {
        console.error(err)
        return null
    }
}

function extractPayload(token: string) {
    try {
        var base64Url = token.split('.')[1]
        var base64 = base64Url.replace('-', '+').replace('_', '/')
        return JSON.parse(window.atob(base64))
    } catch (e) {
        window.localStorage.removeItem('auth0-token')
        return null
    }
}

if (!token_type) {
    const token = window.localStorage.getItem('auth0-token')

    if (token) {
        const payload = extractPayload(token)
        if (payload && payload.exp > time) {
            token_type = 'Bearer'
            id_token = token
        }
    }
}

const sessionId = getSession()
if (!sessionId) {
    const elm = document.querySelector('.appIsLoading')
    if (elm) elm.innerHTML = 'Missing sessionId parameter. Please try again.'
} else {
    if (!token_type) {
        const nonce = `${time}-${shortid()}`
        window.localStorage.setItem('auth0-nonce', nonce)
        ;(window as any).location = `${auth0Url}&client_id=${clientId}&redirect_uri=${redirectUrl}&state=${actualState}&nonce=${nonce}&scope=openid,email`
    } else if (hasIdTokenHash) {
        window.localStorage.setItem('auth0-token', id_token)
        window.location.replace(`${redirectUrl}/${sessionId}`)
    } else {
        ReactDOM.render(<App sessionId={sessionId} token={id_token} />, document.getElementById('root'))

        const payload = extractPayload(id_token)
        if (!payload) {
            window.location.reload()
        } else {
            setTimeout(() => {
                if (sessionId) {
                    if ((window as any).Raven) {
                        ;(window as any).Raven.setExtraContext({ sessionId })
                    }
                }
            }, 1500)
        }
    }
}
