import React from 'react'
import {px} from '../utils'

export type HitBox = { x: number; y: number; w: number; h: number }

export function fixup(v: any, defval: any = false): any {
    if (isNaN(v)) {
        return defval
    }
    if (!isFinite(v)) {
        return defval
    }
    return v
}

export default class ToggleKnob extends React.Component<{
    imageUrl: string
    x: number
    y: number
    initial?: any
    inverted?: boolean
    hitBox?: HitBox
    onChange?: (n: any, b: any) => void
    values?: any[]
    name?: string
    disabled?: boolean
    style?: any
},
    { loaded: boolean }> {
    _value: any = this.props.initial == null ? (this.props.values ? fixup(this.props.values[0], 0) : false) : fixup(this.props.initial, false)
    canvas: HTMLCanvasElement | null = null
    image: any
    state = {
        loaded: false,
    }
    scheduled = false

    init() {
        this.image = new Image()
        this.image.src = this.props.imageUrl
        this.image.onload = () => this.setState({loaded: true}, () => this.update())
    }

    update = () => {
        if (!this.scheduled) {
            this.scheduled = true
            requestAnimationFrame(this.innerUpdate)
        }
    }

    innerUpdate = () => {
        this.scheduled = false
        if (this.state.loaded && this.canvas) {
            let numOptions = 2
            if (this.props.values) {
                numOptions = this.props.values.length
            }

            const {width, height} = this.image
            const w = width
            const h = height / numOptions

            const ctx = this.canvas.getContext('2d')
            let offset = this._value ? 1 : 0
            if (this.props.values) {
                let index = 0
                for (const val of this.props.values) {
                    if (val == this._value) {
                        offset = index
                        break
                    }
                    index += 1
                }

                if (this.props.inverted) {
                    offset = this.props.values.length - 1 - offset
                }
            } else {
                if (this.props.inverted) {
                    offset = offset ? 0 : 1
                }
            }

            if (!ctx) {
                return
            }

            ctx.clearRect(0, 0, w, h)
            ctx.drawImage(this.image, 0, (offset * height) / numOptions, w, h, 0, 0, w, h)
        }
    }

    componentDidMount() {
        this.init()
    }

    set value(value: any) {
        const didChange = value != this._value
        if (didChange) {
            this.setPositionNoNotify(value)
            if (this.props.onChange) {
                this.props.onChange(this.value, true)
            }
        }
    }

    setPositionNoNotify(value: any) {
        this._value = fixup(value, this.props.values ? this.props.values[0] : false)
        this.update()
    }

    get value(): any {
        return this._value
    }

    render() {
        const {loaded} = this.state
        const {x, y, style = {}} = this.props

        let w = 0
        let h = 0

        if (loaded) {
            w = this.image.width
            h = this.image.height / 2
        }

        return !loaded ? null : (
            <canvas
                ref={c => {
                    this.canvas = c
                    this.update()
                }}
                width={Math.max(w, 1)}
                height={Math.max(h, 1)}
                style={{
                    ...style,
                    position: 'absolute',
                    top: px(y),
                    left: px(x),
                    touchAction: 'none',
                }}
                onClick={this.onClick}
                onDoubleClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                }}
                onDragStart={e => e.preventDefault()}
            />
        )
    }

    onClick = (e: any) => {
        /* check hitBox */
        if (this.props.hitBox) {
            let {clientX, clientY} = e
            let {x, y, w, h} = this.props.hitBox
            const {left, top} = this.canvas ? this.canvas.getBoundingClientRect() : {left: 0, top: 0}
            clientX -= left
            clientY -= top

            if (clientX < x || clientX > x + w || clientY < y || clientY > y + h) {
                return
            }
        }

        e.preventDefault()
        e.stopPropagation()

        if (this.props.values) {
            let index = 0
            for (const val of this.props.values) {
                if (val == this._value) {
                    index = index + (this.props.inverted ? -1 : 1)
                    if (index < 0) {
                        index = this.props.values.length - 1
                    } else if (index == this.props.values.length) {
                        index = 0
                    }

                    this.value = this.props.values[index]
                    break
                }
                index += 1
            }
        } else {
            this.value = !this.value
        }
    }
}
