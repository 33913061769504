import { action, computed, extendObservable, observable, observe, toJS } from 'mobx'
import addMinutes from 'date-fns/addMinutes'

import find from 'lodash/find'
import filter from 'lodash/filter'
import omit from 'lodash/omit'
import mapValues from 'lodash/mapValues'
import get from 'lodash/get'
import debounce from 'lodash/debounce'
import cloneDeep from 'lodash/cloneDeep'

import moment from 'moment'
import Api, { Device, DownloadEncodings, Owner, Preset, ReloadData, Session } from '../api'
import { Media } from '../api/media'
import { Player, PlayerEventsListener } from './player'
import { AudioEventsListener } from '../api/events'
import EventEmitter from 'event-emitter-es6'
import { IAudioReady, ISettingValue, ITerminated } from 'protocol'
import { Model, Property } from '../Model'
import { DisconnectedError, Transport, TransportEventsListener } from '../api/transport'
import uuid from 'uuid'

import { identify, track } from './analytics'
import { Bounce, BouncingBounce, CompletedBounce } from './bounces'
import { Slot } from '../api/preset'

export const THEME_NORMAL = 'normal'
export const THEME_DARK = 'dark'
export type ThemeId = typeof THEME_NORMAL | typeof THEME_DARK

export enum Meter {
    RMS,
    LUFSMomentary,
    LUFSShortTerm,
}

export const AppStates = {
    Loading: 0,
    Error: 1,
    Ready: 2,
    ConnectingWebRTC: 3,
    Connected: 4,
    ConnectedWithPlayer: 5,
}

export const EngineStates = {
    Disconnected: 0,
    Connecting: 1,
    Connected: 2,
    Playing: 3,
}

export default class Store extends EventEmitter implements TransportEventsListener, PlayerEventsListener {
    /* global blocking */
    @observable
    blocking: boolean = false
    @observable
    warning?: string
    @observable
    dialogVisible = false

    /* internal state machine */
    @observable
    appState = AppStates.Loading
    @observable
    engineState = EngineStates.Connected
    @observable
    appError?: Error
    @observable
    latency = Infinity

    /* domain data t*/
    @observable
    media: Array<Media> = []
    @observable
    presets: Array<Preset> = []
    @observable
    enableWebRtc = false
    @observable
    sessionId: string = ''
    @observable
    selectedSlotIndex = 0
    @observable
    themeId: ThemeId = THEME_DARK
    @observable
    prefix = ''
    @observable
    owner: Owner = { tokens: 0 } as any

    /* on download blocker */
    @observable
    isDownloading: boolean = false

    @observable
    lastBounceAttemptStatus: { id: string; status: number } | null = null

    /* playing and settings */
    @observable
    player = new Player(this, false)

    /* project settings */
    @observable
    projectName = `Unnamed Project ${new Date().toDateString()}`
    @observable
    artistName = ''

    /* ui setting s*/
    @observable
    uiScale = 0.5
    @observable
    windowInnerWidth = window.innerWidth
    @observable
    globalBypass = false
    @observable
    settingsVisible = false
    @observable
    maxUiScale = 0.5

    /* bounces */
    @observable
    queuedBounces: Bounce[] = []

    @observable
    currentBounce: BouncingBounce | null = null

    @observable
    completedBounces: CompletedBounce[] = []

    @observable
    meter: Meter = Meter.RMS

    @observable
    meterDisabled: boolean = false

    /* API */
    api: Api
    audioListener?: AudioEventsListener = this.player
    notifyTimer: any

    warned60SecondsBefore: boolean
    plans: any
    cart: any
    notifyCart: any
    payment: any

    prevPosition = -1

    constructor(apiRoot: string, token: string, sessionId: string) {
        super({ emitDelay: 0 })
        const anyWindow = window as any

        this.sessionId = sessionId
        this.api = new Api(apiRoot, token, this, this as any)
        this.warned60SecondsBefore = false

        if (anyWindow.Raven && typeof anyWindow.Raven.configure === 'function') {
            anyWindow.Raven.configure({ release: process.env.REACT_APP_VERSION, environment: process.env.NODE_ENV })
        }

        this.on('audioReady', (ar: IAudioReady) => {
            this.audioListener && this.audioListener.onAudioReady(ar)
        })

        this.on('terminated', (tr: ITerminated) => {
            this.audioListener && this.audioListener.onTerminated(tr)
        })

        this.on('change.insert_1.bypass', this.setGlobalBypass)

        this.on('change', this.onParameterChange)

        observe(this, 'selectedSlotIndex', async () => {
            if (this.isPlaying) {
                await this.stop()
            }
            this.broadcastSlotSettings()
        })

        window.onresize = () => {
            this.windowInnerWidth = window.innerWidth
        }

        setInterval(this.checkSessionEnd, 500)

        this.on('profile.interest', async (...args) => {
            console.log('profile.interest', ...args)

            if (this.player.session) {
                const newSession = await this.api.getSession(this.player.session.id)
                if (this.player.session.to != newSession.to) {
                    this.player.session.to = newSession.to
                    await this.reload()
                }
            }
        })
    }

    @action.bound
    setGlobalBypass(val: boolean) {
        this.globalBypass = val
    }

    checkSessionEnd = () => {
        const { timeUntilSessionEnd } = this
        if (timeUntilSessionEnd != null && timeUntilSessionEnd <= 0) {
            window.location.assign('https://app.mixanalog.com?reason=expired')
        }
    }

    updateAnimations = () => {
        const { position } = this.player
        if (this.prevPosition != position) {
            this.prevPosition = position

            try {
                this.emit('playingAnimation', position)
            } catch (e) {
                console.error('while playingAnimation', e)
            }
        }
    }

    @action.bound
    onParameterChange = (params: { processor: string; parameter: string; value: number }) => {
        const { processor, parameter, value } = params
        // console.log('sending', processor, parameter, value)
        this.api.setSettings(this.player.session ? this.player.session.id : '', [
            {
                processor,
                parameter,
                value,
            },
        ])

        const slot = this.slots[this.selectedSlotIndex]
        if (slot) {
            if (!slot.settings[processor]) {
                slot.settings[processor] = {}
            }

            if (slot.settings[processor][parameter] !== value) {
                slot.settings[processor][parameter] = value
                this.saveSlot(this.selectedSlotIndex)
            }
        }
    }

    @action
    reload() {
        return this.api.reload(this.player.session ? this.player.session.product : '').then(this.afterReload, this.applicationError)
    }

    @action.bound
    afterReload = (data: ReloadData) => {
        this.owner = data.owner
        this.plans = data.plans
        this.media = data.media
        this.presets = data.presets

        const { id, email, ...rest } = data.owner

        identify(id, email, rest)
    }

    @action.bound
    changeDialogVisible = (val: boolean) => {
        this.dialogVisible = val
    }

    @action
    broadcastSlotSettings() {
        const { session } = this.player
        if (session && this.isSlotIndexPopulated(this.selectedSlotIndex)) {
            const slot = session.slots[this.selectedSlotIndex]

            for (const gearId in slot.settings) {
                for (const propName in slot.settings[gearId]) {
                    session.devices[gearId].model.setValue(propName, slot.settings[gearId][propName], false, true)
                }
            }
        }
    }

    @action
    onLatency(t: Transport, ms: number): any {
        this.latency = ms
    }

    @action
    async onConnected(t: Transport) {
        console.log('onConnected')
        await this.api.setSessionSocket(this.sessionId)

        if (t === this.api.webRtc) {
            return
        }

        if (this.player && this.player.session) {
            return
        }

        try {
            this.appState = AppStates.Connected
            const spec = await this.api.activateSession(this.sessionId)

            console.log('spec received', toJS(spec))

            if (spec) {
                const devices = mapValues(spec.devices, (dev, name) => ({
                    model: new Model(this, name, dev.model),
                    type: dev.model.name,
                    name: dev.name,
                    sidecars: dev.sidecars,
                }))

                await this.reload()

                this.setPlayerSession({ ...omit(spec, 'devices'), devices: devices as any })
            } else {
                console.error('No such session?', this.sessionId)
            }
        } catch (e) {
            this.applicationError(e).catch(console.error)
        }
    }

    @action
    async storeSlotToServerAndRefresh() {
        /* propagate settings */
        this.blocking = true
        try {
            const slot = this.slots[this.selectedSlotIndex]
            if (slot) {
                const params: Array<ISettingValue> = []

                for (const procName in slot.settings) {
                    for (const propName in slot.settings[procName]) {
                        params.push({
                            processor: procName,
                            parameter: propName,
                            value: slot.settings[procName][propName],
                        })
                    }
                }

                this.setSlotSettings(params, false, true)
            }
        } catch (e) {
            this.applicationError(e).catch(console.error)
        } finally {
            this.clearBlocking()
        }
    }

    @action
    setPlayerSession(s: Session) {
        this.player.session = extendObservable({}, s)
    }

    async extendSession() {
        if (!this.player.session) {
            return
        }

        const { id, to } = this.player.session

        const newSession = await this.api.extendSession(id, addMinutes(to, 15))

        track('Extended Session', { productId: newSession.product, from: newSession.from, newTo: newSession.to, to })

        if (this.player.session) {
            this.setPlayerSession({ ...this.player.session, to: newSession.to })
        }

        await this.reload()
    }

    @action.bound
    applicationError = (error: any, errorInfo?: any) => {
        if (!(error instanceof DisconnectedError) && this.appError !== error) {
            const { Raven } = window as any

            if (Raven) {
                Raven.captureException(error, { extra: errorInfo })
                if (Raven.lastEventId) {
                    this.dialogVisible = true
                    Raven.showReportDialog()
                }
            } else if (window.onerror) {
                window.onerror(error)
            } else {
                this.appError = error
                this.appState = AppStates.Error
            }
            track('App Error', { error })

            if (this.blocking) {
                this.clearBlocking()
            }
        }

        return Promise.reject(error)
    }

    @computed
    get loading(): boolean {
        return this.appState < AppStates.Ready
    }

    get timeUntilSessionEnd() {
        return this.player.session ? Math.round(moment(this.player.session.to).diff(moment()) / 1000) : undefined
    }

    get isEnoughTime() {
        /* is there enough time to complete a download? */
        const { currentSlotMedia, timeUntilSessionEnd } = this
        if (currentSlotMedia != null && timeUntilSessionEnd != null) {
            return currentSlotMedia.duration <= timeUntilSessionEnd
        }
    }

    @action
    onDisconnected(t: Transport) {
        console.log('disconnected')
        // TODO: there used to be a WebRTC check here
        track('Disconnected')
        // this.appState = AppStates.Loading
    }

    @computed
    get canOpenDownloading(): boolean {
        return this.engineState >= EngineStates.Connected
    }

    @computed
    get canTogglePlayingState(): boolean {
        return this.engineState >= EngineStates.Connected
    }

    @computed
    get canOpenLive(): boolean {
        return this.engineState >= EngineStates.Connecting
    }

    @action.bound
    saveSlot = debounce(
        (slotIndex: number) => {
            if (this.player.session) {
                this.api.updateSession(this.player.session.id, slotIndex, toJS(this.slots[slotIndex]))
            }
        },
        100,
        { trailing: true, leading: true },
    )

    @action
    async saveAllSlots() {
        const { session } = this.player
        if (session) {
            this.slots.forEach((slot, index) => {
                this.api.updateSession(session.id, index, slot)
            })
        }
    }

    // TODO: this needs type annotations
    @action
    notify = (msg: any) => {
        /* if timer exists, extend it, else cerate one */
        this.notifyTimer && clearTimeout(this.notifyTimer)

        this.notifyTimer = setTimeout(() => (this.notifyCart = null), 3000)
        this.notifyCart = msg
    }

    @action
    setAudioEventListener(listener?: AudioEventsListener) {
        this.audioListener = listener
    }

    @action
    resetAudioListenerToPlayer() {
        this.audioListener = this.player
    }

    @computed
    get currentSlotMediaId(): string | null {
        if (this.isSlotIndexPopulated(this.selectedSlotIndex)) {
            return get(this.slots[this.selectedSlotIndex], 'inputs.input.mediaId')
        } else {
            return null
        }
    }

    @computed
    get currentSlotSettings(): Record<string, any> | null {
        if (this.isSlotIndexPopulated(this.selectedSlotIndex)) {
            return this.slots[this.selectedSlotIndex].settings
        } else {
            return null
        }
    }

    @action
    getMediaById(id: string): Media | null {
        const rv = this.media.find(media => media.id === id)
        if (rv) {
            return rv
        } else {
            return null
        }
    }

    @computed
    get currentSlotMedia(): Media | null {
        const id = this.currentSlotMediaId
        if (id) {
            return find(this.media, { id: this.currentSlotMediaId || '' }) as Media
        } else {
            return null
        }
    }

    @computed
    get slots(): Array<Slot> {
        if (this.player.session) {
            return this.player.session.slots
        }

        return []
    }

    isSlotIndexPopulated = (index: number) => {
        return !!this.slots[index]
    }

    @action
    async startDownload(
        paymentId: number | null,
        download: {
            opts: { encoding: string; sampleRate: number }
            session: Session
            slot: any
            title: string
        },
    ) {
        const { session, slot, opts, title } = download
        try {
            this.setDownloading(true)
            return this.api.download(session.id, slot, title, opts.encoding as any, opts.sampleRate, 1)
        } catch (e) {
            this.setDownloading(false)
            throw e
        }
    }

    @action
    async play(startPos?: number) {
        if (!this.isSlotIndexPopulated(this.selectedSlotIndex) || !this.currentSlotMediaId) {
            return
        }

        this.prevPosition = -1

        track('Play')
        /** TODO: this should go to individual components */

        if (this.engineState >= EngineStates.Connected) {
            const sessionId = this.player.session ? this.player.session.id : ''
            const slot = toJS(this.slots[this.selectedSlotIndex])

            try {
                this.blocking = true

                if (startPos !== undefined) {
                    slot.inputs.input.startPos = startPos
                }

                this.player.startPosition = startPos || 0

                if (this.isPlaying) {
                    await this.stop()

                    if (this.player.session && this.player.session.product === 'telefunken_m15') {
                        await new Promise(function(resolve, reject) {
                            setTimeout(resolve, 500)
                        })
                    }
                    await this.play(startPos)
                    return
                }

                await this.player.start(startPos)
                await this.api.play(sessionId, slot, this.player.encoding, this.player.sampleRate, 1)
                await this.player.waitForFirstPacket()
                this.setEngineState(EngineStates.Playing) // if we crash before this we're not playing
            } catch (e) {
                this.applicationError(e)

                await this.player.stop()
                await this.api.stop(sessionId)
            } finally {
                this.clearBlocking()
            }
        }
    }

    @action
    setEngineState(st: any): void {
        this.engineState = st
    }

    @action
    async stop() {
        track('Stop')

        if (this.engineState >= EngineStates.Connected) {
            this.engineState = EngineStates.Connected
            await this.player.stop()
            await this.api.stop(this.player.session ? this.player.session.id : '')
        }
    }

    @computed
    get isPlaying(): boolean {
        return this.isEngineConnected && this.engineState === EngineStates.Playing
    }

    @computed
    get isEngineConnected(): boolean {
        return this.engineState >= EngineStates.Connected
    }

    @action
    onPlayerEndOfInput() {
        if (this.engineState === EngineStates.Playing) {
            this.engineState = EngineStates.Connected
        }
    }

    @action
    onPlayerTerminated() {
        if (this.engineState === EngineStates.Playing) {
            this.engineState = EngineStates.Connected
        }
    }

    @action
    setSlotSettings(settings: Array<ISettingValue>, fromGui: boolean = false, force: boolean = false) {
        /* call API only if actual value changed */
        const { session } = this.player
        if (!session) {
            return
        }

        for (const s of settings) {
            const dev: Device = session.devices[s.processor as any]
            if (dev) {
                dev.model.setValue(s.parameter as any, s.value as any, fromGui, force)
            }
        }
    }

    makeFileName = (index: number) => {
        const {
            cart,
            player: { session },
        } = this
        const media = this.getMediaById(cart[index].slot.inputs.input.mediaId)
        const fileExt = DownloadEncodings[cart[index].opts.encoding] && DownloadEncodings[cart[index].opts.encoding].extension

        return `${media ? media.title : ''}-${session ? session.product : ''}.${fileExt}`
    }

    @action
    async uploadMediaFile(title: string, data: File, onUploadProgress?: any): Promise<Media> {
        track('Start Upload File', { title })

        return this.api
            .uploadMediaFile(title, data, onUploadProgress)
            .then(this.afterUploadMediaFile, this.applicationError)
            .catch(this.applicationError)
    }

    @action.bound
    afterUploadMediaFile = (m: Media) => {
        track('Uploaded File', { mediaId: m.id })

        console.log('afterUploadMediaFile', m)

        this.saveAllSlots()
            .catch(this.applicationError)
            .then(() => this.reload())

        return m
    }

    getParameterModel(devName: string, name: string): Property | undefined {
        const { devices } = this.player.session || { devices: {} }
        const device = devices[devName]
        if (device) {
            return device.model.parameters[name]
        }
    }

    @action
    clearWarning() {
        this.warning = undefined
    }

    @action
    setDownloading(v: boolean) {
        this.isDownloading = v
    }

    @action
    downloadMediaFileOriginal(mediaId: string) {
        const media = find(this.media, { id: mediaId })
        if (media) {
            const file = find(media.files, { isOriginal: true }) as any
            if (file) {
                window.location.assign(`${process.env.REACT_APP_API_URL || 'http://localhost'}/api/v1/blob/${file.id}?filename=${media.title}.${file.container}`)
            }
        }
    }

    @action
    addSlot() {
        const len = this.slots.length
        if (len > 0 && len < 10) {
            this.slots.push(cloneDeep(this.slots[len - 1]))
        }
    }

    @action
    clearBlocking() {
        this.blocking = false
    }

    @action
    async addBounceToQueue(title: string, sampleRate: number) {
        if (!this.currentSlotMediaId) {
            throw new Error('No media in the current slot')
        }

        const slot = cloneDeep(this.slots[this.selectedSlotIndex])
        const { duration } = find(this.media, { id: this.currentSlotMediaId }) as any
        const newBounce = {
            id: uuid.v4().toString(),
            slot,
            duration,
            title,
            sampleRate,
        }

        this.queuedBounces.push(newBounce)

        if (this.player.session) {
            track('Added Bounce To Queue', { duration, productId: this.player.session.product })
        }

        return newBounce
    }

    @action
    setLastBounceAttemptStatus(id: string, status: number): number {
        this.lastBounceAttemptStatus = { id, status }
        return status
    }

    @action
    clearLastBounceAttemptStatus() {
        this.lastBounceAttemptStatus = null
    }

    @action
    async executeBounce(id: string) {
        if (this.player.session && this.currentSlotMedia) {
            track('Execute Bounce', {
                id,
                productId: this.player.session.product,
                duration: this.currentSlotMedia.duration,
            })
        }

        if (this.currentBounce != null) {
            throw new Error('There is a bounce in progress')
        }

        this.blocking = true

        try {
            for (const b of this.queuedBounces) {
                if (b.id === id) {
                    /* we need to execute this one */
                    if (this.isPlaying) {
                        await this.stop()
                        await new Promise(resolve => setTimeout(resolve, 1500))
                    }

                    if (!this.player.session) {
                        return this.setLastBounceAttemptStatus(id, 0)
                    }

                    if (!(await this.api.canBounce(this.player.session.id, b.slot))) {
                        return this.setLastBounceAttemptStatus(id, 2)
                    }

                    this.setAudioEventListener(undefined)
                    this.on('download.progress', this.downloadProgress)

                    /* now queue it up */
                    this.setCurrentBounce({
                        ...b,
                        progress: 0,
                    })

                    /* actually perform the bounce */
                    await this.startDownload(null, {
                        opts: {
                            encoding: 'wav24',
                            sampleRate: b.sampleRate,
                            delivery: 0,
                            isDisabled: false,
                        } as any,
                        title: b.title,
                        session: this.player.session,
                        slot: b.slot,
                    })

                    return this.setLastBounceAttemptStatus(id, 1)
                }
            }
        } catch (e) {
            this.resetAudioListenerToPlayer()
            throw e
        } finally {
            this.clearBlocking()
        }
    }

    @action
    removeBounce(id: string) {
        this.queuedBounces = this.queuedBounces.filter(q => q.id !== id)
    }

    @action
    async terminateBounce() {
        track('Canceled Bounce')
        try {
            this.currentBounce = null
            await this.api.stop(this.player.session ? this.player.session.id : '')
        } finally {
            this.resetAudioListenerToPlayer()
            this.setDownloading(false)
        }
    }

    @action
    restoreCurrentBounce() {
        if (this.currentBounce) {
            this.queuedBounces = [this.currentBounce as any]
            this.currentBounce = null
        }

        this.resetDownloadsStatus()
    }

    @action
    finishCurrentBounce(mediaId: string) {
        if (this.currentBounce) {
            this.currentBounce.progress = 100
            this.completedBounces.push({ ...this.currentBounce, mediaId })
            this.currentBounce = null
        }

        this.resetDownloadsStatus()
    }

    @action
    resetDownloadsStatus() {
        this.clearLastBounceAttemptStatus()
        this.resetAudioListenerToPlayer()
        this.setDownloading(false)
    }

    @action.bound
    downloadProgress = (e: { error: any; progress: number | 'finished'; mediaId: string }) => {
        const { progress, error, mediaId } = e

        if (error) {
            this.downloadError(error)
        }

        if (this.currentBounce) {
            if (progress !== 'finished') {
                this.currentBounce.progress = progress
            } else {
                this.reload().then(() => this.finishCurrentBounce(mediaId), this.applicationError)
            }
        }
    }

    @action.bound
    downloadError = (error: Error) => {
        this.restoreCurrentBounce()
        this.applicationError(error).catch(() => {})
    }

    @action
    setCurrentBounce(b: BouncingBounce | null) {
        this.currentBounce = b

        if (b != null) {
            const { id } = b
            this.queuedBounces = filter(this.queuedBounces, f => f.id != id)
        }
    }

    onData(t: Transport, data: any) {}

    @action
    setMaxUiScale(val: number) {
        track('Change UI Scale', { scale: val })
        this.maxUiScale = val
    }

    @action
    changeOrderPreferences = async (newPreferences: string[]) => {
        try {
            await this.api.changeOrderPreferences(newPreferences, this.sessionId)

            if (this.player.session) {
                this.player.session.orderPreferences = newPreferences
            }
        } catch (error) {
            console.error(error)
        }
    }
}
