import '@baadc0de/audioworklet-polyfill'
import throttle from 'lodash/throttle'
import {action, computed, observable, toJS} from 'mobx'
import {IAudioReady, IErrorOrWarning, IReady, ITerminated} from 'protocol'
import {detect} from 'detect-browser'
import map from 'lodash/map'

import Store from '.'
import AudioWorklet from 'worklet-loader!../audio-io/worklet/audio.worklet.ts'
import {EncodingId, Encodings, Session} from '../api'
import InterpolatingValue from './interpolation'
import MixanalogNode from '../audio-io/MixanalogNode'
import {AudioEventsListener} from '../api/events'
import MixanalogAudio from '../audio-io/MixanalogAudio'

export interface PlayerEventsListener {
    onPlayerEndOfInput(): void

    onPlayerTerminated(): void

    updateAnimations(): void
}

interface AudioProcessingEvent extends Event {
    inputBuffer: AudioBuffer
    outputBuffer: AudioBuffer
    playbackTime: number
}

export class Player implements AudioEventsListener {
    scriptProcessorBufferSize = 256
    audioContext = new ((window as any).AudioContext || (window as any).webkitAudioContext)()
    node?: MixanalogAudio

    @observable
    playing = false
    @observable
    encoding: EncodingId = 'experiment16'
    @observable
    buffering = 6
    @observable
    nativeSampleRate = this.audioContext.sampleRate
    @observable
    sampleRate = this.nativeSampleRate
    @observable
    session?: Session
    @observable
    lufsEnabled = true

    startPosition: number = 0
    position: number = 0
    measurements: { [name: string]: InterpolatingValue } = {}
    lastPacket: boolean = false
    lastPacketTimestamp: number = new Date().getTime()
    startedPlaying: boolean = false

    prevPos?: number

    firstPacket = false
    stopInterval: any

    store: Store

    constructor(store: Store, audioWorklets: boolean) {
        this.store = store
        this.prevPos = undefined
        this.lufsEnabled = this.checkIfBrowserFirefox()
        /*
        if (audioWorklets) {
            this.audioContext.audioWorklet
                .addModule(AudioWorklet)
                .then(() => {
                    this.node = new MixanalogNode(this.audioContext, {
                        numberOfInputs: 0,
                        numberOfOutputs: 1,
                        outputChannelCount: [2],
                        bufferSize: 1024,
                    })

                    this.node.connect(this.audioContext.destination)
                })
                .catch((err: any) => {
                    store.applicationError(err).catch(() => {
                    })
                })
        } else {
         */
        let bufferSize = 1024
        if (localStorage) {
            bufferSize = parseInt(localStorage.getItem('bufferSize') || '1024')
            if (bufferSize < 0 || isNaN(bufferSize) || !isFinite(bufferSize)) {
                bufferSize = 1024
            }
        }

        console.log('using buffer size', bufferSize)

        this.node = new MixanalogAudio(this.audioContext, {
            bufferSize,
            outputChannels: 2,
            checkIfLufsEnabled: this.checkIfLufsEnabled,
        })
        /*
        }
         */

        requestAnimationFrame(this.updateAnimations)

        this.stopInterval = setInterval(() => {
            if (this.playing && this.lastPacket && new Date().getTime() - this.lastPacketTimestamp > this.bufferingTime + 100) {
                this.store.stop()
            }
        }, 200)
    }

    updateAnimations = () => {
        this.debouncedUpdateAnimations()
        requestAnimationFrame(this.updateAnimations)
    }

    debouncedUpdateAnimations = throttle(() => {
        if (this.playing) {
            try {
                this.store.updateAnimations()
            } catch (e) {
                console.error('updateAnimations', e)
            }
        }
    }, 33)

    onUpdateMeters = (data: any) => {
        if (!data) {
            return
        }

        this.firstPacket = true

        const {peaks, rms} = data

        for (const name of ['masterVolumeLeftRms', 'masterVolumeLeftPeak', 'masterVolumeRightRms', 'masterVolumeRightPeak']) {
            if (!this.measurements[name]) {
                this.measurements[name] = new InterpolatingValue(name, [], 0)
            }
        }

        this.measurements.masterVolumeLeftPeak.push(peaks[0])
        this.measurements.masterVolumeRightPeak.push(peaks[1])

        this.measurements.masterVolumeLeftRms.push(rms[0])
        this.measurements.masterVolumeRightRms.push(rms[1])
    }

    onUpdatePosition = (data: any) => {
        if (!data) {
            return
        }

        this.firstPacket = true

        const {position, numSamples} = data

        this.position = position
        this.scriptProcessorBufferSize = numSamples
    }

    checkIfBrowserFirefox = () => {
        const browser = detect()
        return browser !== null && browser.name === 'firefox'
    }

    @action
    setLufsEnabled = (nextState: boolean) => {
        this.lufsEnabled = nextState
    }

    @action
    setEncoding(e: EncodingId) {
        this.encoding = e
    }

    @computed
    get bandwidthRequired(): number {
        const e = Encodings[this.encoding] as any
        return (e.bandwidth * this.sampleRate) / (e.sampleRateLimit ? e.sampleRateLimit : 44100)
    }

    @computed
    get deviceOrderData() {
        const {session} = this
        if (session) {
            const removeVCFrom = ['telefunken/m15', 'studer/a812']
            const vcName = 'distopik/volume2'

            return map(session.deviceOrder, (deviceId, i) => {
                const simpleDeviceId = (Array.isArray(toJS(deviceId)) ? deviceId[0] : deviceId) as string
                const deviceDesc = session.devices[simpleDeviceId]
                const deviceType = deviceDesc.type

                return {deviceId, simpleDeviceId, deviceDesc, deviceType}
            }).filter((data, index, self) => {
                if (data.deviceType === vcName && self.length - 1 > index) {
                    const next = self[index + 1]
                    return !removeVCFrom.includes(next.deviceType)
                }
                return true
            })
        }
        return undefined
    }

    @computed
    get bypassableDeviceOrder() {
        /* only bypassable devices */
        if (this.deviceOrderData) {
            return this.deviceOrderData.filter(d => d.deviceId !== 'distopik/insert').map(d => d.deviceType)
        }
        return undefined
    }

    @action
    onAudioReady(item: IAudioReady) {
        try {
            if (this.node) {
                //send for playback
                this.node.sendPayload('encoded_audio', item)
            }

            if (!this.startedPlaying) {
                this.startedPlaying = true
            }
            this.lastPacket = !item.haveMore
            this.lastPacketTimestamp = new Date().getTime()
            if (item.buffer) {
                if (!item.measurements) {
                    return
                }

                for (const m of item.measurements) {
                    if (!m.measurement || !m.name) {
                        continue
                    }

                    const target = this.measurements[m.name]

                    if (!target) {
                        console.log('new measurment', m.name)
                        this.measurements[m.name] = new InterpolatingValue(m.name, m.measurement, this.startPosition)
                    } else if (target.hasSpace) {
                        target.push(m.measurement)
                    }
                }
            }
        } catch (e) {
            console.error('onAudioReady', e)
        }
    }

    @action
    onTerminated(e: ITerminated) {
        /* cleanup */
        console.log('terminated: ', e.reason)
        this.store && this.store.onPlayerTerminated()
        this.stop()
    }

    @action
    onErrorOrWarning(event: IErrorOrWarning): void {
        if (event.error) {
            console.error(event.error)
        }
        if (event.warning) {
            console.warn(event.warning)
        }
    }

    @action
    onReady(event: IReady): void {
        console.log('engine ready')
    }

    @action.bound
    onPlaying = () => {
        this.playing = true
    }

    @action.bound
    onStopped = () => {
        this.playing = false
    }

    @action
    async start(startPos?: number): Promise<void> {
        if (!this.node) {
            console.warn('node is null run away from the browser')
            return
        }

        const browser = detect()

        await this.audioContext.resume()
        this.measurements = {}
        this.lastPacket = false
        this.prevPos = undefined

        this.startPosition = startPos ? startPos : 0
        this.position = this.startPosition
        this.startedPlaying = false

        //Where are the coddings

        console.log('--- start ---', this.startPosition, browser)

        const encoding = Encodings[this.encoding]

        this.node.sendPayload(
            'setup',
            Object.assign(encoding, {
                sampleRate: this.sampleRate,
                position: this.startPosition,
                is_safari: browser && browser.name === 'safari',
                sampleBuffer: this.buffering,
            }),
        )

        this.node.on('meters', this.onUpdateMeters)
        this.node.on('position', this.onUpdatePosition)
        this.node.on('playing', this.onPlaying)
        this.node.on('stopped', this.onStopped)
    }

    stop() {
        if (!this.node) {
            console.warn('node is null run away from the browser')
            return
        }

        console.log('--- stop ---')
        this.node.off('meters', this.onUpdateMeters)
        this.node.off('position', this.onUpdatePosition)
        this.node.off('playing', this.onPlaying)
        this.node.off('stopped', this.onStopped)

        this.onStopped()

        this.firstPacket = false
    }

    onUpdateEnd = () => {
    }

    @computed
    get bufferingTime() {
        return (this.buffering * Encodings[this.encoding].bufferSize) / this.sampleRate
    }

    waitForFirstPacket() {
        return new Promise<void>((resolve, reject) => {
            let attempts = 0

            const timer = setInterval(() => {
                if (this.firstPacket) {
                    clearInterval(timer)
                    resolve()
                }

                attempts++
                if (attempts > 210) {
                    clearInterval(timer)
                    reject(new Error('Timed out while waiting to receive first playback packet!'))
                }
            }, 50)
        })
    }

    checkIfLufsEnabled = () => this.lufsEnabled
}
