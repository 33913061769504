import {clamp, lerp} from '../utils'

export default class InterpolatingValue {
    private source: Array<number>
    private cur: number
    private debug: boolean = false
    private window?: Array<number>

    constructor(private name: string, data: Array<number>, private readonly startPos: number) {
        this.source = []
        this.cur = 0
        this.startPos = startPos

        this.push(data)
    }

    push(arr: Array<number>) {
        while (arr.length && !isFinite(arr[0])) {
            arr.shift()
            arr.shift()
        }

        this.source.push(...arr)

        if (this.debug) {
            console.log(this.name, 'pushed', arr)
        }

        while (this.source.length > 5000) {
            this.source.shift()
            this.source.shift()
        }
    }

    reset() {
        this.source = []
        this.cur = 0
    }

    getLast(): number {
        while (this.source.length > 2) {
            this.source.shift()
            this.source.shift()
        }

        return this.source[1]
    }

    getValue(pos: number, debug = false): number {
        this.debug = debug
        pos -= this.startPos
        pos *= 1000

        let shifted = 0
        let firstShifted = null

        while (this.source.length && this.source[2] < pos) {
            if (firstShifted == null) {
                firstShifted = this.source[0]
            }

            shifted++
            this.source.shift() // pos
            this.source.shift() // val
        }

        if (shifted > 0 && debug) {
            console.warn(this.name, 'shifted', shifted, 'samples, starting with', firstShifted, 'at', pos)
        }

        let lowIndex = 0
        let highIndex = 2

        const last = this.source[this.source.length - 1]

        const lowVal = this.source[lowIndex + 1]
        const highVal = this.source[highIndex + 1]

        const lowPos = this.source[lowIndex]
        const highPos = this.source[highIndex]

        const factor = clamp(Math.abs(pos - lowPos) / Math.max(highPos - lowPos, 0.001))

        if (!isFinite(factor)) {
            // console.warn(this.name, 'factor not finite', { pos, lowPos, highPos, last })
        }

        if (debug) {
            console.log(this.name, pos, Math.floor(lowIndex / 2), this.source.length / 2, Math.round(factor * 100) / 100, factor, this.source)
        }

        const rv = lerp(lowVal, highVal, factor)

        if (isFinite(rv)) {
            return rv
        } else if (isFinite(highVal)) {
            return highVal
        } else {
            return last
        }
    }

    getValueWithSmoothing(pos: number, lp: number = 9): number {
        const incoming = this.getValue(pos)
        pos -= this.startPos

        if (incoming > this.cur) {
            this.cur = incoming
        } else {
            this.cur = ((lp - 1) * this.cur + incoming) / lp
        }

        return this.cur
    }

    getValueWindowedRms(pos: number, size: number): number {
        if (!this.window) {
            this.window = []
        }

        this.window.push(this.getValue(pos))
        if (this.window.length > size) {
            this.window.shift()
        }

        let total = Math.sqrt(this.window.reduce((total, cur) => total + cur * cur, 0.0))
        if (!isFinite(total)) {
            total = 0
        }

        return total
    }

    get hasSpace(): boolean {
        return true
    }

    getSourceInfo() {
        const rv = []
        for (var i = 0; i < this.source.length; i += 2) {
            rv.push(this.source[i])
        }

        return rv
    }
}
