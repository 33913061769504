import React from 'react'

import {Context, ModelImageKnob, ModelToggleKnob} from '../ImageKnob'
import {clamp, factorToDb, mobx, px, rescale} from '../../utils'
import Store from '../../store'
import Needle from "../Needle";
import Overlay from "../Overlay";
import ToggleKnob from "../ToggleKnob";

enum AmplifierMode {
    RevisionA,
    RevisionD,
    RevisionG
}

const backgrounds = {
    [AmplifierMode.RevisionA]: require('./images/bg_a.jpg'),
    [AmplifierMode.RevisionD]: require('./images/bg_d.jpg'),
    [AmplifierMode.RevisionG]: require('./images/bg_g.jpg'),
}

const overlays = {
    [AmplifierMode.RevisionA]: require('./images/a_vu.png'),
    [AmplifierMode.RevisionD]: require('./images/d_vu.png'),
    [AmplifierMode.RevisionG]: require('./images/g_vu.png')
}

const RatioSwitch = mobx(
    class KRatioSwitch extends React.Component<{ store: Store; id: string }, { ratio: number }> {
        state = {ratio: 0}

        componentDidMount() {
            const {store} = this.props
            const {session} = store.player
            if (session && store.isSlotIndexPopulated(store.selectedSlotIndex)) {
                const slot = store.slots[store.selectedSlotIndex]
                this.setState({ratio: slot.settings[this.props.id].ratio})
            }

            store.on(`change.${this.props.id}.ratioLeft`, this.setRatio)
        }

        componentWillUnmount() {
            const {store} = this.props
            store.off(`change.${this.props.id}.ratioLeft`, this.setRatio)
        }

        setRatio = (ratio: any) => {
            console.log('setRatio', ratio)
            this.setState({ratio})
        }

        render() {
            let ratioIndex = 0
            if (this.state.ratio === 15) {
                ratioIndex = 4
            } else {
                for (let i = 1; i <= 4; i++) {
                    if (this.state.ratio & (1 << i)) {
                        ratioIndex = i
                        break
                    }
                }
            }

            return (
                <div
                    onClick={this.onClick}
                    style={{
                        backgroundImage: `url(${require('./images/ratio.png')})`,
                        backgroundPositionY: px(-(3 - ratioIndex) * 403),
                        position: 'absolute',
                        left: px(1709),
                        top: px(71),
                        height: px(403),
                        width: px(108),
                    }}
                />
            )
        }

        onClick = (e: any) => {
            const offY = Math.max(0, e.nativeEvent.offsetY - 4)
            const newRatio = 3 - Math.max(Math.min(Math.floor(offY / 90), 3), 0)

            const setRatio = (ratio: any) => {
                ;(this.props as any).store.player.session.devices[this.props.id].model.setValue('ratioLeft', ratio)
                ;(this.props as any).store.player.session.devices[this.props.id].model.setValue('ratioRight', ratio)
            }

            if (e.shiftKey) {
                setRatio(15)
            } else {
                setRatio(1 << newRatio)
            }
        }
    },
) as any

const AmpSwitch = mobx(
    class KAmpSwitch extends React.Component<{ store: Store; id: string }, { amp: AmplifierMode, bypass: boolean }> {
        state = {amp: AmplifierMode.RevisionA, bypass: false}

        componentDidMount() {
            const {store} = this.props
            const {session} = store.player

            if (session && store.isSlotIndexPopulated(store.selectedSlotIndex)) {
                const slot = store.slots[store.selectedSlotIndex]
                this.setState({
                    amp: session.product === 'a76' ? AmplifierMode.RevisionA : slot.settings[this.props.id].amplifierMode,
                    bypass: slot.settings[this.props.id].gainReductionDisableLeft
                })
            }

            store.on(`change.${this.props.id}.amplifierMode`, this.setAmplifierMode)
        }

        componentWillUnmount() {
            const {store} = this.props
            store.off(`change.${this.props.id}.amplifierMode`, this.setAmplifierMode)
        }

        setAmplifierMode = (amp: AmplifierMode) => {
            const {store} = this.props
            const {session} = store.player
            this.setState({amp: session && session.product === 'a76' ? AmplifierMode.RevisionA : amp})
        }

        render() {
            return (
                <div
                    onClick={this.onClick}
                    style={{
                        backgroundImage: `url(${require('./images/amp.png')})`,
                        backgroundPositionY: px(-(this.state.amp) * 403),
                        position: 'absolute',
                        left: px(2420),
                        top: px(71),
                        height: px(403),
                        width: px(81),
                    }}
                />
            )
        }

        onClick = (e: any) => {
            const {store} = this.props
            const {session} = store.player

            const offY = Math.max(0, e.nativeEvent.offsetY - 5)
            const newAmp = session && session.product === 'a76' ? AmplifierMode.RevisionA : Math.min(Math.floor(offY / 96), 2)

            this.setState({amp: newAmp})
            ;(this.props as any).store.player.session.devices[this.props.id].model.setValue('amplifierMode', newAmp)
        }
    },
) as any

export default mobx(
    class DistopikADG76 extends React.Component<{ id: string; store: Store; uiScale: number }, { vuMode: boolean }> {
        gainReduction: Needle | null = null
        state = {vuMode: false}

        static renderTitle() {
            return 'Distopik ADG76'
        }

        componentDidMount() {
            this.props.store.on('playingAnimation', this.updateAnimation)
        }

        componentWillUnmount() {
            this.props.store.off('playingAnimation', this.updateAnimation)
        }

        updateAnimation = () => {
            const {measurements: m, position: pos} = this.props.store.player

            try {
                let factor = this.state.vuMode ? m[`${this.props.id}.volumeLeft`].getValueWithSmoothing(pos) : m[`${this.props.id}.gainReductionLeft`].getValueWithSmoothing(pos)
                if (this.gainReduction) {
                    this.gainReduction.position = clamp(rescale(factor, 0.40613232510583364, 0.451683022053392345, 0.13, 0.75))
                }
            } catch (e) {
            }
        }

        render() {
            const {uiScale = 0.5, store, id} = this.props
            const {api, currentSlotSettings} = store

            const amplifierMode: AmplifierMode = currentSlotSettings && currentSlotSettings[id] ? currentSlotSettings[id].amplifierMode : AmplifierMode.RevisionA

            return (
                <Context.Provider value={{api, id} as any}>
                    <div
                        style={{
                            transform: `scale(${uiScale}, ${uiScale})`,
                            transformOrigin: 'left top',
                            margin: 0,
                            padding: 0,
                            width: px(2828 * uiScale),
                            height: px(519 * uiScale),
                        }}
                        onClick={e => e.preventDefault()}
                        onDoubleClick={e => e.preventDefault()}
                    >
                        <div
                            style={{
                                width: px(2828),
                                height: px(519),
                                margin: 0,
                                padding: 0,
                                background: `url('${backgrounds[amplifierMode]}')`,
                                backgroundAttachment: 'local',
                                backgroundSize: 'stretch',
                                boxShadow: 'black 0 4px 12px',
                                filter: store.globalBypass ? 'opacity(0.5)' : '',
                            }}
                            onClick={e => e.preventDefault()}
                            onDoubleClick={e => e.preventDefault()}
                        >
                            {/* ---- left knobs ---- */}
                            <ModelImageKnob name={"inputLevelLeft"} imageUrl={require('./images/input.png')} nf={61}
                                            x={270} y={119}/>
                            <ModelImageKnob name={"outputLevelLeft"} imageUrl={require('./images/output.png')} nf={61}
                                            x={792} y={119}/>
                            <ModelImageKnob name={"attackLeft"} imageUrl={require('./images/attack.png')} nf={61}
                                            x={1347} y={85}/>
                            <ModelImageKnob name={"releaseLeft"} imageUrl={require('./images/release.png')} nf={61}
                                            x={1347} y={320}/>

                            <RatioSwitch id={this.props.id}/>
                            <AmpSwitch id={this.props.id}/>

                            <Needle x={2118} y={388} length={190}
                                    startAngle={-0.798131701}
                                    endAngle={0.798131701}
                                    lineWidth={2}
                                    ref={e => this.gainReduction = e}
                            />

                            <Overlay x={1850} y={0} imageUrl={overlays[amplifierMode]} style={{zIndex: 100}}/>

                            <ToggleKnob imageUrl={require('./images/vugr.png')} x={1992} y={419}
                                        values={[false, true]}
                                        onChange={() => this.setState({vuMode: !this.state.vuMode})}
                                        style={{zIndex: 200}}/>

                            <ModelToggleKnob name={"gainReductionDisableLeft"}
                                             imageUrl={require('./images/gr.png')}
                                             x={2420}
                                             y={344}/>
                        </div>
                    </div>
                </Context.Provider>
            )
        }
    },
)
