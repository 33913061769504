import React from 'react'

import ImageKnob, { ModelImageKnob, ModelToggleKnob, Context } from '../ImageKnob'
import Needle from '../Needle'
import Overlay from '../Overlay'
import { px, mobx } from '../../utils'
import Store from '../../store'
import { toJS } from 'mobx'
import { CnbFeatures } from '../features'

// window.fcLeft = 1 / 0.347
// window.fcRight = 1 / 0.349

export default mobx(
    class Fairchild670 extends React.Component<{ id: string; uiScale: number; store: Store }> {
        leftGr?: Needle
        rightGr?: Needle

        static enabledCnbFeatures: Array<CnbFeatures> = ['mid/side', 'dry/wet']

        static renderTitle() {
            return 'Fairchild 670'
        }

        componentDidMount() {
            this.props.store.on('playingAnimation', this.updateAnimation)
        }

        componentWillUnmount() {
            this.props.store.off('playingAnimation', this.updateAnimation)
        }

        updateAnimation = () => {
            const { measurements: m, position: pos } = this.props.store.player
            const leftGRKey = `${this.props.id}.leftGainReduction`
            const rightGRKey = `${this.props.id}.rightGainReduction`

            const { leftGr, rightGr } = this

            try {
                let liPos = m[leftGRKey].getValueWithSmoothing(pos, 3) // Math.max(0, Math.min(0.5, )) * 1.6
                let riPos = m[rightGRKey].getValueWithSmoothing(pos, 3) // Math.max(0, Math.min(0.5, m[rightGRKey].getValue(pos))) * 1.6

                liPos = Math.max(0, liPos - 0.05)
                riPos = Math.max(0, riPos - 0.05)

                if (leftGr) {
                    leftGr.position = liPos * 0.85
                }

                if (rightGr) {
                    rightGr.position = riPos * 0.85
                }
            } catch (e) {
                console.error(e)
            }
        }

        render() {
            const { uiScale = 0.5, store, id } = this.props
            const { api } = store

            const { session } = this.props.store.player
            if (session) {
                const { model } = session.devices[this.props.id]
                model.setValue('linkPairs', toJS(store.slots[store.selectedSlotIndex].settings[id].linkPairs), true)
            }

            return (
                <Context.Provider value={{ api, id } as any}>
                    <div
                        style={{
                            transform: `scale(${uiScale}, ${uiScale})`,
                            transformOrigin: 'left top',
                            margin: 0,
                            padding: 0,
                            width: px(1414 * uiScale),
                            height: px(660 * uiScale),
                        }}
                        onClick={e => e.preventDefault()}
                        onDoubleClick={e => e.preventDefault()}
                    >
                        <div
                            style={{
                                width: px(1414),
                                height: px(660),
                                margin: 0,
                                padding: 0,
                                background: `url(${require('./images/bg.jpg')})`,
                                backgroundAttachment: 'local',
                                backgroundSize: 'stretch',
                                boxShadow: 'black 0 4px 12px',
                                filter: store.globalBypass ? 'opacity(0.5)' : '',
                            }}
                            onClick={e => e.preventDefault()}
                            onDoubleClick={e => e.preventDefault()}
                        >
                            <ModelImageKnob imageUrl={require('./images/Knobs_Time_Left_Up.png')} x={1098} y={108} nf={6} name={'leftTimeConstant'} />
                            <ModelImageKnob imageUrl={require('./images/Knobs_Time_Right_Down.png')} x={1118} y={466} nf={6} name={'rightTimeConstant'} />
                            <ModelImageKnob imageUrl={require('./images/AGC.png')} x={1148} y={308} nf={2} name={'midSide'} scaling={4} />
                            <ImageKnob imageUrl={require('./images/Knobs_Metering.png')} x={524} y={298} nf={3} initial={0.5} disabled />

                            <Overlay imageUrl={require('./images/Power_Led_2.png')} x={58 * 2} y={145 * 2} />

                            <ModelToggleKnob x={74 * 2} y={194 * 2} name={'link'} inverted initial={true} imageUrl={require('./images/Link_Switch.png')} />
                            <ModelToggleKnob x={74 * 2} y={245 * 2} inverted name={'linkPairs'} imageUrl={require('./images/Link_Switch.png')} />
                            <ModelImageKnob imageUrl={require('./images/Knob1.png')} x={490} y={88} nf={87} initial={0.75} name={'leftInput'} />
                            <ModelImageKnob imageUrl={require('./images/Knob4.png')} x={492} y={452} nf={87} initial={0.75} name={'rightInput'} />
                            <ModelImageKnob imageUrl={require('./images/Knob2.png')} x={702} y={88} nf={87} name={'leftThreshold'} initial={0.75} />
                            <ModelImageKnob imageUrl={require('./images/Knob5.png')} x={704} y={450} nf={87} name={'rightThreshold'} initial={0.75} />
                            <ModelImageKnob imageUrl={require('./images/Knob3.png')} x={912} y={86} nf={87} name={'leftRatio'} inverted initial={0} />
                            <ModelImageKnob imageUrl={require('./images/Knob6.png')} x={914} y={452} nf={87} name={'rightRatio'} inverted initial={0} />
                            <Needle x={350} y={250} length={140} startAngle={-0.698131701} endAngle={0.465421134} ref={(e: any) => (this.leftGr = e)} />
                            <Needle x={350} y={560} length={140} startAngle={-0.698131701} endAngle={0.465421134} ref={(e: any) => (this.rightGr = e)} />
                            <Overlay x={240} y={56} imageUrl={require('./images/VU_mask.png')} style={{ zIndex: 3 }} />
                        </div>
                    </div>
                </Context.Provider>
            )
        }
    },
)
