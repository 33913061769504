import React from 'react'

import { ModelImageKnob, ModelToggleKnob, Context } from '../ImageKnob'
import { px, mobx, factorToDb } from '../../utils'
import Store from '../../store'
import { toJS } from 'mobx'
import Overlay from '../Overlay'
import { range } from 'lodash'
import { relativeTimeThreshold } from 'moment'

function Sprite(props: { x: number, y: number, imageUrl: string, style?: any, opacity?: number }) {
    const { x, y, imageUrl, style = {}, opacity = 1 } = props

    return <img src={imageUrl} draggable={false} onDragStart={e => e.preventDefault()}
                onDoubleClick={e => e.preventDefault()} onClick={e => e.preventDefault()}
                style={{
                    userSelect: 'none',
                    position: 'absolute',
                    top: px(y),
                    left: px(x),
                    opacity,
                    ...style,
                }} alt=""/>
}

const cells: Array<[number, string, string]> = [
    [1750 - 11, require('./images/meters/01.png'), require('./images/meters/01+.png')],
    [1731 - 11, require('./images/meters/02.png'), require('./images/meters/02+.png')],
    [1713 - 11, require('./images/meters/03.png'), require('./images/meters/03+.png')],
    [1695 - 11, require('./images/meters/04.png'), require('./images/meters/04+.png')],
    [1676 - 11, require('./images/meters/05.png'), require('./images/meters/05+.png')],
    [1658 - 11, require('./images/meters/06.png'), require('./images/meters/06+.png')],
    [1640 - 11, require('./images/meters/07.png'), require('./images/meters/07+.png')],
    [1621 - 11, require('./images/meters/08.png'), require('./images/meters/08+.png')],
    [1603 - 11, require('./images/meters/09.png'), require('./images/meters/09+.png')],
    [1585 - 11, require('./images/meters/10.png'), require('./images/meters/10+.png')],
    [1566 - 11, require('./images/meters/11.png'), require('./images/meters/11+.png')],
    [1548 - 11, require('./images/meters/12.png'), require('./images/meters/12+.png')],
    [1529 - 11, require('./images/meters/13.png'), require('./images/meters/13+.png')],
    [1511 - 11, require('./images/meters/14.png'), require('./images/meters/14+.png')],
]
const limit = require('./images/meters/15.png')
const limitX = 1482

const XpressorMeter = mobx(class XpressorMeter extends React.Component<{ store: Store, id: string }, { gainReduction: number, gainReductionLimit: number }> {
    state = { gainReduction: 0, gainReductionLimit: 0 }

    componentDidMount() {
        this.props.store.on('playingAnimation', this.updateAnimation)
    }

    componentWillUnmount() {
        this.props.store.off('playingAnimation', this.updateAnimation)
    }

    updateAnimation = () => {
        const { measurements: m, position: pos } = this.props.store.player

        const gainReductionLimit = m[`${this.props.id}.gainReductionLimit`].getValue(pos)
        const gainReduction = m[`${this.props.id}.gainReduction`].getValue(pos)

        this.setState({ gainReduction, gainReductionLimit })
    }

    render() {
        const value = this.state.gainReduction
        const valueIndex = value * 14
        const grLimit = this.state.gainReductionLimit

        return <>
            {
                range(0, 14).map((i) => {
                    const key = `led_${i}`
                    const over = Math.max(Math.min(1, valueIndex - i), 0)

                    if (over == 0) {
                        return <div key={key}/>
                    } else if (over <= 0.5) {
                        return <Sprite x={cells[i][0]} y={115} key={key} imageUrl={cells[i][1]} opacity={over}/>
                    } else {
                        return <Sprite x={cells[i][0]} y={115} key={key} imageUrl={cells[i][2]} opacity={over}/>
                    }
                })
            }
            {
                grLimit ? <Sprite x={limitX} y={115} key={'grlimit'} imageUrl={limit} opacity={1.0}/> : undefined
            }
        </>
    }
})

export default mobx(
    class ElysiaXpressor extends React.Component<{ id: string; store: Store; uiScale: number }> {
        static renderTitle() {
            return 'elysia xpressor'
        }

        render() {
            const { uiScale = 0.5, store, id } = this.props
            const { api } = store

            const { session } = this.props.store.player

            return (
                <Context.Provider value={{ api, id } as any}>
                    <div
                        style={{
                            transform: `scale(${uiScale}, ${uiScale})`,
                            transformOrigin: 'left top',
                            margin: 0,
                            padding: 0,
                            width: px(2828 * uiScale),
                            height: px(259 * uiScale),
                        }}
                        onClick={e => e.preventDefault()}
                        onDoubleClick={e => e.preventDefault()}
                    >
                        <div
                            style={{
                                width: px(2828),
                                height: px(259),
                                margin: 0,
                                padding: 0,
                                background: `url(${require('./images/xpressor_bg.png')})`,
                                backgroundAttachment: 'local',
                                backgroundSize: 'stretch',
                                boxShadow: 'black 0 4px 12px',
                                filter: store.globalBypass ? 'opacity(0.5)' : '',
                            }}
                            onClick={e => e.preventDefault()}
                            onDoubleClick={e => e.preventDefault()}
                        >
                            {/* ---- left knobs ---- */}
                            <ModelImageKnob x={318} y={73} nf={61} imageUrl={require('./images/threshold.png')}
                                            name={'threshold'} initial={0.5}/>

                            <ModelImageKnob x={507} y={73} nf={41} imageUrl={require('./images/attack.png')}
                                            name={'attack'}/>

                            <ModelImageKnob x={697} y={73} nf={61} imageUrl={require('./images/release.png')}
                                            name={'release'} initial={0.5}/>

                            <ModelImageKnob x={885} y={73} nf={41} imageUrl={require('./images/ratio.png')}
                                            name={'ratio'} initial={1}/>

                            {/* ---- right knobs ---- */}
                            <ModelImageKnob x={1830} y={73} nf={61} imageUrl={require('./images/side_chain_filter.png')}
                                            name={'sideChainFilter'} initial={0.5}/>

                            <ModelImageKnob x={2018} y={73} nf={41}
                                            imageUrl={require('./images/gain_reduction_limit.png')}
                                            name={'gainReductionLimit'}/>

                            <ModelImageKnob x={2207} y={73} nf={61} imageUrl={require('./images/gain.png')}
                                            name={'gain'} initial={0.5}/>

                            <ModelImageKnob x={2397} y={73} nf={41} imageUrl={require('./images/mix.png')}
                                            name={'mix'} initial={1}/>

                            {/* ---- left toggles ---- */}
                            <ModelToggleKnob x={1066} y={108} name={'bypass'}
                                             imageUrl={require('./images/bypass.png')}/>

                            <ModelToggleKnob x={1137} y={108} name={'warm'}
                                             imageUrl={require('./images/warm.png')}/>

                            <ModelToggleKnob x={1208} y={108} name={'logRelease'}
                                             imageUrl={require('./images/log_release.png')}/>

                            <ModelToggleKnob x={1279} y={108} name={'autoFast'}
                                             imageUrl={require('./images/auto_fast.png')}/>

                            <XpressorMeter id={this.props.id}/>

                            <Overlay imageUrl={require('./images/elysia_on.jpg')} x={1348} y={65}/>
                        </div>
                    </div>
                </Context.Provider>
            )
        }
    },
)
