import React from "react";
import _ from 'lodash'

import {mobx, px} from "../../utils";
import Store from "../../store";
import {Context, ModelImageKnob, ModelToggleKnob} from "../ImageKnob";

export default mobx(
    class BettermakerBusCompressor extends React.Component<{ id: string; store: Store; uiScale: number }, { lcd: number[] }> {
        state = {
            lcd: new Array(15).fill(0)
        }

        static renderTitle() {
            return 'bettermaker bus compressor'
        }

        componentDidMount(): void {
            this.props.store.on('playingAnimation', this.updateAnimation)
        }

        componentWillUnmount() {
            this.props.store.off('playingAnimation', this.updateAnimation)
        }

        updateAnimation = () => {
            const {measurements, position: pos} = this.props.store.player
            const lcd = new Array(15).fill(0)
            for (let i = 15; i >= 0; i--) {
                const m = measurements[`${this.props.id}.line${i}`]
                if (m) {
                    lcd[i] = m.getLast()
                }
            }

            this.setState({lcd})
        }

        render() {
            const {uiScale = 0.5, store, id} = this.props
            const {lcd} = this.state
            const {api} = store

            return (
                <Context.Provider value={{api, id} as any}>
                    <div
                        style={{
                            transform: `scale(${uiScale}, ${uiScale})`,
                            transformOrigin: 'left top',
                            margin: 0,
                            padding: 0,
                            width: px(2828 * uiScale),
                            height: px(282 * uiScale),
                        }}
                        onClick={e => e.preventDefault()}
                        onDoubleClick={e => e.preventDefault()}
                    >
                        <div
                            style={{
                                width: px(2828),
                                height: px(282),
                                margin: 0,
                                padding: 0,
                                background: `url(${require('./images/bg2.jpg')})`,
                                backgroundAttachment: 'local',
                                backgroundSize: 'stretch',
                                boxShadow: 'black 0 4px 12px',
                                filter: store.globalBypass ? 'opacity(0.5)' : '',
                            }}
                            onClick={e => e.preventDefault()}
                            onDoubleClick={e => e.preventDefault()}
                        >
                            {
                                _.range(7).map(i => (
                                    _.range(32).map(j => (
                                        <div key={`${i}_${j}`} style={{
                                            position: 'absolute',
                                            width: '10px',
                                            height: '10px',
                                            borderRadius: '10px',
                                            left: `${1600 + j * 16}px`,
                                            top: `${27 + (i - 1) * 16}px`,
                                            background: (!!(lcd[14 - i] & (1 << (31 - j)))) ? 'red' : 'none'
                                        }}/>
                                    ))
                                ))
                            }
                            {
                                _.range(8, 15).map(i => (
                                    _.range(32).map(j => (
                                        <div key={`${i}_${j}`} style={{
                                            position: 'absolute',
                                            width: '10px',
                                            height: '10px',
                                            borderRadius: '10px',
                                            left: `${1600 + j * 16}px`,
                                            top: `${137 + (i - 8) * 16}px`,
                                            background: (!!(lcd[14 - i] & (1 << (31 - j)))) ? 'red' : 'none'
                                        }}/>
                                    ))
                                ))
                            }
                            <ModelImageKnob name={"threshold"} imageUrl={require('./images/threshold.png')} nf={61}
                                            x={310} y={86}/>

                            <ModelImageKnob name={"ratio"} imageUrl={require('./images/ratio.png')} nf={5} x={504}
                                            y={86}/>

                            <ModelImageKnob name={"attack"} imageUrl={require('./images/attack.png')} nf={61} x={702}
                                            y={86}/>

                            <ModelImageKnob name={"release"} imageUrl={require('./images/release.png')} nf={61} x={896}
                                            y={86}/>

                            <ModelImageKnob name={"mix"} imageUrl={require('./images/mix.png')} nf={61} x={1095}
                                            y={86}/>

                            <ModelImageKnob name={"gain"} imageUrl={require('./images/gain.png')} nf={61} x={1291}
                                            y={86}/>

                            <ModelImageKnob name={"vcaThd"} imageUrl={require('./images/vcathd.png')} nf={61} x={2246}
                                            y={52}/>

                            <ModelImageKnob name={"sidechain"} imageUrl={require('./images/hpf.png')} nf={18} x={2443}
                                            y={52}/>

                            <ModelToggleKnob name={"engage"} imageUrl={require('./images/engage.png')} x={161} y={117}/>

                            <ModelToggleKnob name={"thdEnable"} imageUrl={require('./images/thd_enable.png')} x={2170}
                                             y={77}/>

                            <ModelToggleKnob name={"sidechainListen"} imageUrl={require('./images/sc_listen.png')}
                                             x={2573} y={54}/>

                            <ModelToggleKnob name={"detector"} imageUrl={require('./images/peak_rms.png')} x={2190}
                                             y={220}/>
                        </div>
                    </div>
                </Context.Provider>)
        }
    })
