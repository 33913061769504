import React from 'react'
import { Context, ModelImageKnob } from '../ImageKnob'
import ToggleKnob from '../ToggleKnob'
import Needle from '../Needle'
import Overlay from '../Overlay'
import { mobx, px } from '../../utils'
import Store from '../../store'
import Rect from '../rect'
import { Device } from '../../api'

const deflect = 0.758131701

export default mobx(
    class DistopikVCAMono extends React.Component<{ id: string; uiScale: number; store: Store, device: Device }> {
        gr?: Needle
        lastVal: any = null

        static renderTitle() {
            return 'Gold Can VCA'
        }

        componentDidMount() {
            this.props.store.on('playingAnimation', this.updateAnimation)
        }

        componentWillUnmount() {
            this.props.store.off('playingAnimation', this.updateAnimation)
        }

        updateAnimation = () => {
            const { measurements, position: pos } = this.props.store.player
            const m = measurements[`${this.props.id}.leftGainReduction`]

            try {
                let val = m.getValue(pos)
                val = Math.min((Math.max(0, 1320 / 0xfff - val) / (1 - 1320 / 0xfff)) * 2, 1)

                if (this.gr) {
                    this.gr.position = val
                }
            } catch (e) {
            }
        }

        changeToDual = () => {
            this.props.store.setSlotSettings([
                { parameter: 'linkPairs', processor: this.props.id, value: 0 },
            ], true)
        }

        toggleDual = () => {
            const { session } = this.props.store.player
            if (session) {
                const { settings } = session.slots[this.props.store.selectedSlotIndex]

                this.props.store.setSlotSettings([
                    { parameter: 'linkPairs', processor: this.props.id, value: settings[this.props.id].linkPairs ? 0 : 1 },
                ], true)
            }
        }

        changeToSingle = () => {
            this.props.store.setSlotSettings([
                { parameter: 'linkPairs', processor: this.props.id, value: 1 },
            ], true)
        }

        render() {
            const { uiScale = 0.5, store, id } = this.props
            const { api } = store

            return (
                <Context.Provider value={{ api, id } as any}>
                    <div
                        onClick={e => e.preventDefault()}
                        onDragStart={e => e.preventDefault()}
                        style={{
                            transform: `scale(${uiScale}, ${uiScale})`,
                            transformOrigin: 'left top',
                            margin: 0,
                            padding: 0,
                            width: px(1414 * uiScale),
                            height: px(176 * uiScale),
                        }}
                    >
                        <div
                            onClick={e => e.preventDefault()}
                            onDoubleClick={e => e.preventDefault()}
                            onDragStart={e => e.preventDefault()}
                            style={{
                                width: px(1414),
                                height: px(176),
                                margin: 0,
                                padding: 0,
                                background: `url(${require('./images/BG.png')})`,
                                backgroundAttachment: 'local',
                                backgroundSize: 'stretch',
                                boxShadow: 'black 0 4px 12px',
                                filter: store.globalBypass ? 'opacity(0.5)' : '',
                            }}
                        >
                            <ModelImageKnob name={'leftThreshold'} x={261} y={46} nf={149} inverted
                                            imageUrl={require('./images/thresh.png')}/>
                            <ModelImageKnob name={'leftAttack'} x={399} y={46} nf={9} imageUrl={require('./images/attack.png')}/>
                            <ModelImageKnob name={'leftRatio'} x={535} y={46} nf={6} imageUrl={require('./images/ratio.png')}/>
                            <ModelImageKnob name={'leftRelease'} x={671} y={46} nf={7} imageUrl={require('./images/release.png')}/>
                            <ModelImageKnob name={'leftMakeupGain'} x={808} y={46} nf={149} imageUrl={require('./images/makeup.png')}/>
                            <ModelImageKnob name={'leftSidechainFilter'} x={945} y={46} nf={6} imageUrl={require('./images/scfilter.png')}/>
                            <ModelImageKnob name={'linkLevel'} x={1082} y={46} nf={149} imageUrl={require('./images/link.png')} inverted/>
                            <ToggleKnob name={'bypass'} x={1206} y={46} imageUrl={require('./images/bypass.png')} disabled/>
                            <Needle x={177} y={108} length={71} startAngle={-deflect} endAngle={deflect} lineStyle="white"
                                    ref={(n: any) => (this.gr = n)}/>
                            <Overlay imageUrl={require('./images/VUMeterMask.png')} x={100} y={15} style={{ zIndex: 900 }}/>

                            <Rect x={89} y={137} w={72} h={31} onClick={this.changeToDual}/>
                            <Rect x={164} y={137} w={32} h={31} onClick={this.toggleDual}/>
                            <Rect x={199} y={137} w={66} h={31} onClick={this.changeToSingle}/>
                        </div>
                    </div>
                </Context.Provider>
            )
        }
    },
) as any
