import React from 'react'
import { ModelImageKnob, ModelToggleKnob, Context } from '../ImageKnob'
import { ModelSliderKnob } from '../SliderKnob'
import { px, mobx } from '../../utils'
import AnimatedCanvas from '../AnimatedCanvas'

import flatten from 'lodash/flatten'
import Store from '../../store'
import styled from 'styled-components'

const ClippiterLabelOverlay = styled.div`
    background-image: url(${require('./images/clippiter.jpg')});
    position: absolute;
    top: 195px;
    left: 415px;
    width: 108px;
    height: 19px;
`

const valueConstraints = {
    input: {
        min: 0,
        max: +20,
    },
    clippiter: {
        min: 0,
        max: 100,
    },
    width: {
        altFactor: 0.5,
        min: -8,
        max: +8,
    },
    release: {
        altFactor: 0.1,
        min: 10,
        max: 1300,
    },
    output: {
        altFactor: 0.5,
        min: -5,
        max: +15,
    },
    drive: {
        altFactor: 0.2,
        min: 0,
        max: 100,
    },
    freq: {
        min: 1,
        max: 31,
    },
    attack: {
        altFactor: 0.1,
        min: 1,
        max: 2500,
    },
    small: {
        altFactor: 0.1,
    },
}

function midSideGuiToDisplay(v: number): string {
    // console.log('midSideGuiToDisplay', v, midSideModelToGui(v))
    return midSideModelToGui(v).toFixed(1)
}

function midSideModelToGui(v: number) {
    if (v >= 0xff - 80) {
        return (v - 0xff) / 10
    } else {
        return v / 10
    }
}

function midSideGuiToModel(v: number): number {
    if (v >= 0) {
        return Math.round(v * 10)
    } else {
        return Math.round(0xff + v * 10)
    }
}

const frequencies = [35, 45, 56, 67, 83, 100, 130, 160, 200, 300, 440, 560, 700, 900, 1050, 1250, 1500, 2000, 3000, 4000, 5000, 6000, 7000, 8500, 10000, 11500, 13500, 15000, 17000, 19000, 21000, 23000]

function VLine({ x, y, y2, w, bg = 'white' }: any) {
    const ystart = Math.min(y, y2)
    const height = Math.abs(y - y2)

    return (
        <div
            style={{
                width: px(w),
                position: 'absolute',
                left: px(x),
                top: px(ystart),
                height: px(height),
                backgroundColor: bg,
            }}
        />
    )
}

export default mobx(
    class BetterLimiter extends React.Component<
        {
            id: string
            uiScale: number
            store: Store
            masonry: any
        },
        { odd: number; even: number; clipperEnabled: boolean }
    > {
        state = { odd: 1, even: 1, clipperEnabled: false }

        static renderTitle() {
            return 'Bettermaker Mastering Limiter'
        }

        componentDidMount() {
            const { store } = this.props
            for (const id of flatten([this.props.id])) {
                const settings = store.isSlotIndexPopulated(store.selectedSlotIndex) ? store.slots[store.selectedSlotIndex].settings[id] : undefined

                if (settings) {
                    const newState = {
                        odd: (settings.oddAmount - 1) / 20,
                        even: (settings.evenAmount - 1) / 20,
                        clipperEnabled: !!settings.clipperEnabled,
                    }
                    this.setState(newState)

                    break
                }
            }
        }

        render() {
            let { uiScale = 0.5, id, store } = this.props
            const { api } = store
            const height = 270
            const width = 1414
            const background = require('./images/bettermaker_bg_x.png')

            // uiScale *= 1.2
            console.log('this.state.clipperEnabled', this.state.clipperEnabled)

            return (
                <Context.Provider value={{ api, id } as any}>
                    <div
                        style={{
                            transform: `scale(${uiScale}, ${uiScale})`,
                            transformOrigin: 'left top',
                            margin: 0,
                            padding: 0,
                            width: px(width * uiScale),
                            height: px(height * uiScale),
                        }}
                        onClick={e => e.preventDefault()}
                        onDoubleClick={e => e.preventDefault()}
                    >
                        <div
                            style={{
                                width: px(width),
                                height: px(height),
                                margin: 0,
                                padding: 0,
                                background: `url(${background})`,
                                backgroundAttachment: 'local',
                                backgroundSize: 'stretch',
                                boxShadow: 'black 0 4px 12px',
                                filter: store.globalBypass ? 'opacity(0.5)' : '',
                            }}
                            onClick={e => e.preventDefault()}
                            onDoubleClick={e => e.preventDefault()}
                        >
                            <ModelImageKnob name={'inputLevel'} imageUrl={require('./images/input.png')} x={91} y={59} nf={60} max={200} />
                            <ModelSliderKnob height={35} width={96} x={311} y={207} name={'inputLevel'} toDisplay={(v: any) => ((v / 200) * 20).toFixed(1)} constraints={valueConstraints.input} />

                            {this.state.clipperEnabled ? (
                                <React.Fragment>
                                    <ClippiterLabelOverlay />
                                    <ModelSliderKnob altFactor={valueConstraints.attack.altFactor} height={35} width={96} x={421} y={207} name={'clippiter'} toDisplay={(v: any) => Math.round(v)} constraints={valueConstraints.clippiter} />
                                </React.Fragment>
                            ) : (
                                <ModelSliderKnob altFactor={valueConstraints.attack.altFactor} height={35} width={96} x={421} y={207} name={'attack'} toDisplay={(v: any) => Math.round(v)} constraints={valueConstraints.attack} />
                            )}

                            <ModelToggleKnob name={'measureOutput'} imageUrl={require('./images/output_level.jpg')} x={317} y={46} />

                            <ModelSliderKnob height={35} width={96} x={531} y={207} name={'midSideWidth'} guiConstraints={valueConstraints.width} altFactor={valueConstraints.width.altFactor} guiToModel={midSideGuiToModel} modelToGui={midSideModelToGui} toDisplay={midSideGuiToDisplay} />
                            <ModelSliderKnob height={35} width={96} x={641} y={207} name={'release'} altFactor={valueConstraints.release.altFactor} toDisplay={(v: any) => Math.round(v)} />
                            <ModelSliderKnob height={35} width={96} x={751} y={207} name={'outputLevel'} toDisplay={(v: any) => ((v / 200) * 20 - 15).toFixed(1)} constraints={valueConstraints.output} />

                            <ModelSliderKnob height={40} width={35} x={859} y={212} name={'evenDrive'} altFactor={valueConstraints.drive.altFactor} color="white" toDisplay={(v: any) => Math.round((v - 1) * 5)} fontSize="1em" constraints={valueConstraints.drive} boostFactor={1.5} />

                            <ModelSliderKnob height={40} width={35} x={890} y={212} name={'oddDrive'} altFactor={valueConstraints.drive.altFactor} color="#f28c0e" toDisplay={(v: any) => Math.round((v - 1) * 5)} fontSize="1em" constraints={valueConstraints.drive} boostFactor={1.5} />

                            <ModelSliderKnob height={40} width={35} x={933} y={212} name={'evenFrequency'} color="white" toDisplay={(v: any) => frequencies[Math.floor(v) - 1]} fontSize="1em" constraints={valueConstraints.freq} boostFactor={1.5} />
                            <ModelSliderKnob height={40} width={35} x={985} y={212} name={'oddFrequency'} color="#f28c0e" toDisplay={(v: any) => frequencies[Math.round(v) - 1]} fontSize="1em" constraints={valueConstraints.freq} boostFactor={1.5} />

                            <ModelSliderKnob height={40} width={35} x={1040} y={212} name={'evenAmount'} color="white" toDisplay={(v: any) => Math.round((v - 1) * 5)} fontSize="1em" constraints={valueConstraints.drive} onChange={(v: any) => this.setState({ even: (v - 1) / 20 })} boostFactor={1.5} />
                            <ModelSliderKnob height={40} width={35} x={1070} y={212} name={'oddAmount'} color="#f28c0e" toDisplay={(v: any) => Math.round((v - 1) * 5)} fontSize="1em" constraints={valueConstraints.drive} onChange={(v: any) => this.setState({ odd: (v - 1) / 20 })} boostFactor={1.5} />

                            <ModelImageKnob name={'outputLevel'} imageUrl={require('./images/output.png')} x={1171} y={59} nf={60} max={200} />
                            <ModelToggleKnob x={38} y={37} name={'processingEnabled'} imageUrl={require('./images/bypass.png')} />
                            <ModelToggleKnob x={46} y={119} name={'clipperEnabled'} imageUrl={require('./images/clipper.png')} onToggle={(v: any) => this.setState({ clipperEnabled: v })} />
                            <ModelToggleKnob x={46} y={175} name={'midSideEnabled'} imageUrl={require('./images/ms.png')} />
                            <ModelToggleKnob x={1336} y={63} name={'intelligentReleaseEnabled'} imageUrl={require('./images/irel.png')} />
                            <ModelToggleKnob x={1336} y={119} name={'oddColorEnabled'} imageUrl={require('./images/odd.png')} />
                            <ModelToggleKnob x={1336} y={174} name={'evenColorEnabled'} imageUrl={require('./images/even.png')} />

                            <AnimatedCanvas x={331} y={68} height={12} width={481} render={ctx => this.renderPeakMeter(ctx, 'peakLevelLeft')} src={this.props.store} />

                            <AnimatedCanvas x={331} y={83} height={12} width={481} render={ctx => this.renderPeakMeter(ctx, 'peakLevelRight')} src={this.props.store} />

                            <AnimatedCanvas x={332} y={138} height={12} width={262} render={ctx => this.renderGRMeter(ctx, 'gainReductionLeft')} src={this.props.store} />

                            <AnimatedCanvas x={332} y={153} height={12} width={262} render={ctx => this.renderGRMeter(ctx, 'gainReductionRight')} src={this.props.store} />

                            <AnimatedCanvas x={627} y={138} height={24} width={188} render={this.renderClipMeter} src={this.props.store} />

                            <VLine x={901} y={161} y2={161 - this.state.even * 70} w={2} />
                            <VLine x={922} y={161} y2={161 - this.state.odd * 45} w={2} bg="#f28c0e" />

                            <VLine x={941} y={161} y2={161 - this.state.even * 31} w={2} />
                            <VLine x={962} y={161} y2={161 - this.state.odd * 20} w={2} bg="#f28c0e" />

                            <VLine x={982} y={161} y2={161 - this.state.even * 14} w={2} />
                            <VLine x={1003} y={161} y2={161 - this.state.odd * 9} w={2} bg="#f28c0e" />

                            <VLine x={1025} y={161} y2={161 - this.state.even * 6} w={2} />
                            <VLine x={1047} y={161} y2={161 - this.state.odd * 4} w={2} bg="#f28c0e" />

                            <VLine x={1065} y={161} y2={161 - this.state.odd * 2} w={2} />
                        </div>
                    </div>
                </Context.Provider>
            )
        }

        renderClipMeter = (ctx: any) => {
            const m = this.props.store.player.measurements[`${this.props.id}.clipLeft`]
            if (m) {
                const { width, height } = ctx.canvas
                const { position } = this.props.store.player
                const val = m.getValue(position)
                const dx = width / 49
                ctx.clearRect(0, 0, width, height)

                ctx.fillStyle = '#4bcb3b'
                const escape = (width * val) / 256

                for (let x = 0; x < 17 * dx; x += dx) {
                    if (escape <= x) {
                        break
                    }
                    ctx.fillRect(x, 2, dx / 4, height - 2)
                }

                ctx.fillStyle = '#ee931e'
                for (let x = 17 * dx; x < 38 * dx; x += dx) {
                    if (escape <= x) {
                        break
                    }
                    ctx.fillRect(x, 2, dx / 4, height - 2)
                }

                ctx.fillStyle = '#b71a23'
                for (let x = 38 * dx; x < 49 * dx; x += dx) {
                    if (escape <= x) {
                        break
                    }
                    ctx.fillRect(x, 2, dx / 4, height - 2)
                }
            }
        }

        renderGRMeter = (ctx: any, name: any) => {
            const m = this.props.store.player.measurements[`${this.props.id}.${name}`]
            if (m) {
                const { width, height } = ctx.canvas
                const { position } = this.props.store.player
                const val = m.getValue(position)
                const dx = width / 65

                ctx.clearRect(0, 0, width, height)

                const escape = ((1023 - val) / 1023) * width
                // console.log('renderGRMeter', name, escape)
                ctx.fillStyle = '#14a1d9'

                for (let x = 0; x < width; x += dx) {
                    if (x <= escape) continue

                    ctx.fillRect(x, 2, dx / 4, height - 2)
                }
            }
        }

        renderPeakMeter = (ctx: any, name: any) => {
            /*
            val = int(256*(unsigned char)inputBuffer[7]+(unsigned char)inputBuffer[8]);
            if(val > 32768) val -= 65536;
            meterValuesToSend.peakLevelL = ((float)val)*0.1f;

            val = int(256*(unsigned char)inputBuffer[9]+(unsigned char)inputBuffer[10]);
            if(val > 32768) val -= 65536;
            meterValuesToSend.peakLevelR = ((float)val)*0.1f;
            */

            const m = this.props.store.player.measurements[`${this.props.id}.${name}`]
            if (m) {
                const { width, height } = ctx.canvas
                const { position } = this.props.store.player
                //
                let val = m.getValue(position)
                if (val > 32768) val -= 65536
                const dx = width / 130

                let dbs = val * 0.1
                if (dbs < -36) {
                    dbs = -36
                }

                // console.log('renderPeakMeter', name, val)

                ctx.clearRect(0, 0, width, height)

                ctx.fillStyle = '#4bcb3b'
                const escape = ((dbs + 36) / 39) * width

                for (let x = -dx / 2; x < 110 * dx; x += dx) {
                    if (escape <= x) {
                        break
                    }
                    ctx.fillRect(x + dx / 2, 2, dx / 2, height - 2)
                }

                ctx.fillStyle = '#ee931e'
                for (let x = 110 * dx; x < 120 * dx; x += dx) {
                    if (escape <= x) {
                        break
                    }
                    ctx.fillRect(x + dx / 2, 2, dx / 2, height - 2)
                }

                ctx.fillStyle = '#b71a23'
                for (let x = 120 * dx; x < 130 * dx; x += dx) {
                    if (escape <= x) {
                        break
                    }
                    ctx.fillRect(x + dx / 2, 2, dx / 2, height - 2)
                }
            }
        }
    },
)
