import React from 'react'
import { Context, ModelImageKnob } from '../ImageKnob'
import Needle from '../Needle'
import Overlay from '../Overlay'
import { mobx, px } from '../../utils'
import Store from '../../store'
import { Device } from '../../api'

type Coeffs = [number, number, number, number, number]

class Biquad {
    state = [0, 0]

    constructor(private coeffs: Coeffs) {}

    filter(x0: number): number {
        let [w1, w2] = this.state
        const [b0, b1, b2, a1, a2] = this.coeffs

        let accumulator = w2 * a2
        accumulator += w1 * a1
        accumulator += x0

        let w0 = accumulator

        accumulator = w0 * b0
        accumulator += w1 * b1
        accumulator += w2 * b2

        w2 = w1
        w1 = w0

        this.state[0] = w1
        this.state[1] = w2

        return accumulator
    }
}

export default mobx(
    class GyrafG24 extends React.Component<{ id: string; uiScale: number; store: Store; device: Device }> {
        gr?: Needle
        lastVal: any = null

        // b0, b1, b2, a1, a2
        filter1 = new Biquad([0.05649682460900102, 0.11299364921800203, 0.05649682460900102, 1.5072474896754453, -0.7395999999999999])

        // filter2 = new Biquad([])

        static renderTitle() {
            return 'Gyraf G24 Passive/Aggressive'
        }

        componentDidMount() {
            this.props.store.on('playingAnimation', this.updateAnimation)
        }

        componentWillUnmount() {
            this.props.store.off('playingAnimation', this.updateAnimation)
        }

        timer = 0

        updateAnimation = () => {
            const { measurements, position: pos } = this.props.store.player
            const m = measurements[`${this.props.id}.gainReduction`]

            try {
                let accumulator = this.filter1.filter(m.getValue(pos))

                this.timer += 1
                if (this.timer % 100 == 0) {
                    console.log(accumulator)
                }

                // val = Math.min((Math.max(0, 1320 / 0xfff - val) / (1 - 1320 / 0xfff)) * 2, 1)

                if (this.gr) {
                    this.gr.position = 1 - Math.min(1.2, accumulator * 1.5)
                }
            } catch (e) {}
        }

        render() {
            let { uiScale = 0.5 } = this.props
            const { store, id } = this.props
            const { api } = store

            uiScale *= 2

            return (
                <Context.Provider value={{ api, id } as any}>
                    <div
                        onClick={e => e.preventDefault()}
                        onDragStart={e => e.preventDefault()}
                        style={{
                            transform: `scale(${uiScale}, ${uiScale})`,
                            transformOrigin: 'left top',
                            margin: 0,
                            padding: 0,
                            width: px(1414 * uiScale),
                            height: px(258 * uiScale),
                        }}
                    >
                        <div
                            onClick={e => e.preventDefault()}
                            onDoubleClick={e => e.preventDefault()}
                            onDragStart={e => e.preventDefault()}
                            style={{
                                width: px(1414),
                                height: px(258),
                                margin: 0,
                                padding: 0,
                                background: `url(${require('./images/bg.jpg')})`,
                                backgroundAttachment: 'local',
                                backgroundSize: 'stretch',
                                boxShadow: 'black 0 4px 12px',
                                filter: store.globalBypass ? 'opacity(0.5)' : '',
                            }}
                        >
                            <ModelImageKnob name={'thresholdA'} imageUrl={require('./images/ath.png')} nf={61} x={142} y={17} />
                            <ModelImageKnob name={'thresholdB'} imageUrl={require('./images/bth.png')} nf={61} x={142} y={147} />

                            <ModelImageKnob name={'ratioA'} imageUrl={require('./images/art.png')} nf={61} x={253} y={17} />
                            <ModelImageKnob name={'ratioB'} imageUrl={require('./images/brt.png')} nf={61} x={253} y={147} />

                            <ModelImageKnob name={'attackA'} imageUrl={require('./images/aat.png')} nf={61} x={376} y={24} />
                            <ModelImageKnob name={'attackB'} imageUrl={require('./images/bat.png')} nf={61} x={376} y={155} />

                            <ModelImageKnob name={'releaseA'} imageUrl={require('./images/arl.png')} nf={61} x={491} y={24} />
                            <ModelImageKnob name={'releaseB'} imageUrl={require('./images/brl.png')} nf={61} x={491} y={155} />

                            <ModelImageKnob name={'feedA'} imageUrl={require('./images/afd.png')} nf={61} x={592} y={17} />
                            <ModelImageKnob name={'feedB'} imageUrl={require('./images/bfd.png')} nf={61} x={592} y={147} />

                            <ModelImageKnob name={'ellipticA'} imageUrl={require('./images/ael.png')} nf={61} x={703} y={17} />
                            <ModelImageKnob name={'ellipticB'} imageUrl={require('./images/bel.png')} nf={61} x={703} y={147} />

                            <ModelImageKnob name={'control'} imageUrl={require('./images/ctl.png')} nf={61} x={791} y={90} />

                            <ModelImageKnob name={'emphasis'} imageUrl={require('./images/ael.png')} nf={61} x={867} y={17} />
                            <ModelImageKnob name={'output'} imageUrl={require('./images/bel.png')} nf={61} x={867} y={147} />

                            <ModelImageKnob name={'outputType'} imageUrl={require('./images/mode.png')} nf={3} x={1223} y={29} />

                            <Needle x={1099} y={178} length={90} lineStyle="rgba(255,60,60,0.8)" lineWidth={2} ref={(n: any) => (this.gr = n)} />
                            <Overlay imageUrl={require('./images/vu_over.png')} x={1071} y={172} style={{ zIndex: 1000 }} />
                            <Overlay imageUrl={require('./images/power.png')} x={1225} y={160} />
                        </div>
                    </div>
                </Context.Provider>
            )
        }
    },
) as any

/*
module.exports = {
  name: 'gyraf/g24',
  driver: 'hidusb',
  parameters: {
    thresholdA: {
      type: 'fn',
      index: 2,
      normalize: 0x7F,
    },
    ratioA: {
      type: 'fn',
      index: 3,
      normalize: 0x7F,
    },
    attackA: {
      type: 'fn',
      index: 4,
      normalize: 0x7F,
    },
    releaseA: {
      type: 'fn',
      index: 5,
      normalize: 0x7F,
    },
    feedA: {
      type: 'fn',
      index: 6,
      normalize: 0x7F,
    },
    ellipticA: {
      type: 'fn',
      index: 7,
      normalize: 0x7F,
    },
    thresholdB: {
      type: 'fn',
      index: 8,
      normalize: 0x7F,
    },
    ratioB: {
      type: 'fn',
      index: 9,
      normalize: 0x7F,
    },
    attackB: {
      type: 'fn',
      index: 10,
      normalize: 0x7F,
    },
    releaseB: {
      type: 'fn',
      index: 11,
      normalize: 0x7F,
    },
    feedB: {
      type: 'fn',
      index: 12,
      normalize: 0x7F,
    },
    ellipticB: {
      type: 'fn',
      index: 13,
      normalize: 0x7F,
    },
    control: {
      type: 'fn',
      index: 14,
      normalize: 0x7F,
    },
    emphasis: {
      type: 'fn',
      index: 15,
      normalize: 0x7F,
    },
    output: {
      type: 'fn',
      index: 16,
      normalize: 0x7F,
    },
    outputType: {
      type: 'i',
      max: 2,
      index: 17,
      default: 0,
    },
  },
  reports: {
    gainReduction: {
      index: 18,
      normalize: 0xFF,
      type: 'fn',
      channel: 'global',
      meteringId: 'gainReduction',
    },
  },
}

 */
