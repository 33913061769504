import * as React from 'react'

import { amber, blueGrey } from '@material-ui/core/colors'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { observer } from 'mobx-react'

import Store from '../store'
import Header from './header'

const themes = {
    normal: createMuiTheme({
        palette: {
            primary: amber,
            secondary: blueGrey,
        },
        typography: {
            useNextVariants: true,
            fontFamily: '"IBM Plex Sans", "Helvetica", "Arial", sans-serif',
            fontWeightLight: 300,
            fontWeightRegular: 400,
            fontWeightMedium: 500,
        },
    }),
    dark: createMuiTheme({
        palette: {
            type: 'dark',
            primary: {
                main: '#263238',
                light: '#4f5b62',
                dark: '#000a12',
                contrastText: '#fff',
            },
            secondary: {
                main: '#7c4dff',
                light: '#b47cff',
                dark: '#3f1dcb',
                contrastText: '#fff',
            },
            background: {
                default: '#000a12',
                paper: '#263238',
            },
            text: {
                primary: 'rgba(255, 255, 255, 0.87)',
                secondary: 'rgba(255, 255, 255, 0.87)',
                disabled: 'rgba(255, 255, 255, 0.38)',
                hint: 'rgba(255, 255, 255, 0.38)',
            },
            divider: 'rgba(50, 125, 75, 1)',
        },
        typography: {
            useNextVariants: true,
            fontFamily: '"IBM Plex Sans", "Helvetica", "Arial", sans-serif',
            fontWeightLight: 300,
            fontWeightRegular: 400,
            fontWeightMedium: 500,
        },
    }),
}

export default observer((props: { children: React.ReactNode; store: Store; sessionId: string }) => (
    <MuiThemeProvider theme={themes[props.store.themeId]}>
        {props.store.warning && (
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={!!props.store.warning}
                autoHideDuration={6000}
                onClose={() => props.store.clearWarning()}
                onExited={() => {
                }}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{props.store.warning}</span>}
                action={[
                    <IconButton key="close" aria-label="Close" color="inherit" onClick={() => props.store.clearWarning()}>
                        <CloseIcon/>
                    </IconButton>,
                ]}
            />
        )}
        <Header>{props.children}</Header>
    </MuiThemeProvider>
))
