import React from 'react'
import { observable, action } from 'mobx'
import HHMMSS from 'hh-mm-ss'

import { ModelToggleKnob, Context, ModelImageKnob } from '../ImageKnob'
import { px, mobx, dbToFactor } from '../../utils'
import Store from '../../store'
import flatten from 'lodash/flatten'
import styled from 'styled-components'

import bgImg from './img/telefunken_bg.png'
import bypassROnOffImg from './img/bypass_r_on_off.png'
import bypassLOnOffImg from './img/bypass_l_on_off.png'
import vuGlassImg from './img/telefunken_vu_glass.png'
import leftInputGainKnobImg from './img/knob1.png'
import rightInputGainKnobImg from './img/knob2.png'
import leftOutputGainKnobImg from './img/knob3.png'
import rightOutputGainKnobImg from './img/knob4.png'
import reelsImg from './img/reels.png'
import NoninteractiveAnimation from '../NonInteractiveAnimation'
import Needle from '../Needle'
import Overlay from '../Overlay'

const startAngle = -(Math.PI / 180) * 52
const endAngle = (Math.PI / 180) * 50

const AbsoluteSpan = styled.span<{ x: number; y: number }>`
    font-family: 'Digitopik2';
    font-size: 26px;
    color: rgba(255, 0, 0, 0.75);
    position: absolute;
    left: ${props => px(props.x)};
    top: ${props => px(props.y)};
`

function loadImage(src: string): HTMLImageElement {
    const rv = new Image()
    rv.src = src
    return rv
}

interface Props {
    id: string
    store: Store
    uiScale: number
}

class TelefunkenM15 extends React.Component<Props> {
    @observable
    flags = 0

    @observable
    private position: number = 0

    lastVal = 0

    timer?: number

    leftInput: Needle | null = null
    rightInput: Needle | null = null
    leftOutput: Needle | null = null
    rightOutput: Needle | null = null

    volumeControllerId?: string

    static renderTitle() {
        return 'Telefunken M15'
    }

    constructor(props: Props) {
        super(props)
        this.volumeControllerId = this.getMyVolumeControlId()
    }

    componentDidMount() {
        this.timer = setInterval(this.updateLights, 200)
        this.props.store.on('playingAnimation', this.updateAnimation)

        if (this.props.store.currentSlotSettings && !this.props.store.currentSlotSettings["distopik/volume2_1"]) {
            console.log('Will setSlotSettings')
            this.props.store.setSlotSettings([
                {processor: 'distopik/volume2_1', parameter: 'leftInputGain', value: 0.705},
                {processor: 'distopik/volume2_1', parameter: 'rightInputGain', value: 0.705},
                {processor: 'distopik/volume2_1', parameter: 'leftOutputGain', value: 0.705},
                {processor: 'distopik/volume2_1', parameter: 'rightOutputGain', value: 0.705},
                {processor: 'distopik/volume2_1', parameter: 'linkPairs', value: 1}
            ], true, true)
        }
    }

    componentWillUnmount() {
        if (this.timer !== null) {
            clearInterval(this.timer)
            this.timer = undefined
        }
        this.props.store.off('playingAnimation', this.updateAnimation)
    }

    updateNeedle(id: string, needle: Needle | null, offset: number, factor: number) {
        if (!needle) {
            return
        }

        const { measurements, position: pos } = this.props.store.player
        const m = measurements[`insert_1.${id}`]

        if (m) {
            // add 18dB, scale to 68%
            needle.position = Math.min(1, m.getValueWithSmoothing(pos + offset, 12) * 0.68181818181 * 7.943282347242816 * factor)
        }
    }

    updateAnimation = () => {
        const { session } = this.props.store.player
        let leftFactor = 1,
            rightFactor = 1
        if (session && this.volumeControllerId) {
            const dev = session.devices[this.volumeControllerId]
            leftFactor = dbToFactor(dev.model.getValue('leftInputGain') * 30 - 21)
            rightFactor = dbToFactor(dev.model.getValue('rightInputGain') * 30 - 21)
        }

        this.updateNeedle('input_rms_0', this.leftInput, 0.125, leftFactor)
        this.updateNeedle('input_rms_1', this.rightInput, 0.125, rightFactor)
        this.updateNeedle('output_rms_0', this.leftOutput, 0.0, 1)
        this.updateNeedle('output_rms_1', this.rightOutput, 0.0, 1)
    }

    getMyVolumeControlId = () => {
        const { session } = this.props.store.player

        if (session) {
            const deviceIndex = session.deviceOrder.findIndex(d => {
                const name = flatten([d])[0]
                return name === this.props.id
            })
            if (deviceIndex > 0) {
                const previousIndex = deviceIndex - 1
                const previousId = session.deviceOrder[previousIndex]
                if (typeof previousId === 'string') {
                    const previousDevice = session.devices[previousId]
                    if (previousDevice.type === 'distopik/volume2') {
                        return previousId
                    }
                }
            }
        }
    }

    @action
    updateLights = () => {
        const { position } = this.props.store.player

        let m = this.props.store.player.measurements[`${this.props.id}.transportFlags`]
        if (m) {
            const value = m.getValue(position)
            if (value != null) {
                this.flags = parseInt(value.toString(), 2) | 0
            }
        }

        m = this.props.store.player.measurements[`${this.props.id}.position`]
        if (m) {
            const value = m.getValue(position)
            if (value != null) {
                this.position = value
            }
        }
    }

    render() {
        const { uiScale = 0.5, store, id } = this.props
        const { api, isPlaying } = store
        return (
            <Context.Provider value={{ api, id } as any}>
                <div
                    style={{
                        transform: `scale(${uiScale}, ${uiScale})`,
                        transformOrigin: 'left top',
                        margin: 0,
                        padding: 0,
                        width: px(1414 * uiScale),
                        height: px(862 * uiScale),
                    }}
                    onClick={e => e.preventDefault()}
                    onDoubleClick={e => e.preventDefault()}
                >
                    <div
                        style={{
                            width: px(1414),
                            height: px(862),
                            transformOrigin: 'left top',
                            margin: 0,
                            padding: 0,
                            background: `url(${bgImg})`,
                            backgroundAttachment: 'local',
                            backgroundSize: 'stretch',
                            boxShadow: 'black 0 4px 12px',
                            filter: store.globalBypass ? 'opacity(0.5)' : '',
                        }}
                        onClick={e => e.preventDefault()}
                        onDoubleClick={e => e.preventDefault()}
                    >
                        {/* volume controller context */}
                        {this.volumeControllerId !== undefined && (
                            <Context.Provider value={{ api, id: this.volumeControllerId } as any}>
                                <Needle startAngle={startAngle} endAngle={endAngle} ref={n => (this.leftInput = n)} x={195 + 106} y={241} lineWidth={2} length={110} height={78} />
                                <Needle startAngle={startAngle} endAngle={endAngle} ref={n => (this.rightInput = n)} x={445 + 106} y={241} lineWidth={2} length={106} height={74} />
                                <Needle startAngle={startAngle} endAngle={endAngle} ref={n => (this.leftOutput = n)} x={756 + 106} y={241} lineWidth={2} length={106} height={74} />
                                <Needle startAngle={startAngle} endAngle={endAngle} ref={n => (this.rightOutput = n)} x={1006 + 106} y={241} lineWidth={2} length={106} height={74} />

                                <ModelImageKnob style={{ zIndex: 10 }} name={'leftInputGain'} imageUrl={leftInputGainKnobImg} nf={61} x={253} y={261} initial={0.705}/>
                                <ModelImageKnob style={{ zIndex: 10 }} name={'rightInputGain'} imageUrl={rightInputGainKnobImg} nf={61} x={517} y={260} initial={0.705}/>
                                <ModelImageKnob style={{ zIndex: 10 }} name={'leftOutputGain'} imageUrl={leftOutputGainKnobImg} nf={61} x={820} y={260} initial={0.705}/>
                                <ModelImageKnob style={{ zIndex: 10 }} name={'rightOutputGain'} imageUrl={rightOutputGainKnobImg} nf={61} x={1082} y={260} initial={0.705}/>
                                <ModelToggleKnob name={'linkPairs'} imageUrl={bypassLOnOffImg} x={385} y={259} />
                                <ModelToggleKnob name={'linkPairs'} imageUrl={bypassROnOffImg} x={951} y={259} />
                            </Context.Provider>
                        )}
                        <Overlay imageUrl={vuGlassImg} x={180} y={81} />

                        <NoninteractiveAnimation animate={isPlaying} x={154} y={432} nf={48} imageUrl={reelsImg} rewinding={!!(this.flags & (1 << 3))} />

                        <AbsoluteSpan x={656} y={326}>
                            {HHMMSS.fromS(Math.round(45 * 60 - this.position), 'hh:mm:ss').replace(/[:]/g, '.')}
                        </AbsoluteSpan>
                    </div>
                </div>
            </Context.Provider>
        )
    }
}

export default mobx(TelefunkenM15)
