import CircualBuffer from 'circular-buffer'
import MixanalogAudio from '../MixanalogAudio'

type Mode = 'stopped' | 'buffering' | 'playing'

export default class MixanalogProcessor {
    mxaAudio: MixanalogAudio
    readIndex: number
    readLength: number
    channels: Float32Array[]
    bitDepth = 0
    bytes = 0
    sampleBuffer = 0
    position = 0
    sampleRate = 0
    is_safari = false
    rms = []
    peaks = []
    mode: Mode = 'stopped'
    callbackIndex = 0
    cBuf = new CircualBuffer(400)

    constructor(mxaAudio: MixanalogAudio) {
        this.mxaAudio = mxaAudio
        this.mxaAudio.workletOnMessage({ type: 'init' })
        this.readIndex = 0
        this.readLength = 0
        this.channels = []
        this.channels[0] = new Float32Array(0)
        this.channels[1] = new Float32Array(0)
    }

    addAudio(audioData: any) {
        this.cBuf.enq(audioData)
    }

    prepare(data: any) {
        this.mode = 'buffering'
        this.bitDepth = data.bitDepth
        this.sampleBuffer = data.sampleBuffer
        this.sampleRate = data.sampleRate
        this.position = data.position
        this.is_safari = data.is_safari

        switch (this.bitDepth) {
            case 16:
                this.bytes = 2
                break
            case 24:
                this.bytes = 3
                console.warn('This mode is experimental and not working yet!')
                break
            case 32:
                this.bytes = 4
                break
            default:
                console.error('Invalid sample size!')
                break
        }
        console.log('Using bytes: ', this.bytes)

        this.channels[0] = new Float32Array(0)
        this.channels[1] = new Float32Array(0)
        this.cBuf = new CircualBuffer(400)
        this.readIndex = 0
        this.readLength = 0
    }

    clearIt(outputs: Array<Float32Array>) {
        const numChannels = Math.min(outputs[0].length, this.channels.length)
        const numSamples = outputs[0].length

        for (let i = 0; i < numSamples; i++) {
            for (let c = 0; c < numChannels; c++) {
                outputs[c][i] = 0
            }
        }
    }

    process(inputs: Array<Float32Array>, outputs: Array<Float32Array>) {
        const wany = window as any

        try {
            this.nextBuffer()
            this.callbackIndex++

            if (this.mode !== 'playing') {
                this.clearIt(outputs)
                return true
            }

            const numInputChannels = this.channels.length;
            const numChannels = Math.min(outputs.length, numInputChannels)
            const numSamples = outputs[0].length
            this.mxaAudio.workletOnMessage({ type: 'position', payload: { position: this.position, numChannels, numSamples } })

            if (!(this.callbackIndex % 256) && !!wany.enableAudioQueueDebugging) {
                // enable this to get lots of nice debuggies
                console.log(this.position, this.mode, 'audio queue depth', this.remaining, 'smpls')
            }

            if (this.readLength != 0 && this.readIndex < this.readLength) {

                for (let i = 0; i < numSamples; i++) {
                    //check buffer empty
                    if (this.readIndex >= this.readLength) {
                        this.nextBuffer()
                    }

                    for (let c = 0; c < outputs.length; c++) {
                        outputs[c][i] = this.channels[c % numInputChannels][this.readIndex]
                    }

                    this.readIndex++
                }

                this.position += numSamples / this.sampleRate
            } else {
                console.log('empty')
                this.clearIt(outputs)
            }

            return true
        } catch (e) {
            console.warn('we caught in process', e)
            return true
        }
    }

    get remaining(): number {
        let rv = 0
        if (this.channels && this.channels.length && this.channels[0]) {
            rv += this.readLength - this.readIndex
        }

        for (let i = 0; i < this.cBuf.size(); i++) {
            rv += this.cBuf.get(i).buffer[0].length
        }

        return rv
    }

    nextBuffer() {
        if (this.cBuf.size() > 0) {
            if (this.mode != 'playing') {
                this.mxaAudio.workletOnMessage({ type: 'event', event: 'playing', msg: 'Buffer is empty' })
                this.mode = 'playing'
            }
        } else if (this.mode != 'buffering') {
            this.mxaAudio.workletOnMessage({ type: 'event', event: 'stopped', msg: 'Buffer is empty' })
            this.mode = 'buffering'
        }

        if (this.readLength === this.readIndex && this.mode === 'playing') {
            this.mode = 'playing'
            const data = this.cBuf.deq()

            this.channels = data.buffer
            this.position = data.position

            this.readLength = this.channels[0].length
            this.readIndex = 0

            return true
        }

        return false
    }
}
