import * as React from 'react'
import styled from 'styled-components'

import SettingsIcon from '@material-ui/icons/Settings'
import UploadIcon from '@material-ui/icons/CloudUpload'

import Store from '../store'
import { HSpace, mobx } from '../utils'
import { action } from 'mobx'
import Suggest, { Value } from '../settings/Suggest'
import { Media } from '../api/media'

const ActionBarInsertStyle = styled.div`
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    justify-content: space-between;

    margin: 'auto auto'; 
    flex: 1;
`

const ButtonsGroup = styled.div`
    font-family: 'IBM Plex Sans', 'Helvetica', 'Arial', sans-serif;
    display: flex;
    align-items: center;
    & > * {
        margin-right: 1px;
    }

    .slotIcon {
        font-size: 0.875rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid rgba(255, 255, 255, 0.23);
        border-radius: 3px;
        width: 27px;
        height: 27px;
        padding: 3px;
        transition: 0.25s;
        cursor: pointer;
        &:first-child {
            margin-left: 0;
        }
        &:hover {
            background: grey;
        }
        &.active {
            color: black;
            background: white;
        }
        &.text {
            width: auto;
            padding: 3px 8px;
        }
    }
`

const SuggestHolder = styled.div`
    font-family: 'IBM Plex Sans', 'Helvetica', 'Arial', sans-serif;
    flex-flow: row;
    align-items: center;
    display: flex;

    .suggest-holder-title {
        margin: 0 10px;
    }
`

function mediaFileTranslateOne(m: Media): Value {
    return m.artist
        ? {
            label: `${m.title} - ${m.artist}`,
            value: m.id,
        }
        : {
            label: m.title,
            value: m.id,
        }
}

function mediaFileTranslate(x: Array<Media>): Array<Value> {
    return x.map(mediaFileTranslateOne)
}


class ActionBarInsertClass extends React.Component<{ store: Store; toggleSaveDialog: any; toggleRecallDialog: any }> {
    handleSlotClick = (key: any) => {
        this.props.store.selectedSlotIndex = key
    }

    @action.bound
    changeMediaFile = (v: Value) => {
        this.props.store.slots[this.props.store.selectedSlotIndex].inputs['input'].mediaId = v.value
        this.props.store.saveSlot(this.props.store.selectedSlotIndex)
    }

    render() {
        const { store } = this.props as any
        const setttingsButtonStyle = { margin: '0 20px', color: 'rgba(255, 255, 255, 0.87)' }

        return (
            <ActionBarInsertStyle>
                <ButtonsGroup>
                    {store.slots &&
                    store.slots.map((slot: any, key: any) => (
                        <div key={key} className={`slotIcon${key === store.selectedSlotIndex ? ' active' : ''}`}
                             onClick={() => this.handleSlotClick(key)}>
                            {(key + 1) % 10}
                        </div>
                    ))}
                    <HSpace/>
                    <div className={`slotIcon text`} onClick={() => this.props.toggleSaveDialog()}>
                        Save
                    </div>
                    <div className={`slotIcon text`} onClick={() => this.props.toggleRecallDialog()}>
                        Recall
                    </div>
                </ButtonsGroup>
                <SuggestHolder>
                    <div className="suggest-holder-title" style={{ color: '#999' }}>Source:</div>
                    <Suggest options={mediaFileTranslate(store.media)} onChange={(v: any) => this.changeMediaFile(v)}
                             value={store.currentSlotMedia && mediaFileTranslateOne(store.currentSlotMedia)}/>
                    <HSpace/>
                    <ButtonsGroup>
                        <a href={'https://app.mixanalog.com/files'} className={`slotIcon text`} style={{color: 'white', textDecoration: 'none'}}>
                            <UploadIcon/>
                            <HSpace/>
                            Upload File(s)
                        </a>
                    </ButtonsGroup>
                </SuggestHolder>
                <ButtonsGroup>
                    <a onClick={() => store.settingsVisible = !store.settingsVisible} style={setttingsButtonStyle} className={'slotIcon'}>
                        <SettingsIcon/>
                    </a>
                </ButtonsGroup>
            </ActionBarInsertStyle>
        )
    }
}

export default mobx(ActionBarInsertClass)
