import * as React from 'react'
import { mobx, nvl } from '../utils'
import flatten from 'lodash/flatten'
import { toJS } from 'mobx'
import Store from '../store'
import { Property } from '../Model'
import ToggleKnob, { HitBox } from './ToggleKnob'

function px(x: any) {
    return `${x}px`
}

export default class ImageKnob extends React.Component<
    {
        initial?: number
        style?: any
        min?: number
        max?: number
        imageUrl: string
        horizontal?: boolean
        inverted?: boolean
        nf: number
        x: number
        y: number
        hitBox?: HitBox
        disabled?: boolean
        scaling?: number
        onChange?: (value: any, fromGui: any) => any
    },
    { loaded: boolean }
> {
    image: any
    state = {
        loaded: false,
    }
    paintedAtLeastOnce = false
    _position = this.default
    scheduled = false
    canvas?: HTMLCanvasElement
    lastPageX?: number
    lastPageY?: number
    offset = 0

    get default() {
        return nvl(this.props.initial, this.props.min) || 0
    }

    init() {
        this.image = new Image()
        this.image.src = this.props.imageUrl
        this.image.onload = () => this.setState({ loaded: true }, () => this.update())
    }

    componentDidMount() {
        this.init()
    }

    setPositionNoNotify(value: number) {
        const didChange = this._position != value
        this._position = value
        this.update()
        return didChange
    }

    set position(value: number) {
        try {
            if (this.setPositionNoNotify(value)) {
                this.postChange(value)
            }
        } catch (e) {}
    }

    get position(): number {
        return this._position
    }

    update = () => {
        if (!this.scheduled) {
            this.scheduled = true
            requestAnimationFrame(this.innerUpdate)
        }
    }

    innerUpdate = () => {
        this.scheduled = false
        if (this.state.loaded && this.canvas) {
            const { width, height } = this.image
            const { horizontal = false, nf: numFrames } = this.props
            let { _position: position } = this

            if (this.props.inverted) {
                position = 1 - position
            }

            const offset = ((horizontal ? width : height) * Math.floor(position * (numFrames - 1))) / numFrames
            if (this.offset === offset && this.paintedAtLeastOnce) {
                return
            }

            const offx = horizontal ? offset : 0
            const offy = horizontal ? 0 : offset
            const w = horizontal ? width / numFrames : width
            const h = horizontal ? height : height / numFrames

            if (this.canvas) {
                this.paintedAtLeastOnce = true
                const ctx = this.canvas.getContext('2d')
                if (ctx === null) {
                    return
                }

                ctx.clearRect(0, 0, w, h)
                ctx.drawImage(this.image, offx, offy, w, h, 0, 0, w, h)
                this.offset = offset
            }
        }
    }

    render() {
        const { loaded } = this.state
        const { x, y, horizontal = false, nf: numFrames } = this.props

        let w = 0
        let h = 0

        if (loaded) {
            w = this.image.width
            h = this.image.height

            if (horizontal) {
                w /= numFrames
            } else {
                h /= numFrames
            }
        }

        const { disabled = false } = this.props
        const enabled = !disabled

        return !loaded ? null : (
            <canvas
                ref={(c: any) => {
                    this.canvas = c
                    this.update()
                }}
                width={Math.max(w, 1)}
                height={Math.max(h, 1)}
                style={{
                    position: 'absolute',
                    top: px(y),
                    left: px(x),
                    touchAction: 'none',
                    ...(this.props.style || {}),
                }}
                onMouseDown={enabled ? this.onMouseDown : undefined}
                onTouchStart={enabled ? this.onTouchStart : undefined}
                onTouchMove={enabled ? this.onTouchMove : undefined}
                onTouchEnd={enabled ? this.onTouchEnd : undefined}
                onDoubleClick={enabled ? this.onDoubleClick : undefined}
            />
        )
    }

    onMouseDown = (e: any) => {
        if (!this.inHitBox(e)) {
            return
        }

        e.preventDefault()
        e.stopPropagation()

        window.onmousemove = (e: MouseEvent) => {
            const { movementX, movementY } = e

            if (movementX !== 0 || movementY !== 0) {
                this.updatePosition(movementX, movementY)
            }
        }

        window.onmouseup = (e: any) => {
            e.preventDefault()
            e.stopPropagation()

            window.onmousemove = () => {}
        }
    }

    inHitBox(e: MouseEvent | TouchEvent): boolean {
        if (this.props.hitBox && this.canvas) {
            let { clientX, clientY } = e as any
            let { x, y, w, h } = this.props.hitBox
            const { left, top } = this.canvas.getBoundingClientRect()
            clientX -= left
            clientY -= top

            if (clientX >= x && clientX <= x + w && clientY > y && clientY < y + h) {
                // console.log('accept hit box')
                return true
            } else {
                // console.log('rejecting hit box')
                return false
            }
        }

        return true
    }

    onDoubleClick = (e: any) => {
        if (!this.inHitBox(e)) {
            return
        }

        e.preventDefault()
        e.stopPropagation()

        this.position = this.default
    }

    updatePosition(dx: number, dy: number): void {
        const { innerWidth, innerHeight } = window
        if (this.props.inverted) {
            dx *= -1
            dy *= -1
        }

        dx *= nvl(this.props.scaling, 1) || 1
        dy *= nvl(this.props.scaling, 1) || 1

        const newPos = Math.min(Math.max(this.position - (dx / innerWidth + dy / innerHeight) * Math.PI, 0), 1)
        this.postChange(newPos)
    }

    postChange = (val: number) => {
        if (this.props.onChange) {
            this.props.onChange(val, true) /* from gui */
        } else {
            this.position = val
        }
    }

    onTouchStart = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
    }

    onTouchMove = (e: any) => {
        if (!this.inHitBox(e)) {
            return
        }

        e.preventDefault()
        e.stopPropagation()

        let dx = 0
        let dy = 0

        for (const t of e.changedTouches) {
            const { lastPageX, lastPageY } = this

            if (lastPageX == null) {
                this.lastPageX = t.pageX
            } else {
                dx = lastPageX - t.pageX
                this.lastPageX = t.pageX
            }

            if (lastPageY == null) {
                this.lastPageY = t.pageY
            } else {
                dy = t.pageY - lastPageY
                this.lastPageY = t.pageY
            }

            this.updatePosition(dx, dy)
            break
        }
    }

    onTouchEnd = (e: any) => {
        e.preventDefault()
        e.stopPropagation()

        this.lastPageX = this.lastPageY = undefined
    }
}

export type ContextParams = { id: string | Array<string> }
export const Context = React.createContext({ id: [] })

const KModelImageKnob = mobx(
    class KModelImageKnob extends React.Component<{
        store: Store
        instance: any
        name: string | Array<string>
        imageUrl: any
        nf: any
        x: any
        y: any
        style?: any
    }> {
        targets: Array<Property> = []
        knob?: ImageKnob

        componentDidMount() {
            const { session } = this.props.store.player
            this.targets = []

            if (session) {
                for (const id of flatten([toJS(this.props.instance)])) {
                    for (const name of flatten([toJS(this.props.name)])) {
                        this.targets.push(session.devices[id].model.parameters[name])
                        this.props.store.on(`change.${id}.${name}`, this.updateFromSettings)
                    }
                }
            }

            if (this.props.store.isSlotIndexPopulated(this.props.store.selectedSlotIndex)) {
                const slot = this.props.store.slots[this.props.store.selectedSlotIndex]

                for (const id of flatten([toJS(this.props.instance)])) {
                    for (const name of flatten([toJS(this.props.name)])) {
                        // console.log(name, id);
                        if (slot.settings[id] != null && slot.settings[id][name] != null) {
                            this.updateFromSettings(slot.settings[id][name], false)
                        }
                    }
                }
            }
        }

        componentWillUnmount() {
            for (const id of flatten([toJS(this.props.instance)])) {
                for (const name of flatten([toJS(this.props.name)])) {
                    this.props.store.off(`change.${id}.${name}`, this.updateFromSettings)
                }
            }
        }

        updateFromSettings = (val: number, fromGui: boolean) => {
            const { knob } = this
            if (knob) {
                /* get max from the knobzorz so that we can relativize it */
                for (const t of this.targets) {
                    if (!t || !t.spec) {
                        continue
                    }

                    const { min = 0, max = 1, type } = t.spec
                    const range = Math.abs(min - max)
                    const delta = Math.abs(min - val)

                    const newPos = delta / range
                    if (Number.isFinite(newPos) && !Number.isNaN(newPos)) {
                        knob.setPositionNoNotify(newPos)
                        break
                    }
                }
            }
        }

        setKnob(e: any) {
            this.knob = e
        }

        render() {
            return <ImageKnob initial={this.targets[0] ? this.targets[0].spec.default : 0} {...this.props} onChange={this.onChange} ref={e => this.setKnob(e)} />
        }

        onChange = (val: any, fromGui: any) => {
            const { min = 0, max = 1 } = this.targets[0].spec
            const range = Math.abs(min - max)
            const value = min + val * range
            for (const name of flatten([toJS(this.props.name)])) {
                this.props.store.setSlotSettings(
                    flatten([toJS(this.props.instance)]).map(id => ({
                        processor: id,
                        parameter: name,
                        value,
                    })),
                    true,
                )
            }

            this.knob && this.knob.setPositionNoNotify(val)
        }
    },
) as any

const KModelToggleKnob = mobx(
    class KModelToggleKnob extends React.Component<{
        store: Store
        instance: any
        name: string
        onToggle?: (v: boolean) => void
        values?: any[]
        imageUrl: any
        disabled?: boolean
        nf: any
        x: any
        y: any
    }> {
        targets: Array<Property> = []
        knob?: ToggleKnob
        mounted: boolean = false

        componentDidMount() {
            this.mounted = true
            const { session } = this.props.store.player
            this.targets = []

            if (session) {
                for (const id of flatten([toJS(this.props.instance)])) {
                    this.targets.push(session.devices[id].model.parameters[this.props.name])
                    this.props.store.on(`change.${id}.${this.props.name}`, this.updateFromSettings)
                }
            }

            if (this.props.store.isSlotIndexPopulated(this.props.store.selectedSlotIndex)) {
                const slot = this.props.store.slots[this.props.store.selectedSlotIndex]

                for (const id of flatten([toJS(this.props.instance)])) {
                    if (slot.settings[id] != null && slot.settings[id][this.props.name] != null) {
                        this.updateFromSettings(slot.settings[id][this.props.name], false)
                    }
                }
            }
        }

        componentWillUnmount() {
            this.mounted = false
            for (const id of flatten([toJS(this.props.instance)])) {
                this.props.store.off(`change.${id}.${this.props.name}`, this.updateFromSettings)
            }
        }

        updateFromSettings = (val: any, fromGui: any) => {
            const { knob } = this
            if (knob && this.mounted) {
                knob.setPositionNoNotify(val)
            }
        }

        render() {
            return <ToggleKnob {...this.props} onChange={this.onChange} ref={(e: any) => (this.knob = e)} />
        }

        onChange = (value: any, fromGui: any) => {
            if (!this.mounted || !!this.props.disabled) {
                return
            }

            this.props.store.setSlotSettings(
                flatten([toJS(this.props.instance)]).map(id => ({
                    processor: id,
                    parameter: this.props.name,
                    value,
                })),
                fromGui,
            )
            if (this.props.onToggle) {
                this.props.onToggle(value)
            }
        }
    },
) as any

export const ModelToggleKnob = (props: { name: string | Array<string>; imageUrl: any; x: any; y: any; inverted?: boolean; onToggle?: any; hitBox?: any; default?: any; initial?: any; values?: any[] }) => <Context.Consumer>{({ id }) => <KModelToggleKnob {...props} instance={id} name={props.name} />}</Context.Consumer>

export const ModelImageKnob = (props: { name: string | Array<string>; imageUrl: any; nf: any; x: any; y: any; max?: any; min?: any; inverted?: boolean; hitBox?: any; initial?: any; scaling?: any; style?: any }) => <Context.Consumer>{({ id }) => <KModelImageKnob {...props} instance={id} name={props.name} />}</Context.Consumer>
