import React from 'react'

import ImageKnob, { ModelImageKnob, ModelToggleKnob, Context } from '../ImageKnob'
import ToggleKnob from '../ToggleKnob'
import Needle from '../Needle'
import Overlay from '../Overlay'
import { px, mobx, clamp } from '../../utils'
import Store from '../../store'

// window.fcLeft = 1 / 0.347
// window.fcRight = 1 / 0.349

const RatioSwitch = mobx(
    class KRatioSwitch extends React.Component<{ store: Store; id: string }, { ratio: number }> {
        state = { ratio: 0 }

        componentDidMount() {
            const { store } = this.props
            const { session } = store.player
            if (session && store.isSlotIndexPopulated(store.selectedSlotIndex)) {
                const slot = store.slots[store.selectedSlotIndex]
                this.setState({ ratio: slot.settings[this.props.id].ratio })
            }

            store.on(`change.${this.props.id}.ratio`, this.setRatio)
        }

        setRatio = (ratio: any) => {
            this.setState({ ratio })
        }

        render() {
            return (
                <div
                    onClick={this.onClick}
                    style={{
                        backgroundImage: `url(${require('./images/ratios.png')})`,
                        backgroundPositionY: px(-(this.state.ratio - 1) * 197),
                        position: 'absolute',
                        left: px(862),
                        top: px(29),
                        height: px(197),
                        width: px(58),
                    }}
                />
            )
        }

        onClick = (e: any) => {
            const offY = Math.max(0, e.nativeEvent.offsetY - 5)
            const newRatio = Math.min(Math.floor(offY / 47), 3)

            const setRatio = (ratio: any) => {
                ;(this.props as any).store.player.session.devices[this.props.id].model.setValue('ratio', ratio)
            }

            if (e.shiftKey) {
                setRatio(5)
            } else {
                setRatio(4 - newRatio)
            }
        }
    },
) as any

export default mobx(
    class Eleven76 extends React.Component<{ id: string; uiScale: number; store: Store }> {
        gr: Needle | null = null

        static renderTitle() {
            return '1176 Rev. A'
        }

        componentDidMount() {
            this.props.store.on('playingAnimation', this.updateAnimation)
        }

        componentWillUnmount() {
            this.props.store.off('playingAnimation', this.updateAnimation)
        }

        updateAnimation = () => {
            const { measurements: m, position: pos } = this.props.store.player

            try {
                const grPos = m[`${this.props.id}.gainReduction`].getValue(pos, false)
                if (this.gr) {
                    this.gr.position = clamp(grPos * 2.9)
                }
            } catch (e) {
            }
        }

        render() {
            const { uiScale = 0.5, store, id } = this.props
            const { api } = store

            return (
                <Context.Provider value={{ api, id } as any}>
                    <div
                        style={{
                            transform: `scale(${uiScale}, ${uiScale})`,
                            transformOrigin: 'left top',
                            margin: 0,
                            padding: 0,
                            width: px(1414 * uiScale),
                            height: px(660 * uiScale),
                        }}
                        onClick={e => e.preventDefault()}
                        onDoubleClick={e => e.preventDefault()}
                    >
                        <div
                            style={{
                                width: px(1414),
                                height: px(260),
                                margin: 0,
                                padding: 0,
                                background: `url(${require('./images/bg.jpg')})`,
                                backgroundAttachment: 'local',
                                backgroundSize: 'stretch',
                                boxShadow: 'black 0 4px 12px',
                                filter: store.globalBypass ? 'opacity(0.5)' : '',
                            }}
                            onClick={e => e.preventDefault()}
                            onDoubleClick={e => e.preventDefault()}
                        >
                            <ModelImageKnob imageUrl={require('./images/input.png')} x={136} y={66} nf={61} name={'input'}/>
                            <ModelImageKnob imageUrl={require('./images/output.png')} x={418} y={66} nf={61} name={'output'}/>
                            <ModelImageKnob inverted imageUrl={require('./images/attack.png')} x={676} y={46} nf={61} name={'attack'}/>
                            <ModelImageKnob inverted imageUrl={require('./images/release.png')} x={676} y={162} nf={61} name={'release'}/>
                            {/* <VUMeter id={this.props.id} /> */}
                            <Needle
                                x={1065}
                                y={206}
                                length={125}
                                startAngle={-0.798131701}
                                endAngle={0.798131701}
                                ref={e => {
                                    this.gr = e
                                }}
                            />

                            <img src={require('./images/vumask.png')} style={{ zIndex: 4, position: 'absolute', left: px(928) }}/>

                            <RatioSwitch id={this.props.id}/>
                            {/* <ModelImageKnob
                imageUrl={require('./images/Knobs_Time_Right_Down.png')}
                x={1118}
                y={466}
                nf={6}
                name={'rightTimeConstant'}
              />
              <ModelImageKnob
                imageUrl={require('./images/AGC.png')}
                x={574 * 2}
                y={154 * 2}
                nf={2}
                name={'midSide'}
                scaling={4}
              />
              <ImageKnob
                imageUrl={require('./images/Knobs_Metering.png')}
                x={262 * 2}
                y={149 * 2}
                nf={3}
                initial={0.5}
                disabled
              />

              <Overlay imageUrl={require('./images/Power_Led_2.png')} x={58 * 2} y={145 * 2}/>

              <ModelToggleKnob
                x={74 * 2}
                y={194 * 2}
                name={'link'}
                inverted
                initial={true}
                imageUrl={require('./images/Link_Switch.png')}
              />
              <ModelToggleKnob
                x={74 * 2}
                y={245 * 2}
                inverted
                name={'linkPairs'}
                imageUrl={require('./images/Link_Switch.png')}
              />
              <ModelImageKnob
                imageUrl={require('./images/Knob1.png')}
                x={490}
                y={88}
                nf={87}
                initial={0.75}
                name={'leftInput'}
              />
              <ModelImageKnob
                imageUrl={require('./images/Knob4.png')}
                x={246 * 2}
                y={226 * 2}
                nf={87}
                initial={0.75}
                name={'rightInput'}
              />
              <ModelImageKnob
                imageUrl={require('./images/Knob2.png')}
                x={351 * 2}
                y={44 * 2}
                nf={87}
                name={'leftThreshold'}
                initial={0.75}
              />
              <ModelImageKnob
                imageUrl={require('./images/Knob5.png')}
                x={352 * 2}
                y={225 * 2}
                nf={87}
                name={'rightThreshold'}
                initial={0.75}
              />
              <ModelImageKnob
                imageUrl={require('./images/Knob3.png')}
                x={456 * 2}
                y={43 * 2}
                nf={87}
                name={'leftRatio'}
                inverted
                initial={0}
              />
              <ModelImageKnob
                imageUrl={require('./images/Knob6.png')}
                x={457 * 2}
                y={226 * 2}
                nf={87}
                name={'rightRatio'}
                inverted
                initial={0}
              />
              <Needle
                x={350}
                y={250}
                length={140}
                startAngle={-0.698131701}
                endAngle={(0.698131701 * 2) / 3}
                ref={e => (this.leftGr = e)}
              />
              <Needle
                x={350}
                y={560}
                length={140}
                startAngle={-0.698131701}
                endAngle={(0.698131701 * 2) / 3}
                ref={e => (this.rightGr = e)}
              />
              <Overlay x={240} y={56} imageUrl={require('./images/VU_mask.png')} style={{ zIndex: 3 }}/> */}
                        </div>
                    </div>
                </Context.Provider>
            )
        }
    },
)
