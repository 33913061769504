import React from 'react'
import { Context, ModelImageKnob, ModelToggleKnob } from '../ImageKnob'
import ToggleKnob from '../ToggleKnob'
import Needle from '../Needle'
import Overlay from '../Overlay'
import { mobx, px } from '../../utils'
import Store from '../../store'
import Rect from '../rect'
import { observable } from 'mobx'
import { Device } from '../../api'

const deflect = 0.758131701

class DistopikVCAStereo extends React.Component<{ id: string; uiScale: number; store: Store; device: Device }> {
    grLeft?: Needle
    grRight?: Needle

    lastVal: any = null

    static renderTitle() {
        return 'Audio Destruction Unit'
    }

    componentDidMount() {
        this.props.store.on('playingAnimation', this.updateAnimation)
    }

    componentWillUnmount() {
        this.props.store.off('playingAnimation', this.updateAnimation)
    }

    updateAnimation = () => {
        const { measurements, position: pos } = this.props.store.player
        let m = measurements[`${this.props.id}.leftBiasMeter`]

        try {
            let val = m.getValueWithSmoothing(pos) / 1.35
            // val = Math.min(Math.max(0, 1320 / 145 - val) / (1 - 1320 / 145) * 2, 1)

            if (this.grLeft) {
                this.grLeft.position = val
            }
        } catch (e) {}

        m = measurements[`${this.props.id}.rightBiasMeter`]
        try {
            let val = m.getValueWithSmoothing(pos) / 1.35
            // val = Math.min(Math.max(0, 1320 / 145 - val) / (1 - 1320 / 145) * 2, 1)

            if (this.grRight) {
                this.grRight.position = val
            }
        } catch (e) {}
    }

    changeToDual = () => {
        this.props.store.setSlotSettings([{ parameter: 'linkPairs', processor: this.props.id, value: 0 }], true)
    }

    toggleDual = () => {
        const { session } = this.props.store.player
        if (session) {
            const { settings } = session.slots[this.props.store.selectedSlotIndex]

            this.props.store.setSlotSettings([{ parameter: 'linkPairs', processor: this.props.id, value: settings[`${this.props.id}.linkPairs`] ? 0 : 1 }], true)
        }
    }

    changeToSingle = () => {
        this.props.store.setSlotSettings([{ parameter: 'linkPairs', processor: this.props.id, value: 1 }], true)
    }

    render() {
        const { uiScale = 0.5, store, id } = this.props
        const { api } = store

        return (
            <Context.Provider value={{ api, id } as any}>
                <div
                    onClick={e => e.preventDefault()}
                    onDragStart={e => e.preventDefault()}
                    style={{
                        transform: `scale(${uiScale}, ${uiScale})`,
                        transformOrigin: 'left top',
                        margin: 0,
                        padding: 0,
                        width: px(1414 * uiScale),
                        height: px(912 * uiScale),
                    }}
                >
                    <div
                        onClick={e => e.preventDefault()}
                        onDoubleClick={e => e.preventDefault()}
                        onDragStart={e => e.preventDefault()}
                        style={{
                            width: px(1414),
                            height: px(912),
                            margin: 0,
                            padding: 0,
                            background: `url(${require('./images/BG2.jpg')})`,
                            backgroundAttachment: 'local',
                            backgroundSize: 'stretch',
                            boxShadow: 'black 0 4px 12px',
                            filter: store.globalBypass ? 'opacity(0.5)' : '',
                        }}
                    >
                        <ModelToggleKnob name={'leftBypass'} imageUrl={require('./images/bypass_left.png')} x={178} y={185} inverted />
                        <ModelToggleKnob name={'leftLowPassFilter'} imageUrl={require('./images/lpf_left.png')} x={767} y={329} />
                        <ModelToggleKnob name={'leftOverdrive'} imageUrl={require('./images/overdrive_left.png')} x={461} y={329} inverted values={[0, 1, 2]} />

                        <ModelToggleKnob name={'linkPairs'} imageUrl={require('./images/stereo.png')} x={178} y={329} />

                        <ModelToggleKnob name={'rightBypass'} imageUrl={require('./images/bypass_right.png')} x={178} y={648} inverted />
                        <ModelToggleKnob name={'rightLowPassFilter'} imageUrl={require('./images/lpf_right.png')} x={767} y={793} />
                        <ModelToggleKnob name={'rightOverdrive'} imageUrl={require('./images/overdrive_right.png')} x={461} y={793} inverted values={[0, 1, 2]} />

                        <Needle x={1144} y={226} length={150} startAngle={-deflect} endAngle={deflect} lineStyle="black" lineWidth={2} ref={(n: any) => (this.grLeft = n)} />

                        <Needle x={1144} y={682} length={150} startAngle={-deflect} endAngle={deflect} lineStyle="black" lineWidth={2} ref={(n: any) => (this.grRight = n)} />

                        <Overlay imageUrl={require('./images/VUMeterMask.png')} x={988} y={22} style={{ zIndex: 900 }} />
                        <Overlay imageUrl={require('./images/VUMeterMask.png')} x={988} y={478} style={{ zIndex: 900 }} />

                        <ModelImageKnob name={'leftDrive'} imageUrl={require('./images/left_drive.png')} nf={61} x={328} y={166} />
                        <ModelImageKnob name={'leftDistortionType'} imageUrl={require('./images/left_distortion.png')} nf={6} x={570} y={166} />
                        <ModelImageKnob name={'leftOutputGain'} imageUrl={require('./images/left_output.png')} nf={61} x={789} y={166} />
                        <ModelImageKnob name={'leftBias'} imageUrl={require('./images/left_bias.png')} nf={61} x={1089} y={324} style={{ zIndex: 10000 }} />

                        <ModelImageKnob name={'rightDrive'} imageUrl={require('./images/right_drive.png')} nf={61} x={328} y={621} />
                        <ModelImageKnob name={'rightDistortionType'} imageUrl={require('./images/right_distortion.png')} nf={6} x={570} y={636} />
                        <ModelImageKnob name={'rightOutputGain'} imageUrl={require('./images/right_output.png')} nf={61} x={789} y={621} />
                        <ModelImageKnob name={'rightBias'} imageUrl={require('./images/right_bias.png')} nf={61} x={1089} y={781} style={{ zIndex: 10000 }} />
                    </div>
                </div>
            </Context.Provider>
        )
    }
}

export default mobx(DistopikVCAStereo)
