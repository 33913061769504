import * as React from 'react'
import { TextField, Button, Dialog, DialogActions, DialogContent, MenuItem } from '@material-ui/core'
import Store from '../store'
import { Encodings } from '../api'
import { HSpace, humanizeBandwidth, mobx } from '../utils'
import map from 'lodash/map'
import range from 'lodash/range'
import Typography from '@material-ui/core/Typography'
import { Theme } from '@material-ui/core'

import HelpIcon from '@material-ui/icons/HelpOutlined'
import withStyles from '@material-ui/core/styles/withStyles'
//@ts-ignore
import classNames from 'classnames'
import MouseOverPopover from './MouseOverPopover'

export const Styles = (theme: Theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing.unit,
    },
    withoutLabel: {
        marginTop: theme.spacing.unit * 3,
    },
    textField: {
        flexBasis: 400,
    },
    button: {
        margin: theme.spacing.unit,
    },
    /* green & disabled need to be implemented in future, currently hover defeats this */
    buttonGreen: {
        backgroundColor: '#1faa00',
        color: 'white',
    },
    buttonDisabled: {
        backgroundColor: '#444',
        color: '#999',
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    menu: {
        width: 200,
    },
    formContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },

    container: {
        padding: '22px 0px',
    },
})

const DefaultEdit = withStyles(Styles as any)(
    mobx(
        class DefaultEditRaw extends React.Component<{ store: Store; classes: any; closeDialog: any; openDialog: any }> {
            render() {
                const { store, classes } = this.props

                //singal to play component
                const encoding = Encodings[store.player.encoding]
                this.props.openDialog && this.props.openDialog()

                return (
                    <Dialog open={true} onClose={this.props.closeDialog} aria-labelledby="save-dialog-title">
                        <DialogContent>
                            <Typography noWrap variant="h5" style={{ paddingTop: '34px' }}>
                                Streaming
                                <HSpace/>
                                <MouseOverPopover icon={HelpIcon}>
                                    <Typography variant="body1">These settings will change the way mix:analog transfers the processed audio
                                        to your computer in real-time.</Typography>
                                    <br/>
                                    <Typography variant="body1">For best experience, select the streaming quality with the required
                                        bandwidth estimate at around one half of your internet service provider declared
                                        bandwidth.</Typography>
                                    <br/>
                                    <Typography variant="body1">To cope with unpredictable internet network delays, a higher buffering
                                        setting will allow for an audio stream with less or no dropouts, at the cost of overall perceived
                                        latency when using the app.</Typography>
                                </MouseOverPopover>
                            </Typography>
                            <form className={classes.formContainer} noValidate autoComplete="off">
                                <TextField id="buffering" select className={classNames(classes.textField, classes.margin)}
                                           SelectProps={{ MenuProps: { className: classes.menu } }} helperText="Buffering" margin="normal"
                                           value={store.player.buffering}
                                           onChange={e => (store.player.buffering = parseInt(e.target.value, 10))}>
                                    {map(range(3, 36, 3), index => (
                                        <MenuItem key={index} value={index}>
                                            {Math.floor((index * encoding.bufferSize * 1000) / store.player.sampleRate)}
                                            ms
                                        </MenuItem>
                                    ))}
                                </TextField>

                                <TextField id="streaming-quality" select className={classNames(classes.textField, classes.margin)}
                                           SelectProps={{ MenuProps: { className: classes.menu } }} helperText="Streaming Quality"
                                           margin="normal" value={store.player.encoding}
                                           onChange={(e: any) => store.player.setEncoding(e.target.value)}>
                                    {map(Encodings, (l, index) => (
                                        <MenuItem key={index} value={index}
                                                  disabled={l.onlySampleRate ? l.onlySampleRate.indexOf(store.player.sampleRate) == -1 : false}>
                                            {l.long}
                                        </MenuItem>
                                    ))}
                                </TextField>

                                <TextField id="sampleRate" className={classNames(classes.textField, classes.margin)}
                                           helperText="Sample Rate" disabled value={store.player.sampleRate}/>

                                <TextField id="bandwidth" className={classNames(classes.textField, classes.margin)}
                                           helperText="Required Bandwidth (estimated)" disabled
                                           value={humanizeBandwidth(store.player.bandwidthRequired)}/>

                                <TextField id="uiScale" select className={classNames(classes.textField, classes.margin)}
                                           SelectProps={{ MenuProps: { className: classes.menu } }} helperText="UI Scale"
                                           margin="normal" value={store.maxUiScale}
                                           onChange={(e: any) => store.setMaxUiScale(parseFloat(e.target.value))}>
                                    {map(range(20, 110, 10), (l) => (
                                        <MenuItem key={l} value={l / 100}>
                                            {l * 2} %
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </form>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.props.closeDialog} variant="outlined">
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                )
            }
        },
    ),
)

export default DefaultEdit
