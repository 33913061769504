import * as React from 'react'
import { px } from '../utils'

export type RectProps = { x: number; y: number; w: number; h: number } & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export default function Rect(props: RectProps) {
    let { x, y, w, h, style, ...rest } = props
    if (!style) {
        style = {}
    }
    style = { zIndex: 1000, ...style, position: 'absolute', top: px(y), left: px(x), width: px(w), height: px(h) }

    return <div {...rest} style={style} />
}
