// import getInstance from 'mixlytics/auto-browser'
// import { Config } from 'mixlytics'
//
// const cfg: Config = {
//     appId: 'com.mixanalog.studio',
//     appVersion: process.env.REACT_APP_VERSION,
// }
//
// export function track(action: string, args?: any): void {
//     // getInstance(cfg).track(action, args)
// }
//
// export function identify(id: string, email: string, props: any): void {
//     // getInstance(cfg).identify(id, email, props)
// }
//
// window.onclick = function(evt: any) {
//     if (typeof window !== 'undefined') {
//         if (evt.target && evt.target.id) {
//             track(`${evt.target.id} Clicked`)
//         }
//     }
// }

export function track(action: string, args?: any): void {
    const wany = window as any
    if (wany.analytics) {
        try {
            wany.analytics.track(action, args)
        } catch (e) {
            console.warn('while analytics track', e)
        }
    }
}

export function identify(id: string, email: string, props: any): void {
    const wany = window as any
    if (wany.analytics) {
        try {
            wany.analytics.identify(id, { ...props, email })
        } catch (e) {
            console.warn('while analytics identify', e)
        }
    }
}
