import React from 'react'

import { Context } from '../../../play/ImageKnob'
import { mobx, nvl } from '../../../utils'
import Store from '../../../store'
import flatten from 'lodash/flatten'
import { toJS, observable, action } from 'mobx'
import { Property } from '../../../Model'
import { observer } from 'mobx-react'

interface ComponentProps extends ExportProps {
    instance: any
    inverted?: boolean
    scaling?: number
}

@observer
class DraggableButton extends React.Component<ComponentProps> {
    property?: Property

    @observable
    value: number = 0

    lastPageX?: number
    lastPageY?: number

    componentDidMount() {
        this.setProperty()
        this.updateFromSlot()
    }

    setProperty = () => {
        // from componentDidMount of KModelImageKnob
        const { session } = this.props.store.player

        if (session) {
            for (const id of flatten([toJS(this.props.instance)])) {
                // console.log(session.devices[id].model.parameters[this.props.name])
                this.property = session.devices[id].model.parameters[this.props.name]
                this.props.store.on(`change.${id}.${this.props.name}`, this.updateFromSettings)
            }
        }
    }

    updateFromSlot = () => {
        const { store, instance, name } = this.props
        if (store.isSlotIndexPopulated(store.selectedSlotIndex)) {
            const slot = store.slots[store.selectedSlotIndex]

            for (const id of flatten([toJS(this.props.instance)])) {
                for (const name of flatten([toJS(this.props.name)])) {
                    if (slot.settings[id] != null && slot.settings[id][name] != null) {
                        this.updateFromSettings(slot.settings[id][name])
                    }
                }
            }
        }
    }

    updateFromSettings = (val: number) => {
        this.setValue(val, false)
    }

    handleMouseDown = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        this.listenToMouse(true)
    }

    handleTouchStart = (e: React.TouchEvent<HTMLButtonElement>) => {
        e.preventDefault()
        e.stopPropagation()
    }

    handleTouchMove = (e: React.TouchEvent<HTMLButtonElement>) => {
        e.preventDefault()
        e.stopPropagation()

        let dx = 0
        let dy = 0

        for (let x = 0; x <= e.changedTouches.length; x++) {
            const { pageX, pageY } = e.changedTouches[x]

            if (this.lastPageX === undefined) {
                this.lastPageX = pageX
            } else {
                dx = this.lastPageX - pageX
                this.lastPageX = pageX
            }

            if (this.lastPageY === undefined) {
                this.lastPageY = pageY
            } else {
                dy = pageY - this.lastPageY
                this.lastPageY = pageY
            }

            this.updatePosition(dx, dy)
            break
        }
    }

    handleTouchEnd = (e: React.TouchEvent<HTMLButtonElement>) => {
        e.preventDefault()
        e.stopPropagation()

        this.lastPageX = this.lastPageY = undefined
    }

    listenToMouse = (start?: boolean) => {
        if (start) {
            window.addEventListener('mousemove', this.handleMouseMove)
            window.addEventListener('mouseup', this.handleMouseUp)
            window.addEventListener('blur', this.handleBlur)
        } else {
            window.removeEventListener('mousemove', this.handleMouseMove)
            window.removeEventListener('mouseup', this.handleMouseUp)
            window.removeEventListener('blur', this.handleBlur)
        }
    }

    handleMouseMove = (e: MouseEvent) => {
        const { movementX, movementY } = e
        if (movementX !== 0 || movementY !== 0) {
            this.updatePosition(movementX, movementY)
        }
    }

    handleMouseUp = (e: MouseEvent) => {
        this.listenToMouse()
    }

    handleBlur = (e: FocusEvent) => {
        this.listenToMouse()
    }

    updatePosition = (dx: number, dy: number) => {
        const { innerWidth, innerHeight } = window
        if (this.props.inverted) {
            dx *= -1
            dy *= -1
        }

        dx *= nvl(this.props.scaling, 1) || 1
        dy *= nvl(this.props.scaling, 1) || 1
        const newPos = Math.min(Math.max(this.value - (dx / innerWidth + dy / innerHeight) * Math.PI, 0), 1)

        this.setValue(newPos, true)
    }

    @action
    setValue = (v: number, updateStore: boolean) => {
        this.value = v
        if (updateStore) {
            this.updateStore(v)
        }
    }

    updateStore = (value: number) => {
        this.props.store.setSlotSettings(
            flatten([toJS(this.props.instance)]).map(id => ({
                processor: id,
                parameter: this.props.name,
                value,
            })),
            true,
        )
    }

    render() {
        const val = Math.round(this.value * 100)
        return (
            <div className="headerButtonWithLabel">
                <span className="headerButtonTitle">{this.props.label}</span>
                <button className={'headerButton minWidth'} onMouseDown={this.handleMouseDown} onTouchStart={this.handleTouchStart} onTouchMove={this.handleTouchMove} onTouchEnd={this.handleTouchEnd}>
                    {val}%
                </button>
            </div>
        )
    }
}

interface ExportProps {
    label: string
    name: string

    store: Store
}

export default mobx((props: ExportProps) => <Context.Consumer>{({ id }) => <DraggableButton {...props} instance={id} />}</Context.Consumer>)
