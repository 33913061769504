import React from 'react'
import { px, mobx } from '../../utils'
import { ModelImageKnob, ModelToggleKnob, Context } from '../ImageKnob'
import Store from '../../store'
import ToggleKnob from '../ToggleKnob'
import AnimatedCanvas from '../AnimatedCanvas'
import { toJS } from 'mobx'

export default mobx(
    class DistopikLimiter extends React.Component<{ id: string; uiScale: number; store: Store }> {
        static renderTitle() {
            return 'Precision Limiter'
        }

        renderMeter(name: string, fixOffset: boolean, ctx: CanvasRenderingContext2D) {
            const m = this.props.store.player.measurements[name]
            if (m) {
                const { width, height } = ctx.canvas
                let value = Math.min(m.getValue(this.props.store.player.position), 1)

                ctx.clearRect(0, 0, width, height)
                ctx.fillStyle = 'rgba(255, 0, 0, 0.45)'
                const x = width - value * width
                ctx.fillRect(x, 0, width - x, height)
            }
        }

        /*
    let liPos = Math.min((m['moslim.leftGainReduction'].getValue(pos) / 2048), 1)
    let riPos = Math.min((m['moslim.rightGainReduction'].getValue(pos) / 2048), 1)
    */

        render() {
            const { uiScale = 0.5, store, id } = this.props
            const { api, player } = store
            const { session } = player
            const { model } = session && (session.devices[id] as any)

            if (session) {
                const { model } = session.devices[this.props.id]
                model.setValue('linkPairs', toJS(store.slots[store.selectedSlotIndex].settings[id].linkPairs), true)
            }

            return (
                <Context.Provider value={{ api, id } as any}>
                    <div
                        style={{
                            transform: `scale(${uiScale}, ${uiScale})`,
                            transformOrigin: 'left top',
                            margin: 0,
                            padding: 0,
                            width: px(1414 * uiScale),
                            height: px(314 * uiScale),
                        }}
                    >
                        <div
                            style={{
                                width: px(1414),
                                height: px(314),
                                margin: 0,
                                padding: 0,
                                background: `url(${require('./images/BG.png')})`,
                                backgroundAttachment: 'local',
                                backgroundSize: 'stretch',
                                boxShadow: 'black 0 4px 12px',
                                filter: store.globalBypass ? 'opacity(0.5)' : '',
                            }}
                        >
                            <ModelImageKnob name={'leftInputGain'} x={172} y={10} nf={149} imageUrl={require('./images/leftInputGain.png')} />

                            <ModelImageKnob name={'rightInputGain'} x={172} y={156} nf={149} imageUrl={require('./images/rightInputGain.png')} />

                            <ModelImageKnob name={'leftCeiling'} imageUrl={require('./images/ceiling.png')} x={641} y={151} nf={149} inverted initial={0.45} />

                            <ModelImageKnob name={'leftOutputGain'} x={1112} y={10} nf={149} imageUrl={require('./images/leftOutputGain.png')} initial={0.5} />

                            <ModelImageKnob name={'rightOutputGain'} x={1111} y={151} nf={149} imageUrl={require('./images/rightOutputGain.png')} initial={0.5} />

                            <ModelToggleKnob name="mosfet" imageUrl={require('./images/mosfet.png')} x={362} y={175} inverted />
                            <ModelToggleKnob imageUrl={require('./images/link.png')} x={796} y={175} inverted name={'linkPairs'} />
                            <ModelToggleKnob name="bypass" imageUrl={require('./images/bypass.png')} inverted x={933} y={175} />

                            <AnimatedCanvas
                                src={this.props.store}
                                width={510}
                                height={15}
                                x={428}
                                y={51}
                                render={ctx => this.renderMeter(`${id}.leftGainReduction`, false, ctx)}
                                // onClick={() => this.onMeterClick('moslim.leftGainReduction')}
                            />

                            <AnimatedCanvas
                                src={this.props.store}
                                width={510}
                                height={15}
                                x={428}
                                y={122}
                                render={ctx => this.renderMeter(`${id}.rightGainReduction`, false, ctx)}
                                // onClick={() => this.onMeterClick('moslim.leftGainReduction')}
                            />

                            {/* 
              <AnimatedCanvas
                src={this.props.store}
                width={514}
                height={17}
                x={443}
                y={243}
                render={ctx => this.renderMeter('insert.output_peak_1', false, ctx)}
                onClick={() => this.onMeterClick('insert.output_peak_1')}
              />

              <div
                style={{
                  position: 'absolute',
                  left: px(422),
                  top: px(32+20),
                  width: px(520),
                  height: px(15),
                  backgroundColor: 'rgba(255,0,0,0.5)',
                }}
              />*/}
                        </div>
                    </div>
                </Context.Provider>
            )
        }
    },
)
