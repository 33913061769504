import EventEmitter from 'event-emitter-es6'
import IO, { Socket } from 'socket.io-client'
//@ts-ignore
import MsgPackParser from 'socket.io-msgpack-parser'
// import SimplePeer from 'simple-peer'
//@ts-ignore
import Codec from 'notepack.io'
//@ts-ignore
import MsgPack from 'msgpack5'

type TransportStatus = 'connected' | 'connecting' | 'disconnected'
    ;
(window as any).codec = Codec
;(window as any).codec2 = MsgPack()

const ALL_EVENTS = '*'

export interface TransportEventsListener {
    onConnected(t: Transport): any

    onDisconnected(t: Transport): any

    onData(t: Transport, data: any): any

    onLatency(t: Transport, ms: number): any
}

export type RequestOptions = {
    transport?: Transport
    timeout?: number
    noReturn?: boolean
}

export interface Transport {
    /**
     * relative score of this transport (how fast it is)
     */
    getScore(): number

    /**
     * status of the transport, especially if it is actually connected or not
     */
    getStatus(): TransportStatus

    /**
     * Set the listener that will listen for events on this transport
     * @param {TransportEventsListener} t listener
     */
    setListener(t: TransportEventsListener): void

    /**
     * Send some data over the wire to the other end
     * @param {*} data data to send over the wire
     */
    send(data: any): any
}

type RequestData<Res> = {
    resolve: Res
    reject: (err: any) => void
    timer: any
}

export class DisconnectedError extends Error {
    constructor(message: string) {
        super(message)
    }
}

export class RequestResponse implements TransportEventsListener {
    transports: Array<Transport> = []
    requests: Map<string, RequestData<any>> = new Map()
    target: EventEmitter
    listener: TransportEventsListener
    debug: boolean
    requestId: number

    constructor(target: EventEmitter, listener: TransportEventsListener) {
        this.listener = listener
        this.target = target
        this.debug = false
        this.requestId = 0
    }

    createNewRequestId() {
        this.requestId += 1
        return this.requestId.toString()
    }

    addTransport(t: Transport) {
        t.setListener(this)
        this.transports.push(t)
    }

    removeTransport(t: Transport) {
        this.transports = this.transports.filter(x => x != t)
    }

    call(name: string, args?: any, meta?: any, opts?: RequestOptions): Promise<any> {
        return this.request({ name, args, meta }, opts)
    }

    fire(name: string, args?: any, meta?: any, opts: RequestOptions = {}): void {
        this.request({ name, args, meta }, { ...opts, noReturn: true })
    }

    request(request: any, { transport, timeout = 5000, noReturn }: RequestOptions = {}): Promise<any> {
        if (this.status != 'connected') {
            return Promise.reject(new DisconnectedError('not connected yet'))
        }

        if (!transport) {
            transport = this.transports.filter(t => t.getStatus() === 'connected').sort((t1, t2) => t2.getScore() - t1.getScore())[0]
        }

        if (this.debug) {
            console.log(transport.constructor.name, '<<', request)
        }

        if (!noReturn) {
            return new Promise((resolve, reject) => {
                const requestId = this.createNewRequestId()
                if (transport) {
                    if (transport.getStatus() !== 'connected') {
                        return reject(new Error(`Transport found, but not connected for request ${requestId}`))
                    }
                    const timer = setTimeout(() => {
                        this.requests.delete(requestId)
                        reject(new Error(`Request ${requestId} timed out after ${timeout}ms`))
                    }, timeout)

                    this.requests.set(requestId, { resolve, reject, timer })
                    transport.send({ requestId, request })
                } else {
                    reject(new Error(`No transports configured for request ${requestId}`))
                }
            })
        } else {
            if (!request.meta) {
                request.meta = {}
            }

            request.meta.noReturn = true
            return Promise.resolve(transport.send({ requestId: null, request }))
        }
    }

    get status(): TransportStatus {
        let rv: TransportStatus = 'disconnected'
        for (const t of this.transports) {
            rv = t.getStatus()
            if (rv === 'connected') {
                break
            }
        }
        return rv
    }

    onConnected(t: Transport): any {
        this.listener.onConnected(t)
    }

    onDisconnected(t: Transport): any {
        try {
            this.listener.onDisconnected(t)
        } catch (_ignore) {
        }

        this.requests.forEach((req) => {
            clearTimeout(req.timer)
            try {
                req.reject(new DisconnectedError('disconnected'))
            } catch (_ignore) {
            }
        })
        this.requests.clear()
    }

    onLatency(t: Transport, ms: number): any {
        this.listener.onLatency(t, ms)
    }

    onData(t: Transport, data: any): any {
        if (this.debug) {
            console.log(t.constructor.name, '>>', data)
        }

        if (data.response) {
            const { requestId, error, response } = data.response
            const state: RequestData<any> | undefined = this.requests.get(requestId)
            if (state) {
                try {
                    clearTimeout(state.timer)
                    if (error) {
                        console.error('Transport: ', error)
                        state.reject(error)
                    } else {
                        state.resolve(response)
                    }
                } finally {
                    this.requests.delete(requestId)
                }
            }
        } else if (data.event) {
            for (const prop in data.event) {
                this.target.emit(prop, data.event[prop])
            }
        } else {
            console.error(`Unexpected data received from transport - it is neither an event nor a response`)
        }
    }
}

export interface SignallingEventsListener {
    onSignal(data: any): any
}

/*
export class WebRTCTransport implements Transport, SignallingEventsListener {
  listener: ?TransportEventsListener = null
  status: TransportStatus = 'disconnected'
  peer: SimplePeer.Instance
  signalling: RequestResponse

  constructor(nodeId: string, signalling: RequestResponse) {
    this.signalling = signalling
    this.status = 'connecting'

    this.peer = new SimplePeer({ initiator: true })

    this.peer.on('signal', data => {
      signalling.call('engine.signal', { data }, { nodeId })
    })
    this.peer.on('close', () => {
      this.status = 'disconnected'
      this.listener && this.listener.onDisconnected(this)
    })
    this.peer.on('error', () => {
      this.status = 'disconnected'
      this.listener && this.listener.onDisconnected(this)
    })
    this.peer.on('connect', () => {
      this.status = 'connected'
      this.listener && this.listener.onConnected(this)
      console.log('---- webrtc connected ----')
    })
    this.peer.on('data', data => {
      const msg = Codec.decode(data)
      this.listener && this.listener.onData(this, msg)
    })
  }

  getScore() {
    return 0
  }

  onSignal(data: any): any {
    this.peer.signal(data)
  }

  getStatus(): TransportStatus {
    return this.status
  }

  setListener(t: TransportEventsListener): void {
    this.listener = t
  }

  send(data: any): any {
    this.peer.send(Codec.encode(data))
  }
}
*/

export class SocketIOTransport implements Transport {
    listener?: TransportEventsListener
    status: TransportStatus = 'disconnected'
    socket: typeof Socket

    constructor(url: string, token: string) {
        this.socket = IO(url, {
            query: { token },
            parser: MsgPackParser,
            autoConnect: true,
            transports: ['websocket'],
            timestampRequests: true,
        } as any)

        this.status = 'connecting'
        this.socket.on('connect', () => {
            this.status = 'connected'
            this.listener && this.listener.onConnected(this)
        })

        this.socket.on('disconnect', () => {
            this.status = 'disconnected'
            this.listener && this.listener.onDisconnected(this)
        })

        this.socket.on('message', (data: any) => {
            this.listener && this.listener.onData(this, data)
        })

        this.socket.on('pong', (ms: number) => {
            this.listener && this.listener.onLatency(this, ms)
        })
    }

    getScore() {
        return 1
    }

    getStatus(): TransportStatus {
        return this.status
    }

    setListener(t: TransportEventsListener): void {
        this.listener = t
    }

    send(data: any): any {
        this.socket.send(data)
    }
}
