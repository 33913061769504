import React from 'react'
import DistopikADUMono from './mono'
import DistopikADUStereo from './stereo'
import { CnbFeatures } from '../features'
import { mobx } from '../../utils'
import Store from '../../store'
import { Device } from '../../api'

class DistopikADU extends React.Component<{ id: string; uiScale: number, store: Store, masonry: any, device: Device }, { dual: boolean }> {
    state = { dual: false }

    static renderTitle() {
        return "Audio Destruction Unit"
    }

    componentDidMount(): void {
        /* check it */
        const { session } = this.props.store.player
        if (session) {
            const { linkPairs } = session.slots[this.props.store.selectedSlotIndex].settings[this.props.id] as { linkPairs: any }
            this.updateDualMode(linkPairs)
        }

        this.props.store.on(`change.${this.props.id}.linkPairs`, this.updateDualMode)
    }

    componentWillUnmount(): void {
        this.props.store.off(`change.${this.props.id}.linkPairs`, this.updateDualMode)
    }

    private updateDualMode = (linked: any) => {
        this.setState({ dual: !linked })

        if (this.props.masonry && this.props.masonry.masonry) {
            setTimeout(() => this.props.masonry.masonry.layout(), 100)
        }
    }

    static enabledCnbFeatures: Array<CnbFeatures> = ['mid/side', 'dry/wet']

    render() {
        return this.state.dual ? <DistopikADUStereo {...this.props} /> : <DistopikADUMono {...this.props} />
    }
}

export default mobx(DistopikADU)
