import React from 'react'

import { ModelImageKnob, ModelToggleKnob, Context } from '../ImageKnob'
import { px, mobx } from '../../utils'
import ContinuousImage from './images/Lo_boost_strip.png'
import Store from '../../store'
import { CnbFeatures } from '../features'

export default mobx(
    class Pultec extends React.Component<{ id: string; store: Store; uiScale: number }> {
        static renderTitle() {
            return 'Pultec EQ'
        }

        static enabledCnbFeatures: Array<CnbFeatures> = ['mid/side']

        render() {
            const anyObj = {} as any
            const { uiScale = 0.5, store, id } = this.props
            const { api } = store
            const { session } = store.player
            const device = ((session || anyObj).devices || anyObj)[id] || {}
            const { sidecars } = device
            const [sidecar = null] = sidecars || []

            return (
                <Context.Provider value={{ api, id } as any}>
                    <div
                        style={{
                            transform: `scale(${uiScale}, ${uiScale})`,
                            transformOrigin: 'left top',
                            margin: 0,
                            padding: 0,
                            width: px(707 * uiScale * 2),
                            height: px(267 * uiScale * 2),
                        }}
                        onClick={e => e.preventDefault()}
                        onDoubleClick={e => e.preventDefault()}
                    >
                        <div
                            style={{
                                width: px(707),
                                height: px(267),
                                transformOrigin: 'left top',
                                transform: 'scale(2, 2)',
                                margin: 0,
                                padding: 0,
                                background: `url(${require('./images/pultec-bg-4.jpg')})`,
                                backgroundAttachment: 'local',
                                backgroundSize: 'stretch',
                                boxShadow: 'black 0 4px 12px',
                                filter: store.globalBypass ? 'opacity(0.5)' : '',
                            }}
                            onClick={e => e.preventDefault()}
                            onDoubleClick={e => e.preventDefault()}
                        >
                            <ModelImageKnob x={49} y={118} nf={4} imageUrl={require('./images/Lo_freq_strip.png')} name={'lowFrequency'}
                                            hitBox={{ x: 95, y: 31, w: 81, h: 101 }} scaling={2}/>

                            <ModelToggleKnob x={48} y={168} imageUrl={require('./images/toggle-bypass.png')} initial={false}
                                             name={'bypass'}/>

                            <ModelImageKnob x={323} y={128} nf={7} imageUrl={require('./images/Hi_freq_strip.png')}
                                            name={'hiBoostFrequency'} hitBox={{ x: 61, y: 31, w: 114, h: 101 }}/>
                            <ModelImageKnob x={487} y={0} nf={3} imageUrl={require('./images/Att_strip.png')} name={'hiAttenFrequency'}
                                            scaling={2}/>
                            {/* low boost */}
                            <ModelImageKnob x={90} y={46} nf={57} imageUrl={ContinuousImage} name={'lowBoost'}/>
                            {/* low atten */}
                            <ModelImageKnob x={204} y={46} nf={57} imageUrl={ContinuousImage} name={'lowAtten'}/>
                            {/* hi boost */}
                            <ModelImageKnob x={346} y={46} nf={57} imageUrl={ContinuousImage} name={'hiBoost'}/>
                            {/* hi atten */}
                            <ModelImageKnob x={460} y={46} nf={57} imageUrl={ContinuousImage} name={'hiAtten'}/>
                            {/* hi q */}
                            <ModelImageKnob x={279} y={154} nf={57} imageUrl={ContinuousImage} name={'hiBoostBandwidth'}/>
                        </div>
                    </div>
                </Context.Provider>
            )
        }
    },
)
