import Worker from 'worker-loader!./worker/audioio.worker'
import EventEmitter from 'event-emitter-es6'
import MixanalogProcessor from './worklet/audio'
import { LUFSAnalyser } from './lufs'

function channelToArray(ch: AudioBuffer) {
    const out: Array<Float32Array> = []
    for (let i = 0; i < ch.numberOfChannels; i++) {
        out[i] = ch.getChannelData(i)
    }
    return out
}

export interface AudioOptions {
    bufferSize: number
    outputChannels: number
    checkIfLufsEnabled(): boolean
}

export default class MixanalogAudio {
    private readonly scriptProcessor: ScriptProcessorNode
    private audioContext: AudioContext
    private _worker: Worker
    private events = new EventEmitter()
    private processor: MixanalogProcessor
    private lufs: LUFSAnalyser | null = null

    constructor(audioContext: AudioContext, options: AudioOptions) {
        this.audioContext = audioContext

        this.scriptProcessor = this.audioContext.createScriptProcessor(options.bufferSize, 2, options.outputChannels)

        this.scriptProcessor.onaudioprocess = this.onAudioProcess

        this._worker = new Worker()
        this._worker.onmessage = this.workerOnMessage

        this.processor = new MixanalogProcessor(this)

        this.scriptProcessor.connect(this.audioContext.destination)

        // this.lufs = new LUFSAnalyser({ nodeToAnalyse: this.scriptProcessor, checkIfLufsEnabled: options.checkIfLufsEnabled })
    }

    workerOnMessage = (event: any) => {
        switch (event.data.type) {
            case 'audio':
                this.processor.addAudio(event.data.payload)
                break
            case 'prepare':
                this.processor.prepare(event.data)
                break
            case 'init':
                console.log('[main_thread] Worker ready')
                break
            case 'meters':
                this.events.emit('meters', event.data.payload)
                break
            case 'error':
                console.error(event.data.errMsg)
                break
        }
    }

    workletOnMessage = (data: any) => {
        switch (data.type) {
            case 'init':
                console.log('[main_thread] Audio Ready')
                break
            case 'event':
                this.events.emit(data.event, data.msg)
                break
            case 'position':
                this.events.emit('position', data.payload)
                break
            case 'error':
                console.error(data.errMsg)
                break
        }
    }

    onAudioProcess = (e: AudioProcessingEvent) => {
        const inputs = channelToArray(e.inputBuffer)
        const outputs = channelToArray(e.outputBuffer)

        this.processor.process(inputs, outputs)
        // console.log({ momentary: this.lufs.momentaryLUFS, shortTerm: this.lufs.shortTermLUFS })
    }

    get lufsAnalyzer() {
        return this.lufs
    }

    sendPayload = (type: string, data: any) => {
        this._worker.postMessage({ type, payload: data })
    }

    on = (name: string, func: any) => {
        this.events.on(name, func)
    }

    off = (name: string, func: any) => {
        this.events.off(name, func)
    }
}
