export function scrollIt(destination: any, easing = 'easeInOutCubic', duration = 500, callback: Function) {
    const easings: any = {
        linear(t: any) {
            return t
        },
        easeInQuad(t: any) {
            return t * t
        },
        easeOutQuad(t: any) {
            return t * (2 - t)
        },
        easeInOutQuad(t: any) {
            return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t
        },
        easeInCubic(t: any) {
            return t * t * t
        },
        easeOutCubic(t: any) {
            return --t * t * t + 1
        },
        easeInOutCubic(t: any) {
            return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
        },
        easeInQuart(t: any) {
            return t * t * t * t
        },
        easeOutQuart(t: any) {
            return 1 - --t * t * t * t
        },
        easeInOutQuart(t: any) {
            return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t
        },
        easeInQuint(t: any) {
            return t * t * t * t * t
        },
        easeOutQuint(t: any) {
            return 1 + --t * t * t * t * t
        },
        easeInOutQuint(t: any) {
            return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t
        },
    }

    // Store initial position of a window and time
    // If performance is not available in your browser
    // It will fallback to new Date().getTime() - thanks IE < 10
    const start = window.pageYOffset
    const startTime = 'now' in window.performance ? performance.now() : new Date().getTime()
    // const startTime = typeof(window.performance['now']) == 'function' ? performance.now() : new Date().getTime();

    // Take height of window and document to sesolve max scrollable value
    // Prevent requestAnimationFrame() from scrolling below maximum scollable value
    // Resolve destination type (node or number)
    const documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight)
    const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight
    const destinationOffset = typeof destination === 'number' ? destination : destination.offsetTop
    const destinationOffsetToScroll = Math.round(documentHeight - destinationOffset < windowHeight ? documentHeight - windowHeight : destinationOffset)

    // If requestAnimationFrame is not supported
    // Move window to destination position and trigger callback function
    if ('requestAnimationFrame' in window === false) {
        window.scroll(0, destinationOffsetToScroll)
        if (callback) {
            callback()
        }
        return
    }

    // function resolves position of a window and moves to exact amount of pixels
    // Resolved by calculating delta and timing function choosen by user
    function scroll() {
        const now = 'now' in window.performance ? performance.now() : new Date().getTime()
        const time = Math.min(1, (now - startTime) / duration)
        const timeFunction = easings[easing](time)
        window.scroll(0, Math.ceil(timeFunction * (destinationOffsetToScroll - start) + start))

        // Stop requesting animation when window reached its destination
        // And run a callback function
        if (window.pageYOffset === destinationOffsetToScroll) {
            if (callback) {
                callback()
            }
            return
        }

        // If window still needs to scroll to reach destination
        // Request another scroll invokation
        requestAnimationFrame(scroll)
    }

    // Invoke scroll and sequential requestAnimationFrame
    scroll()
}
