import Store from '../store'
import * as React from 'react'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'

import TrashIcon from '@material-ui/icons/Delete'
import ExecuteIcon from '@material-ui/icons/Send'
import CancelIcon from '@material-ui/icons/Cancel'

import { delay, HSpace, mobx } from '../utils'
import { action, toJS } from 'mobx'
import { Bounce } from '../store/bounces'

class BounceQueueDialog extends React.Component<{ store: Store; closeDialog: any; showBouncesDialog: any }> {
    @action.bound
    executeWholeQueue = async () => {
        this.props.closeDialog()
        this.props.showBouncesDialog()

        const ids = this.props.store.queuedBounces.map(k => k.id)

        for (const k of ids) {
            // the bounces dialog will finish it
            await this.props.store.executeBounce(k)

            while (this.props.store.currentBounce != null) {
                await delay(250)
            }
        }
    }

    @action.bound
    deleteFromQueue = async (q: Bounce) => {
        this.props.store.removeBounce(q.id)

        if (this.props.store.queuedBounces.length === 0) {
            this.props.closeDialog()
        }
    }

    render() {
        return (
            <Dialog open={true} maxWidth={'lg'} onClose={this.props.closeDialog}>
                <DialogTitle>Bounce Queue</DialogTitle>
                <DialogContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Bounce</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.store.queuedBounces.map(q => (
                                <TableRow key={q.id}>
                                    <TableCell>{q.title}</TableCell>
                                    <TableCell>
                                        <Button onClick={() => this.deleteFromQueue(q)}>
                                            <TrashIcon /> Delete
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.closeDialog} variant="outlined">
                        <CancelIcon />
                        <HSpace />
                        Cancel
                    </Button>
                    <Button onClick={this.executeWholeQueue} variant="contained">
                        <ExecuteIcon />
                        <HSpace />
                        Execute Queue
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default mobx(BounceQueueDialog)
