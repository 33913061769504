import React from 'react'
import { toJS } from 'mobx'

import { Session } from '../api'
import devices from './devices'

interface Props {
    session: Session
    parent: any
    deviceId: string
    localScale: number
    masonry: any
}

export default (p: Props) => {
    /* so we do the same as usual */
    const deviceDesc = toJS(p.session.devices[p.deviceId])
    const deviceType = deviceDesc.type
    const Device = devices[deviceType]

    if (Device) {
        return <Device key={`sidecar_${p.deviceId}`} uiScale={p.localScale} id={p.deviceId} masonry={p.masonry} enabledFeatures={p.parent.enabledCnbFeatures || ['mid/side', 'dry/wet']} />
    }
    return null
}
