import React from 'react'
import SmoothieComponent, { TimeSeries } from 'react-smoothie'

import { factorToDb, mobx } from '../utils'
import Store from '../store'

type SmoothieColor = { r?: number; g?: number; b?: number; a?: number }

function avg(a: number, b: number) {
    return (a + b) / 2
}

class MeteringGraph extends React.Component<{ width: number; height: number; store: Store }> {
    peak = new TimeSeries()
    rms = new TimeSeries()
    momentary = new TimeSeries()
    shortTerm = new TimeSeries()

    componentDidMount() {
        this.props.store.on('playingAnimation', this.updateAnimation)
    }

    componentWillUnmount() {
        this.props.store.off('playingAnimation', this.updateAnimation)
    }

    updateAnimation = () => {
        if (this.props.store.meterDisabled) {
            return
        }

        const { store } = this.props
        const { player, meter } = store
        const { measurements: m, position: pos, node } = player
        const now = new Date().valueOf()
        const rel = 1000

        const lPeak = factorToDb(m.masterVolumeLeftPeak.getValue(pos))
        const rPeak = factorToDb(m.masterVolumeRightPeak.getValue(pos))

        this.peak.append(now, avg(lPeak, rPeak))

        // RMS mode is default
        const lRms = factorToDb(m.masterVolumeLeftRms.getValue(pos))
        const rRms = factorToDb(m.masterVolumeRightRms.getValue(pos))

        this.rms.append(now, avg(lRms, rRms))

        if (node && node.lufsAnalyzer) {
            const [lMom, rMom] = node.lufsAnalyzer.momentaryLUFS || []
            this.momentary.append(now, avg(lMom, rMom))

            const [lShort, rShort] = node.lufsAnalyzer.shortTermLUFS || []
            this.shortTerm.append(now, avg(lShort, rShort))
        }
    }

    tooltip = (props: any) => {
        if (!props.display) return <div />

        return (
            <div
                style={{
                    userSelect: 'none',
                    background: '#343434',
                    padding: '0.5em',
                    marginLeft: '10px',
                    marginRight: '10px',
                    fontFamily: 'consolas',
                    color: 'white',
                    fontSize: '10px',
                    pointerEvents: 'none',
                    zIndex: 1500000,
                }}
            >
                {props.data ? (
                    props.data.map((data: any, i: number) => (
                        <>
                            <span key={i} style={{ color: data.series.options.strokeStyle, whiteSpace: 'nowrap' }}>
                                {data.series.options.label}: {parseFloat(data.value).toFixed(2)}dB
                            </span>
                            <br />
                        </>
                    ))
                ) : (
                    <div />
                )}
            </div>
        )
    }

    series = [
        {
            data: this.peak,
            strokeStyle: { g: 255 } as SmoothieColor,
            fillStyle: { g: 255 } as SmoothieColor,
            lineWidth: 1,
            label: 'Peak',
        },
        {
            data: this.rms,
            strokeStyle: { r: 255 },
            fillStyle: { r: 255 },
            lineWidth: 2,
            label: 'RMS',
        },
        {
            data: this.momentary,
            strokeStyle: { b: 255, r: 20, g: 20 },
            fillStyle: { b: 255, r: 20, g: 20 },
            lineWidth: 1,
            label: 'LUFS Momentary',
        },
        {
            data: this.shortTerm,
            strokeStyle: { b: 255, r: 255 },
            fillStyle: { b: 255, r: 255 },
            lineWidth: 2,
            label: 'LUFS Short Term',
        },
    ]

    render() {
        const { width, height, store } = this.props

        return store.meterDisabled ? null : <SmoothieComponent width={width} height={height} series={this.series} maxValue={0} minValue={-40} grid={{ fillStyle: '#dcdcdc1a' }} interpolation={'linear'} tooltip={this.tooltip} millisPerPixel={40} />
    }
}

export default mobx(MeteringGraph)
