import React from 'react'
import { px, mobx, lerp } from '../../utils'
import ImageKnob, { ModelToggleKnob, ModelImageKnob, Context } from '../ImageKnob'
import Store from '../../store'
import AnimatedCanvas from '../AnimatedCanvas'
import range from 'lodash/range'
import Rect from '../rect'
import { action, toJS } from 'mobx'
import flatten from '../index'

const scale = [-72, -48, -36, -27, -22, -18, -15, -13, -11, -9, -7, -5, -3, -1, 0]
const lerped = range((scale.length - 1) * 3).map((_, i) => lerp(scale[(i / 3) | 0], scale[(i / 3 + 1) | 0], (i % 3) / 3))

const red = '#F90000'
const orange = '#FF7300'
const green = '#00FF18'

class BurlInsert extends React.Component<{ id: string; store: Store; uiScale: number }> {
    inputGain?: ImageKnob
    outputGain?: ImageKnob
    clipped: { [id: string]: boolean } = {}
    peakHolds: { [id: string]: { time: number; value: number } } = {}

    @action.bound
    toggleBypass = () => {
        this.props.store.setSlotSettings([{ processor: this.props.id, parameter: 'bypass', value: this.props.store.globalBypass ? 0 : 1 }], true)
    }

    static renderTitle() {
        return 'Digital Converter Control'
    }

    renderMeter(name: string, fixOffset: boolean, ctx: CanvasRenderingContext2D) {
        const m = this.props.store.player.measurements[name]
        if (m) {
            const { width, height } = ctx.canvas
            const { position } = this.props.store.player
            const val = m.getValue(position)

            ctx.clearRect(0, 0, width, height)

            if (Math.abs(val) >= 1) {
                this.clipped[name] = true
            }
            const now = window.performance.now()
            const db = Math.min(20 * Math.log10(val), 0)

            if (!this.peakHolds[name] || this.peakHolds[name].value <= db || this.peakHolds[name].time <= now - 500) {
                this.peakHolds[name] = { time: now, value: db }
            }

            const dbHold = this.peakHolds[name].value

            for (let i = 0; i < lerped.length; i++) {
                const hold = this.peakHolds[name].value

                if (db < lerped[i] && !(i < lerped.length - 1 && dbHold >= lerped[i] && dbHold <= lerped[i + 1])) {
                    continue
                }

                if (i >= lerped.length - 1) {
                    ctx.fillStyle = red
                } else if (i >= lerped.length - 6) {
                    ctx.fillStyle = orange
                } else {
                    ctx.fillStyle = green
                }

                ctx.fillRect(i * 12.0714285714, 0, 5, height)
            }

            if (this.clipped[name]) {
                ctx.fillStyle = red
                ctx.fillRect(lerped.length * 12.0714285714, 0, 5, height)
            }
        }
    }

    onMeterClick(name: string) {
        this.clipped[name] = false
    }

    render() {
        const { uiScale = 0.5, store, id } = this.props
        const { api } = store

        return (
            <div
                style={{
                    transform: `scale(${uiScale}, ${uiScale})`,
                    transformOrigin: 'left top',
                    margin: 0,
                    padding: 0,
                    width: px(1414 * uiScale),
                    height: px(307 * uiScale),
                }}
                onClick={e => e.preventDefault()}
                onDoubleClick={e => e.preventDefault()}
            >
                <div
                    style={{
                        position: 'relative',
                        top: 0,
                        left: 0,
                        width: px(1414),
                        height: px(307),
                        margin: 0,
                        padding: 0,
                        background: store.globalBypass ? `url(${require('./io_unit_off.jpg')})` : `url(${require('./io_unit_on.jpg')})`,
                        backgroundAttachment: 'local',
                        backgroundSize: 'stretch',
                        boxShadow: 'black 0 4px 12px',
                    }}
                    onClick={e => e.preventDefault()}
                    onDoubleClick={e => e.preventDefault()}
                >
                    <Context.Provider value={{ api, id } as any}>
                        <span style={{ color: 'red', position: 'absolute', left: px(992), top: px(45 - 3) }}>192kHz</span>
                        <span style={{ color: 'red', position: 'absolute', left: px(992), top: px(151 + 45 - 5) }}>192kHz</span>

                        <span style={{ color: 'red', position: 'absolute', left: px(992), top: px(45 - 3 + 53) }}>LOCK</span>
                        <span style={{ color: 'red', position: 'absolute', left: px(992), top: px(151 + 45 - 5 + 53) }}>LOCK</span>

                        {/*<ModelToggleKnob x={781} y={-15} imageUrl={require('./on_off_resized.png')} name={'bypass'} inverted
                                             hitBox={{ w: 59 * uiScale, h: 78 * uiScale, x: 385 * uiScale, y: 138 * uiScale }}/>*/}

                        <Rect h={100} w={76} x={101} y={113} onClick={this.toggleBypass} />
                        <ModelImageKnob imageUrl={require('./strip1_resized.png')} x={230} y={35} nf={61} min={-12} max={12} initial={0.5} name={['input_gain_0', 'input_gain_1']} />
                        <ModelImageKnob imageUrl={require('./strip2_resized.png')} x={230} y={186} nf={61} min={-12} max={12} initial={0.5} name={['wet_output_gain_0', 'wet_output_gain_1']} />

                        <AnimatedCanvas src={this.props.store} width={514} height={17} x={443} y={41} render={ctx => this.renderMeter(`${this.props.id}.input_peak_0`, false, ctx)} onClick={() => this.onMeterClick(`${this.props.id}.input_peak_0`)} />
                        <AnimatedCanvas src={this.props.store} width={514} height={17} x={443} y={95} render={ctx => this.renderMeter(`${this.props.id}.input_peak_1`, false, ctx)} onClick={() => this.onMeterClick(`${this.props.id}.input_peak_1`)} />
                        <AnimatedCanvas src={this.props.store} width={514} height={17} x={443} y={190} render={ctx => this.renderMeter(`${this.props.id}.output_peak_0`, false, ctx)} onClick={() => this.onMeterClick(`${this.props.id}.output_peak_0`)} />
                        <AnimatedCanvas src={this.props.store} width={514} height={17} x={443} y={243} render={ctx => this.renderMeter(`${this.props.id}.output_peak_1`, false, ctx)} onClick={() => this.onMeterClick(`${this.props.id}.output_peak_1`)} />
                    </Context.Provider>
                </div>
            </div>
        )
    }
}

export default mobx(BurlInsert)
