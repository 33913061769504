import React from 'react'
import { observer } from 'mobx-react'
import { observable, action, computed, IReactionDisposer, reaction } from 'mobx'

import { px } from '../utils'

interface Props {
    imageUrl: string
    nf: number
    x: number
    y: number
    animate: boolean
    rewinding?: boolean
}

@observer
class NoninteractiveAnimation extends React.Component<Props> {
    @observable dimensions = { width: 0, height: 0 }

    @observable position: number

    reaction?: IReactionDisposer

    raf?: number

    lastRender = 0

    constructor(props: Props) {
        super(props)
        /* start on a random frame for funzies */
        this.position = Math.round(props.nf * Math.random())
    }

    componentDidMount() {
        const image = new Image()
        image.src = this.props.imageUrl
        image.onload = () => this.onImageLoad(image)

        this.reaction = reaction(() => this.props.animate, this.handleAnimateChange, { fireImmediately: true })
    }

    @computed get framePos() {
        const frameSize = this.dimensions.height / this.props.nf
        return this.dimensions.height - frameSize * this.position
    }

    handleAnimateChange = (animate: boolean) => {
        if (animate) {
            this.raf = requestAnimationFrame(this.onFrame)
        } else if (this.raf !== undefined) {
            cancelAnimationFrame(this.raf)
        }
    }

    onFrame = (prevFrame: number) => {
        const elapsed = prevFrame - this.lastRender
        if (elapsed > 30) {
            const { nf, rewinding } = this.props
            let nextPosition: number
            if (rewinding) {
                nextPosition = (this.position - 3) % (nf - 1)
                if (nextPosition < 0) {
                    nextPosition += nf
                }
            } else {
                nextPosition = (this.position + 1) % (nf - 1)
            }

            this.setFramePos(nextPosition)

            this.lastRender = prevFrame
        }

        if (this.props.animate) {
            this.raf = requestAnimationFrame(this.onFrame)
        }
    }

    @action setFramePos = (n: number) => {
        this.position = n
    }

    @action
    onImageLoad = (image: HTMLImageElement) => {
        this.dimensions = { width: image.width, height: image.height }
    }

    render() {
        const { imageUrl, nf, x, y } = this.props
        const { dimensions, framePos } = this

        return (
            <div
                className="knob"
                style={{
                    position: 'absolute',
                    display: 'block',
                    height: `${dimensions.height / nf}px`,
                    width: `${dimensions.width}px`,
                    top: px(y),
                    left: px(x),
                    background: `url(${imageUrl}) 0px ${framePos}px`,
                    touchAction: 'none',
                }}
            />
        )
    }
}

export default NoninteractiveAnimation
