import * as React from 'react'
import EventEmitter from 'event-emitter-es6'
import { px } from '../utils'

export default class AnimatedCanvas extends React.Component<{
    src: EventEmitter
    render: (ctx: CanvasRenderingContext2D, pos: number) => void
    width: number
    height: number
    x: number
    y: number
    style?: any
    onClick?: any
}> {
    canvas?: HTMLCanvasElement

    componentDidMount() {
        // this.timer = setInterval(this.updateAnimation, 10)
        this.props.src.on('playingAnimation', this.updateAnimation)
    }

    componentWillUnmount() {
        // clearInterval(this.timer)
        this.props.src.off('playingAnimation', this.updateAnimation)
    }

    updateAnimation = (position: number) => {
        if (this.canvas) {
            this.props.render(this.canvas.getContext('2d') as any, position)
        }
    }

    render() {
        const { render, ...other } = this.props
        return <canvas {...other} style={{ position: 'absolute', top: px(this.props.y), left: px(this.props.x), ...this.props.style }} ref={(c: any) => (this.canvas = c)} width={this.props.width} height={this.props.height} />
    }
}
