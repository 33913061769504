import * as React from 'react'
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@material-ui/core'
import HelpIcon from '@material-ui/icons/HelpOutlined'
import map from 'lodash/map'
import { action, observable } from 'mobx'

import Store from '../store'
import { px, mobx, HSpace } from '../utils'
import MouseOverPopover from '../settings/MouseOverPopover'

import Typography from '@material-ui/core/Typography'
import CancelIcon from '@material-ui/icons/Cancel'
import AddToQueueIcon from '@material-ui/icons/AddToQueue'
import BounceNowIcon from '@material-ui/icons/Send'

import find from 'lodash/find'

const SampleRates = [44100, 48000, 88200, 96000, 192000]
const BitDepths = [16, 24, 32, 64]

class CreateBounceDialog extends React.Component<{ store: Store; closeDialog: any; openBouncesDialog: any }> {
    @observable
    title: string = ''

    @observable
    sampleRate: number = 44100

    @observable
    bitDepth: number = 24

    componentDidMount() {
        /* update */
        const { store } = this.props
        const { media, player, currentSlotMediaId } = store
        const foundMedia: any = find(media, { id: currentSlotMediaId || '' })

        if (foundMedia) {
            const foundFile: any = find(foundMedia.files, { isOriginal: true })

            if (foundFile) {
                this.sampleRate = foundFile.sampleRate
            }

            this.title = `${foundMedia.title} - ${(player.session as any).product} @ ${this.sampleRate}`
        }
    }

    @action.bound
    enqueue = async (bounceNow?: boolean) => {
        const { id } = await this.props.store.addBounceToQueue(this.title, this.sampleRate)

        if (bounceNow) {
            await this.props.closeDialog()
            this.props.openBouncesDialog()
            await this.props.store.executeBounce(id)
        } else {
            await this.props.closeDialog()
        }
    }

    @action.bound
    changeSampleRate = (sr: number) => {
        const prev = this.sampleRate
        this.sampleRate = sr
        this.title = this.title.replace(prev.toString(), sr.toString())
    }

    render() {
        const { store } = this.props

        return (
            <Dialog open={true} onClose={this.props.closeDialog} aria-labelledby="save-dialog-title" maxWidth={'lg'}>
                <DialogTitle id="save-dialog-title">
                    Bounces
                    <HSpace/>
                    <MouseOverPopover icon={HelpIcon}>
                        <Typography variant={'body1'}>
                            By clicking <em>Add To Queue</em> below, you will add the current file with the settings as they are now to the
                            Bounce Queue. You can then use the <em>Bounces</em> tab to perform the bounces and download the results.
                        </Typography>
                    </MouseOverPopover>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Title"
                        type="text"
                        fullWidth
                        value={this.title}
                        style={{ marginTop: px(15) }}
                        onKeyPress={ev => {
                            if (ev.key === 'Enter') {
                                ev.preventDefault()
                                this.enqueue(true).catch(console.error)
                            }
                        }}
                        onChange={e => (this.title = e.target.value)}
                    />
                    <TextField id="sample-rate" select helperText="Sample rate" margin="normal" value={this.sampleRate}
                               onChange={e => this.changeSampleRate(parseInt(e.target.value))}>
                        {map(SampleRates, (l, index) => (
                            <MenuItem key={index} value={l}>
                                {l}
                            </MenuItem>
                        ))}
                    </TextField>
                    <HSpace/>
                    <TextField id="bit-depth" select helperText="Bit Depth" margin="normal" value={this.bitDepth} disabled={true}>
                        {map(BitDepths, (l, index) => (
                            <MenuItem key={index} value={l}>
                                {l}
                            </MenuItem>
                        ))}
                    </TextField>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.closeDialog} variant="outlined">
                        <CancelIcon/>
                        <HSpace/>
                        Cancel
                    </Button>
                    <Button onClick={() => this.enqueue(false)} variant="outlined" disabled={store.currentSlotMedia == null}>
                        <AddToQueueIcon/>
                        <HSpace/>
                        Add To Queue
                    </Button>
                    <Button onClick={() => this.enqueue(true)} variant="contained" disabled={store.currentSlotMedia == null}>
                        <BounceNowIcon/>
                        <HSpace/>
                        Bounce Now!
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default mobx(CreateBounceDialog)
