import React from 'react'
import styled from 'styled-components'
import { mobx } from '../utils'
import Store from '../store'

const Mixanalog = styled.img<any>`
    width: 118px;
    height: 21.8px;
    padding: 0px 24px;
    object-fit: contain;
    filter: ${(props: any) => (props.themeId === 'dark' ? 'none' : 'invert()')};

    @media (max-width: 500px) {
        display: none;
    }
`

export default mobx((props: { store: Store }) => (
    <a href="https://app.mixanalog.com">
        <Mixanalog src={require('./images/mix_analog.svg')} themeId={props.store.themeId} {...props} />
    </a>
))
