import * as React from 'react'
import styled from 'styled-components'
import { factorToDb, lerp, mobx, px } from '../utils'
import Store, { Meter } from '../store'

import { action, IValueDidChange, observable, observe } from 'mobx'

const dbScales = [[0, 0], [-6, 72], [-12, 134], [-18, 198], [-24, 260], [-24, 260], [-30, 325], [-36, 389], [-36, 389], [-42, 452], [-48, 513], [-54, 576]]

class VerticalPeakMeter extends React.Component<{
    uiScale: number
}> {
    canvas: HTMLCanvasElement | null = null
    ctx: CanvasRenderingContext2D | null = null
    gradient: CanvasGradient | null = null
    _current = -Infinity

    setCanvas = (c: HTMLCanvasElement) => {
        this.canvas = c

        if (this.canvas) {
            this.initCtx()
        }
    }

    initCtx(): boolean {
        if (!this.canvas) {
            return false
        }

        if (!this.ctx) {
            const ctx = this.canvas.getContext('2d')
            if (!ctx) {
                return false
            }

            ctx.font = 'bold 20px "Roboto Mono"'
            const g = ctx.createLinearGradient(0, 0, 0, 600)

            g.addColorStop(0, '#F60D03')
            g.addColorStop(0.06, '#FF7600')
            g.addColorStop(0.1, '#EAFF00')
            g.addColorStop(0.3, '#C6FF00')
            g.addColorStop(0.5, '#7DFF00')
            g.addColorStop(1.0, '#00FF00')

            this.gradient = g
            this.ctx = ctx
        }

        return true
    }

    drawMetering = () => {
        if (this.initCtx()) {
            const ctx = this.ctx as CanvasRenderingContext2D

            ctx.clearRect(0, 0, 50, 600)
            ctx.fillStyle = this.gradient as CanvasGradient

            /* ok at what y do we want to go */
            const { position: volDb } = this

            let i = -1
            for (const [db, y] of dbScales) {
                i++
                if (volDb <= db) continue
                const prev = dbScales[i - 1] || [0, 0]

                ctx.fillRect(0, lerp(prev[1], y, Math.abs(volDb - prev[0]) / Math.abs(db - prev[0])), 50, 600)
                break
            }
        }
    }

    set position(newVal: number) {
        if (this._current !== newVal) {
            this._current = newVal

            this.drawMetering()
        }
    }

    get position() {
        return this._current
    }

    render() {
        const { uiScale } = this.props
        return (
            <div
                style={{
                    paddingRight: 2,
                    paddingLeft: 2,
                    display: 'inline-block',
                    position: 'relative',
                }}
            >
                <canvas
                    width={50}
                    height={600}
                    style={{
                        position: 'relative',
                        width: 50 * uiScale,
                        height: 600 * uiScale,
                    }}
                    ref={(c: any) => {
                        this.canvas = c
                        if (this.canvas) {
                            this.drawMetering()
                        }
                    }}
                />
                <img
                    src={require('./images/meter.png')}
                    style={{
                        position: 'absolute',
                        left: px(0),
                        width: 50 * uiScale,
                        height: 600 * uiScale,
                    }}
                    alt="meter overlay"
                />
            </div>
        )
    }
}

function clarify(n: number): number {
    if (isFinite(n)) {
        return n
    } else {
        return 0
    }
}

class Metering extends React.Component<{
    uiScale: number
    store: Store
}> {
    @observable meter = Meter.RMS

    lRms?: VerticalPeakMeter
    lPeak?: VerticalPeakMeter
    rRms?: VerticalPeakMeter
    rPeak?: VerticalPeakMeter

    @observable
    max = [-Infinity, -Infinity, -Infinity, -Infinity]
    maxTimes = [0, 0, 0, 0]

    timer: any = null
    observePlayState?: Function

    componentWillUnmount() {
        this.observePlayState && this.observePlayState()
        this.props.store.off('playingAnimation', this.updateAnimation)
    }

    updateAnimation = () => {
        const { store } = this.props
        const { player, meter } = store
        const { measurements: m, position: pos, node } = player
        const now = new Date().valueOf()
        const rel = 1000

        try {
            const lPeak = factorToDb(m.masterVolumeLeftPeak.getValueWithSmoothing(pos, 4))
            const rPeak = factorToDb(m.masterVolumeRightPeak.getValueWithSmoothing(pos, 4))

            // RMS mode is default
            let lRms = factorToDb(m.masterVolumeLeftRms.getValue(pos))
            let rRms = factorToDb(m.masterVolumeRightRms.getValue(pos))

            if (node && node.lufsAnalyzer) {
                if (meter === Meter.LUFSMomentary) {
                    ;[lRms, rRms] = node.lufsAnalyzer.momentaryLUFS
                } else if (meter === Meter.LUFSShortTerm) {
                    ;[lRms, rRms] = node.lufsAnalyzer.shortTermLUFS
                }
            }

            if (this.lRms) {
                /* resolution of valueOf */
                this.lRms.position = lRms
                if (this.max[0] < lRms || this.maxTimes[0] + rel < now) {
                    this.maxTimes[0] = now
                    this.max[0] = lRms
                }
            }
            if (this.lPeak) {
                this.lPeak.position = lPeak
                if (this.max[1] < lPeak || this.maxTimes[1] + rel < now) {
                    this.maxTimes[1] = now
                    this.max[1] = lPeak
                }
            }
            if (this.rPeak) {
                this.rPeak.position = rPeak
                if (this.max[2] < rPeak || this.maxTimes[2] + rel < now) {
                    this.maxTimes[2] = now
                    this.max[2] = rPeak
                }
            }
            if (this.rRms) {
                this.rRms.position = rRms
                if (this.max[3] < rRms || this.maxTimes[3] + rel < now) {
                    this.maxTimes[3] = now
                    this.max[3] = rRms
                }
            }
        } catch (e) {}
    }

    componentDidMount() {
        this.props.store.on('playingAnimation', this.updateAnimation)

        this.observePlayState = observe(this.props.store, 'isPlaying', (ivc: IValueDidChange<boolean>) => {
            try {
                if (!ivc.newValue) {
                    /* we stopped */
                    if (this.lRms) {
                        this.lRms.position = -Infinity
                    }
                    if (this.rRms) {
                        this.rRms.position = -Infinity
                    }
                    if (this.lPeak) {
                        this.lPeak.position = -Infinity
                    }
                    if (this.rPeak) {
                        this.rPeak.position = -Infinity
                    }
                }
            } catch (e) {
                console.error('from observePlayState', e)
            }
        }) as any
    }

    @action.bound
    changeMeter = (m: Meter) => {
        this.props.store.meter = m
    }

    render() {
        const { uiScale, store } = this.props
        const { meter } = store

        return (
            <div>
                <select value={this.props.store.meter} onChange={e => this.changeMeter(parseInt(e.target.value))} className={''} style={{ backgroundColor: '#343434', color: 'white', width: '100%' }}>
                    <option value={Meter.RMS}>RMS</option>
                    <option value={Meter.LUFSMomentary}>LUFS - Momentary</option>
                    <option value={Meter.LUFSShortTerm}>LUFS - Short term</option>
                </select>
                <UpperLabelsStyle>
                    <span />
                    <span>{meter === Meter.RMS ? 'RMS' : 'LUFS'}</span>
                    <span>Peak</span>
                    <span>Peak</span>
                    <span>{meter === Meter.RMS ? 'RMS' : 'LUFS'}</span>
                    <span />
                </UpperLabelsStyle>
                <UpperLabelsStyle>
                    <span />
                    <span>{clarify(Math.round(this.max[0] * 10) / 10)}</span>
                    <span>{clarify(Math.round(this.max[1] * 10) / 10)}</span>
                    <span>{clarify(Math.round(this.max[2] * 10) / 10)}</span>
                    <span>{clarify(Math.round(this.max[3] * 10) / 10)}</span>
                    <span />
                </UpperLabelsStyle>
                <MeteringStyle>
                    <VerticalPeakMeter ref={(m: any) => (this.lRms = m)} uiScale={uiScale} /> <VerticalPeakMeter ref={(m: any) => (this.lPeak = m)} uiScale={uiScale} /> <VerticalPeakMeter ref={(m: any) => (this.rPeak = m)} uiScale={uiScale} /> <VerticalPeakMeter ref={(m: any) => (this.rRms = m)} uiScale={uiScale} />{' '}
                </MeteringStyle>
                <BottomLabelsStyle>
                    <span />
                    <span>L</span>
                    <span />
                    <span>R</span>
                    <span />
                </BottomLabelsStyle>
            </div>
        )
    }
}

export default mobx(Metering)

const MeteringStyle = styled.div`
    display: flex;
    justify-content: center;
    padding: 5px;
    border-radius: 3px;
    background-color: #dcdcdc1a;
    font-family: 'IBM Plex Sans', 'Helvetica', 'Arial', sans-serif;
`

const BottomLabelsStyle = styled.div`
    display: flex;
    width: 100%;
    margin: auto;
    color: white;
    background-color: #dcdcdc1a;
    font-family: 'IBM Plex Sans', 'Helvetica', 'Arial', sans-serif;

    > * {
        flex: 1;
    }

    > span {
        text-align: center;
    }
`

const UpperLabelsStyle = styled(BottomLabelsStyle as any)`
    padding-top: 5px;
    font-size: 0.5em;
    padding-bottom: -20px;
`
