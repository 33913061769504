import React from 'react'
import { AppBar, Button, Typography, withStyles } from '@material-ui/core'
import RecordIcon from '@material-ui/icons/FiberManualRecord'
import MenuIcon from '@material-ui/icons/Menu'
import { action, observable, toJS } from 'mobx'
import Masonry from 'react-masonry-component'
import map from 'lodash/map'

import './styles.scss'
import BurlInsert from './burl_insert'
import { scrollIt } from '../components/scroll'

import Devices from './devices'
import Store from '../store'
import { delay, HSpace, mobx, px } from '../utils'
import Sidebar from './Sidebar'
import Transport from './Transport'
import Waveform from './Waveform'

import bgImage from './images/bg.jpg'
import CreateBounceDialog from './CreateBounceDialog'
import BounceQueueDialog from './BounceQueueDialog'
import SettingsDialog from '../settings'
import BounceListDialog from '../bounce'
import RenderSidecars from './RenderSidecars'
import BypassButton from '../components/componentHeader/BypassButton'

const styles = (theme: any) => ({
    root: {
        flexGrow: 1,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
})

class Play extends React.Component<{ classes: any; store: Store }> {
    deviceComponents: { [id: string]: HTMLDivElement } = {}
    keys = [49, 50, 51, 52, 53, 54, 55, 56, 57, 48]
    masonry: any
    dialogVisible = false

    @observable
    createBounceVisible = false

    @observable
    queueVisible = false

    @observable
    bounceListVisible = false

    @observable
    settingsVisible = false

    constructor(props: any) {
        super(props)
    }

    componentWillUnmount() {
        if (typeof document !== 'undefined') {
            document.removeEventListener('keydown', this.handleSlotKeys)
        }
        this.props.store.isPlaying && this.props.store.stop()
    }

    componentDidMount() {
        if (typeof document !== 'undefined') {
            document.addEventListener('keydown', this.handleSlotKeys)
        }

        setTimeout(() => this.props.store.storeSlotToServerAndRefresh(), 100)
    }

    @action.bound
    showBounceDialog = () => {
        this.props.store.changeDialogVisible(true)
        this.createBounceVisible = true
    }

    @action.bound
    showQueueDialog = () => {
        this.props.store.changeDialogVisible(true)
        this.queueVisible = true
    }

    @action.bound
    showSettingsDialog = () => {
        this.props.store.changeDialogVisible(true)
        this.props.store.settingsVisible = true
    }

    @action.bound
    showBounceListDialog = () => {
        this.bounceListVisible = true
        this.props.store.changeDialogVisible(true)
    }

    @action.bound
    clearBounceListDialog = async () => {
        this.bounceListVisible = false
        this.props.store.changeDialogVisible(false)

        this.props.store.currentBounce = null
        await this.props.store.stop()
        await delay(500)

        this.props.store.resetAudioListenerToPlayer()
    }

    @action.bound
    clearCreateBounceDialog = () => {
        this.props.store.changeDialogVisible(false)
        this.createBounceVisible = false

        return delay(1)
    }

    @action.bound
    clearQueueDialog = () => {
        this.props.store.changeDialogVisible(false)
        this.queueVisible = false

        return delay(1)
    }

    @action.bound
    clearSettingsDialog = () => {
        this.props.store.changeDialogVisible(false)
        this.props.store.settingsVisible = false

        return delay(1)
    }

    handleSlotKeys = (ke: KeyboardEvent) => {
        if (this.props.store.dialogVisible) {
            return
        }

        const { keyCode } = ke
        if (keyCode === 32) {
            /* space bar */
            ke.preventDefault()
            ke.stopPropagation()

            if (this.props.store.isPlaying) {
                this.props.store.stop()
            } else {
                this.props.store.play()
            }

            return false
        }
        const { store } = this.props,
            nextSlotIndex = this.keys.reduce((acc: any, code: any, index: any) => (keyCode === code ? (acc = index) : acc), undefined)

        if (nextSlotIndex !== undefined && store.isSlotIndexPopulated(nextSlotIndex)) {
            store.selectedSlotIndex = nextSlotIndex
            store.storeSlotToServerAndRefresh().catch(console.error)
            return false
        }

        return true
    }

    handleLayoutClick = (deviceId: any) => {
        if (typeof window === 'object') {
            const { top } = this.deviceComponents[deviceId] && this.deviceComponents[deviceId].getBoundingClientRect()
            const { pageYOffset = 0 } = window
            scrollIt(top - 87 + pageYOffset, undefined, undefined, () => {})
        }
    }

    render() {
        const { store } = this.props
        const { deviceOrderData, session } = this.props.store.player

        if (!store.canOpenLive) {
            return <div />
        }

        const { windowInnerWidth } = store
        const responsiveScaler = windowInnerWidth > 650 ? windowInnerWidth - 185 : windowInnerWidth - 30
        const localScale = Math.min(responsiveScaler / 1414, store.maxUiScale)
        const totalLeftMargin = windowInnerWidth > 650 ? `${330 * localScale}px` : '0px'

        return (
            <React.Fragment>
                <Sidebar key={0} handleLayoutClick={this.handleLayoutClick} uiScale={localScale} deviceOrder={session && session.deviceOrder} changeDialogVisible={this.props.store.changeDialogVisible} />

                {/* dialogs */}
                {this.createBounceVisible ? <CreateBounceDialog closeDialog={this.clearCreateBounceDialog} openBouncesDialog={this.showBounceListDialog} /> : undefined}
                {this.queueVisible ? <BounceQueueDialog closeDialog={this.clearQueueDialog} showBouncesDialog={this.showBounceListDialog} /> : undefined}
                {store.settingsVisible ? <SettingsDialog closeDialog={this.clearSettingsDialog} openDialog={this.showSettingsDialog} /> : undefined}
                {this.bounceListVisible ? <BounceListDialog closeDialog={this.clearBounceListDialog} /> : undefined}

                {/* Devices UI */}
                <Masonry key={2} style={{ marginLeft: totalLeftMargin, marginBottom: '50px', background: bgImage }} ref={ref => (this.masonry = ref)}>
                    {session &&
                        deviceOrderData &&
                        map(deviceOrderData, ({ deviceId, deviceType, simpleDeviceId, deviceDesc }, i) => {
                            const Device = deviceType === 'burl/da' ? BurlInsert : Devices[deviceType]
                            if (deviceType === 'burl/da') {
                                deviceId = 'insert'
                            }

                            const sidecars = deviceDesc.sidecars.map(i => <RenderSidecars key={i.name} session={session} parent={Device} deviceId={i.name} localScale={localScale} masonry={this.masonry} />)
                            sidecars.push(<BypassButton key="sidecar_bypass" deviceType={deviceType} />)

                            return (
                                Device && (
                                    <div ref={(el: any) => (this.deviceComponents[simpleDeviceId] = el)} style={{ margin: `${localScale}em 15px` }} key={simpleDeviceId}>
                                        {Device != BurlInsert ? (
                                            <div className="deviceHeaderWrapper">
                                                {/* TODO: title has to ellipsis if too long */}
                                                <div className="title">
                                                    <span className="place">{i}</span>
                                                    {Device.renderTitle()}
                                                </div>
                                                <div className="headerControlsWrapper">{sidecars}</div>
                                            </div>
                                        ) : (
                                            undefined
                                        )}
                                        <Device uiScale={localScale} id={deviceId} masonry={this.masonry} device={deviceDesc} />
                                    </div>
                                )
                            )
                        })}
                </Masonry>
                <AppBar key={3} style={{ bottom: 0, top: 'unset', zIndex: 100 }} position="fixed" onClick={e => e.preventDefault()} onDoubleClick={e => e.preventDefault()}>
                    <Transport>
                        <Waveform id={store.currentSlotMediaId} />
                    </Transport>
                </AppBar>
                <div
                    style={{
                        position: 'fixed',
                        bottom: px(62),
                        right: px(100),
                    }}
                >
                    <Button variant="contained" size="large" style={{ backgroundColor: 'white', color: 'black' }} onClick={this.showBounceDialog}>
                        <RecordIcon style={{ color: 'red' }} /> <HSpace /> Bounce
                    </Button>
                    <HSpace />
                    {this.props.store.queuedBounces.length ? (
                        <Button variant="contained" size="large" style={{ backgroundColor: 'white', color: 'black' }} onClick={this.showQueueDialog}>
                            <MenuIcon style={{ color: 'red' }} />
                            <HSpace /> Queue {`(${this.props.store.queuedBounces.length})`}
                        </Button>
                    ) : (
                        undefined
                    )}
                </div>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(mobx(Play))
