import * as React from 'react'
import styled from 'styled-components'
import Store from '../store'
import { Encodings, EncodingId } from '../api'
import { mobx } from '../utils'

const RowNavCell = styled.div<any>`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 20px;

    > a {
        margin: 3px;
    }

    @media (max-width: 650px) {
        padding: 5px;
        flex-wrap: wrap;
    }
`
const CenteredOnBreak = styled.div<any>`
    display: flex;
    margin: auto;
`

const PlayButton = styled.button<any>`
    width: 50px;
    height: 50px;
    background-color: ${({ isPlaying }: any) => (isPlaying ? '#566068' : '#689730')};
    border: none;
    border-radius: 5px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.25);
    overflow: hidden;
    opacity: 0.9;
    cursor: pointer;
    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100px;
        /* transition: 0.25s; */
        transform: translateX(${({ isPlaying }: any) => (isPlaying ? '0px' : '-70px')});
        .play {
            border-top: 19px solid transparent;
            border-right: 0px;
            border-bottom: 19px solid transparent;
            border-left: 21px solid white;
            margin-left: 10px;
        }
        .pause {
            width: 10px;
            height: 33px;
            border-left: 7px solid white;
            border-right: 7px solid white;
            border-top: 0px;
            border-bottom: 0px;
            margin-left: 6px;
        }
    }
    &:hover {
        opacity: 1;
    }
    &:focus {
        outline: none;
    }
`

const BigClock = styled.div<any>`
    width: 247px;
    height: 50px;
    border-radius: 5px;
    margin-left: 3px;
    background-color: black;
    color: #f1f1f1;
    display: flex;
    align-items: center;

    span {
        text-transform: uppercase;
    }
`

const BigClockText = styled.span<any>`
    padding-left: 5px;
    font-family: 'Roboto Mono', monospace;
    font-size: 35px;
    letter-spacing: -2px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    text-align: center;
    color: #f1f1f1;
`

const Separator = styled.div<any>`
    width: 2px;
    height: 32px;
    opacity: 0.25;
    background-color: #ffffff;
    margin-left: 5px;
`

const Statuses = styled.div<any>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 5px;

    > span {
        font-size: 13px;
        font-weight: normal;
    }
`

function zeroPad(x: any, l = 2) {
    return (('' + x) as any).padStart(l, '0')
}

function secondsToString(time: number) {
    const ms = Math.floor((time * 1000) % 1000)
    const sec = ((time * 1000) / 1000) | 0
    const min = (sec / 60) | 0

    return `${zeroPad(min)}:${zeroPad(sec % 60)}:${zeroPad(ms, 3)}`
}

const SampleRateLabel = ({ sampleRate, nativeSampleRate }: { sampleRate: number; nativeSampleRate: number }) => <span style={{ fontFamily: 'Roboto Mono', cursor: 'pointer' }}>{sampleRate}</span>

const EncodingLabel = ({ encoding }: { encoding: EncodingId }) => <span style={{ fontFamily: 'Roboto Mono', cursor: 'pointer' }}>{Encodings[encoding].short}</span>

class BigClockTime extends React.Component<{ store: Store }, { position: number }> {
    state = { position: 0 }

    componentDidMount() {
        this.props.store.on('playingAnimation', this.playingAnimation)
    }

    componentWillUnmount() {
        this.props.store.off('playingAnimation', this.playingAnimation)
    }

    playingAnimation = (position: any) => {
        if (this.state.position !== position) {
            this.setState({ position })
        }
    }

    render() {
        return <BigClockText>{secondsToString(this.state.position)}</BigClockText>
    }
}

export default mobx((props: { store: Store; children?: React.ReactNode }) => (
    <RowNavCell style={{ padding: '' }} onClick={(e: any) => e.preventDefault()} onDoubleClick={(e: any) => e.preventDefault()}>
        <CenteredOnBreak>
            <PlayButton isPlaying={props.store.isPlaying} disabled={!props.store.isEngineConnected} onClick={(e: any) => (props.store.isPlaying ? props.store.stop() : props.store.play())}>
                <div className="container">
                    <div className="pause" />
                    <div className="play" />
                </div>
            </PlayButton>
            <BigClock>
                <BigClockTime store={props.store} />
                <Separator />
                <Statuses>
                    <SampleRateLabel sampleRate={props.store.player.sampleRate} nativeSampleRate={props.store.player.nativeSampleRate} />
                    <EncodingLabel encoding={props.store.player.encoding} />
                </Statuses>
            </BigClock>
        </CenteredOnBreak>
        {props.children}
    </RowNavCell>
))
