import React from 'react'
import { toJS } from 'mobx'
import { px } from '../utils'

interface Props {
    startAngle?: number
    endAngle?: number
    lineWidth?: number
    lineStyle?: string
    length?: number
    initial?: number
    min?: number
    x: number
    y: number
    debug?: boolean
    style?: object
    height?: number
    width?: number
}

export default class Needle extends React.Component<
    Props,
    {
        length: number
        startAngle: number
        endAngle: number
        lineWidth: number
        lineStyle: string
    }
> {
    prevPosition = 0
    drawing = false
    canvas: any
    state = toJS({
        startAngle: -0.785398,
        endAngle: 0.785398,
        lineWidth: 2,
        lineStyle: 'black',
        ...this.props,
    }) as any

    internalState: {
        position: number
    } = {
        position: this.props.initial || this.props.min || 0,
    }

    set position(value: number) {
        if (this.prevPosition != value) {
            this.prevPosition = value
            this.internalState.position = value
            this.update()
        }
    }

    get position(): number {
        return this.internalState.position
    }

    update = () => {
        try {
            if (this.canvas && !this.drawing) {
                this.drawing = true
                const { width, height } = this.canvas
                const ctx = this.canvas.getContext('2d')

                const { length = 200 } = this.props
                const { startAngle, endAngle, lineStyle, lineWidth } = this.state
                const { position } = this.internalState
                const angle = startAngle + (endAngle - startAngle) * position

                if (this.props.debug) {
                    console.log({ position, angle })
                }

                const x = length
                const y = length

                const x2 = 0
                const y2 = length
                const x3 = x2 * Math.cos(angle) - y2 * Math.sin(angle)
                const y3 = y2 * Math.cos(angle) + x2 * Math.sin(angle)

                if (ctx === null) {
                    return
                }

                ctx.clearRect(0, 0, width, height)

                ctx.lineWidth = lineWidth
                ctx.strokeStyle = lineStyle
                ctx.beginPath()
                ctx.moveTo(x, y)
                ctx.lineTo(x - x3, y - y3)
                ctx.stroke()
            }
        } finally {
            this.drawing = false
        }
    }

    render() {
        const { x, y, length = 200, style = {}, width, height } = this.props

        return (
            <canvas
                ref={c => {
                    this.canvas = c
                    this.update()
                }}
                width={width !== undefined ? px(width) : length * 2}
                height={height !== undefined ? px(height) : length * 2}
                style={{ position: 'absolute', left: `${x - length}px`, top: `${y - length}px`, zIndex: 1, ...style }}
            />
        )
    }
}
