import React from 'react'

import { ModelImageKnob, ModelToggleKnob } from '../ImageKnob'
import Needle from '../Needle'
import { mobx, px } from '../../utils'
import Store from '../../store'
import { toJS } from 'mobx'
import Overlay from '../Overlay'
import { CnbFeatures } from '../features'

const BG: Record<string, string> = {
    left: require('./images/la2a-bg-1.png'),
    right: require('./images/la2a-bg-2.png'),
}

const LA2AUnitManaged = mobx(
    class LA2AUnit extends React.Component<{ id: string; uiScale: number; store: Store; prefix: string }> {
        meter: Needle | null = null

        static enabledCnbFeatures: Array<CnbFeatures> = ['mid/side', 'dry/wet']

        componentDidMount() {
            this.props.store.on('playingAnimation', this.updateAnimation)
        }

        componentWillUnmount() {
            this.props.store.off('playingAnimation', this.updateAnimation)
        }

        updateAnimation = () => {
            const { store, id, prefix } = this.props
            const { measurements: m, position: pos } = store.player
            const key = `${this.props.id}.${prefix}Meter`

            const { meter } = this

            try {
                const smoothing =
                    !(toJS(store.slots[store.selectedSlotIndex].settings[id][`${prefix}VU`])) ? 3 : 12

                let offset = 0.12
                let value = Math.min(m[key].getValueWithSmoothing(pos, smoothing) - offset, 1)  // Math.max(0, Math.min(0.5, )) * 1.6

                value = Math.max(0, value)

                if (meter) {
                    meter.position = value
                }
            } catch (e) {
                console.error(e)
            }
        }

        render() {
            let { uiScale = 0.5, store, id, prefix } = this.props
            const { api } = store
            const settings = store.slots[store.selectedSlotIndex].settings[id]
            const bypassed = !!(store.globalBypass || toJS(settings[`${prefix}Bypass`]))

            return (
                <>
                    <Overlay
                        imageUrl={bypassed ? require('./images/vu-bypassed.png') : require('./images/vu-active.png')}
                        style={{ zIndex: -200, filter: store.globalBypass ? 'opacity(0.5)' : '' }} x={1106}
                        y={45}/>
                    <Needle x={1419} y={465} length={245} lineWidth={3} ref={e => this.meter = e}
                            style={{ zIndex: -100, filter: store.globalBypass ? 'opacity(0.5)' : '' }}/>
                    <div
                        style={{
                            width: px(2828),
                            height: px(671),
                            margin: 0,
                            padding: 0,
                            background: `url('${BG[prefix]}')`,
                            backgroundAttachment: 'local',
                            backgroundSize: 'stretch',
                            boxShadow: 'black 0 4px 12px',
                            filter: store.globalBypass ? 'opacity(0.5)' : '',
                            zIndex: 400,
                        }}
                        onClick={e => e.preventDefault()}
                        onDoubleClick={e => e.preventDefault()}
                    >
                        {/*<Overlay imageUrl={require('./images/fast.png')} x={2294} y={205}*/}
                        {/*         style={{ filter: 'opacity(0.9)', mixBlendMode: 'screen' }}/>*/}

                        <ModelToggleKnob name={`${prefix}Limit`} imageUrl={require('./images/limit.png')} x={2294}
                                         y={413}/>

                        <ModelToggleKnob name={`linkPairs`} imageUrl={require('./images/link.png')} x={98}
                                         y={220}/>

                        <ModelToggleKnob name={`${prefix}Bypass`} imageUrl={require('./images/bypass.png')} x={107}
                                         y={386}/>

                        <ModelToggleKnob name={`${prefix}VU`} imageUrl={require('./images/vu.png')} x={1141} y={491}/>

                        <ModelImageKnob name={`${prefix}Feedback`} imageUrl={require('./images/feedback.png')} nf={101}
                                        x={457} y={380}/>

                        <ModelImageKnob name={`${prefix}PeakReduction`} imageUrl={require('./images/peakreduction.png')}
                                        nf={101}
                                        x={1869} y={185}/>

                        <ModelImageKnob name={`${prefix}Gain`} imageUrl={require('./images/gain.png')}
                                        nf={101}
                                        x={713} y={185}/>

                        <ModelImageKnob name={`${prefix}Emphasis`} imageUrl={require('./images/emphasis.png')} nf={101}
                                        x={2234} y={380}/>

                        <ModelToggleKnob name={`${prefix}Slow`} imageUrl={require('./images/fast.png')} x={2294} y={205}
                                         inverted />


                    </div>
                </>
            )
        }
    },
)

export default LA2AUnitManaged
