import mapValues from 'lodash/mapValues'
import EventEmitter from 'event-emitter-es6'

export type PropertySpec = {
    type: 'f' | 'i' | 'fn' | 'nf' | 'b'
    min?: number
    max?: number
    default?: number
    step?: number
    peer?: string
}

export class Property {
    spec: PropertySpec
    value: number = 0

    constructor(spec: PropertySpec, name: string) {
        this.spec = spec

        // console.log('++ Property: ', name, '=>', spec)

        if (this.spec.type === 'i' || this.spec.type === 'b') {
            this.spec.step = 1
        }

        if (this.spec.type === 'nf' || this.spec.type === 'fn') {
            this.spec.min = 0
            this.spec.max = 1
        }

        if (this.spec.default != null) {
            this.setValue(this.spec.default)
        } else if (this.spec.min != null) {
            this.spec.default = this.spec.min
            this.setValue(this.spec.min)
        }

        if (this.spec.min == null) {
            this.spec.min = 0
        }
    }

    setValue(v: number, force: boolean = false): number | null {
        if (this.spec.step != null) {
            const { step } = this.spec
            v = Math.floor(v / step) * step
        }

        if (this.spec.min != null) {
            v = Math.max(this.spec.min, v)
        }
        if (this.spec.max != null) {
            v = Math.min(this.spec.max, v)
        }

        if (this.value !== v || force) {
            this.value = v
            return v
        } else {
            return null
        }
    }

    getUiPosition(value: number): number {
        /* normalize between our min and max */
        const diffFromMin = value - (this.spec.min || 0)
        const fullRange = (this.spec.max || 1) - (this.spec.min || 0)
        return diffFromMin / fullRange
    }

    getDefaultValue(): number {
        return this.spec.default || 0
    }
}

export interface ParametersSpec {
    [id: string]: PropertySpec
}

export interface ModelSpec {
    name: string
    parameters?: ParametersSpec
    reports?: ParametersSpec
}

export class Model {
    parameters: { [id: string]: Property }
    spec: ModelSpec
    reports: { [id: string]: Property }
    emitter: EventEmitter
    name: string
    type: string
    linkPairs: any

    constructor(emitter: EventEmitter, name: string, spec: ModelSpec) {
        this.emitter = emitter
        this.spec = spec
        this.name = name
        this.type = spec.name
        this.parameters = mapValues(spec.parameters, (propSpec, name) => new Property(propSpec, name))
        this.reports = mapValues(spec.reports, (propSpec, name) => new Property(propSpec, name))
        this.linkPairs = this.parameters['linkPairs']
    }

    setValue(propName: string, value: number, fromGui: boolean = false, force: boolean = false) {
        const prop = this.parameters[propName]
        if (prop) {
            const rv = prop.setValue(value, force)
            if (rv !== null) {
                this.emitter.emit(`change.${this.name}.${propName}`, rv, fromGui)
                this.emitter.emit('change', { processor: this.name, parameter: propName, value: rv, fromGui })

                if (this.linkPairs && this.linkPairs.value && prop.spec.peer) {
                    this.setValue(prop.spec.peer, value, force)
                }
            }

            return rv
        } else {
            console.log(`setValue: ${this.name} ${propName} not found`)
            return null
        }
    }

    getValue(propName: string) {
        return this.parameters[propName] && this.parameters[propName].value
    }
}
