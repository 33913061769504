import React, { Component } from 'react'
import { observable, toJS } from 'mobx'
import { observer, Provider } from 'mobx-react'
//@ts-ignore
import BlockUi from 'react-block-ui'

import Template from './template'
import Store from './store'
import Play from './play'

import './index.css'
import 'react-block-ui/style.css'
import { Card, CardContent, Typography } from '@material-ui/core'
import { track } from './store/analytics'

function ErrorPage({ error }: any) {
    return (
        <Card style={{ maxWidth: 1024, margin: 'auto auto', marginTop: '24px' }}>
            <CardContent>
                <Typography variant="h5">Oops! An error occured!</Typography>
                <Typography variant="body1">We are very very sorry 🙇! All errors are logged and an engineer will be assigned to take a look
                    into it as soon as possible. In the mean time, you may try to refresh the page.</Typography>

                <Typography variant="body2" style={{ marginTop: '12px' }}>
                    <span>Details:</span>
                    {JSON.stringify(error)}
                </Typography>
            </CardContent>
        </Card>
    )
}

class RavenErrorBoundary extends React.Component<{ store: Store; children: any }, { error: any }> {
    constructor(props: any) {
        super(props)
        this.state = { error: null }
    }

    componentDidCatch(error: any, errorInfo: any) {
        this.setState({ error })
        this.props.store.applicationError(error, errorInfo).catch(err => {
            console.error('there was an error', error, { errorInfo })
        })
    }

    render() {
        if (this.state.error) {
            return <ErrorPage error={this.state.error}/>
        } else {
            //when there's not an error, render children untouched
            return this.props.children
        }
    }
}


@observer
class App extends Component<{ sessionId: string; token: string }> {
    @observable store: Store
    history: any

    constructor(props: { sessionId: string; token: string }) {
        super(props);
        (window as any).store = this.store = new Store(process.env.REACT_APP_API_URL || 'https://mixanalog.audiocloud.io', props.token, props.sessionId);
        (window as any).toJS = toJS
    }

    componentDidMount(): void {
        track('Logged In', { app: 'studio' })
    }

    render() {
        const { store, props } = this
        const { sessionId } = props
        const { prefix, loading, appError } = store

        let Content = null
        if (appError) {
            Content = <ErrorPage error={appError}/>
        } else if (!loading) {
            Content = (
                <Play/>
            )

            if ((window as any).Raven) {
                Content = <RavenErrorBoundary store={store}>{Content}</RavenErrorBoundary>
            }
        }

        return (
            <BlockUi blocking={(!store.appError && store.loading) || store.blocking}>
                <Provider store={store}>
                    <Template sessionId={sessionId} store={store}>
                        {Content}
                    </Template>
                </Provider>
            </BlockUi>
        )
    }
}

export default App
