import * as React from 'react'
import {ComponentType} from 'react'
import * as ReactDOM from 'react-dom'

import {inject, observer} from 'mobx-react'

import {Card, CardContent, colors, Typography} from '@material-ui/core'
import size from 'lodash/size'
import styled from 'styled-components'
import Store from './store'

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>

export function mobx<P extends { store: Store }>(C: ComponentType<P>): ComponentType<Omit<P, 'store'>> {
    return inject('store')(observer(C)) as any
}

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'center': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
            'tt': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
        }
    }
}

export const AnchorStyle = styled.a`
    > * {
        cursor: pointer;
    }
`
export const Anchor = (props: { href?: string; children?: any; onClick?: Function }) => {
    const {href = '', children, onClick, ...rest} = props
    return (
        <AnchorStyle
            href={href}
            onClick={
                onClick &&
                (e => {
                    e.preventDefault()
                    onClick(e)
                })
            }
            {...rest}
        >
            {children}
        </AnchorStyle>
    )
}

const modalRoot = document.getElementsByTagName('body')[0]

const DialogStyle = styled.div`
    height: 100vh;
    width: 100vw;
    position: fixed;
    left: 0;
    top: 0;
    overflow: auto;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.333);

    .dialogClose {
        position: fixed;
        right: 18px;
        top: 10px;
        display: block;
        cursor: pointer;
        transform: rotate(45deg);

        &:after,
        &:before {
            content: '';
            display: block;
            position: absolute;
            background: #fff;
        }

        &:before {
            width: 22px;
            height: 4px;
            top: 9px;
            left: 0;
            border-radius: 4px;
        }
        &:after {
            height: 22px;
            width: 4px;
            top: 0;
            left: 9px;
            border-radius: 4px;
        }
    }

    .content {
        transition: all 0.25s ease-in;
        position: absolute;
        background: rgba(27, 27, 27, 0.95);
        box-shadow: 0 0px 16 rgba(0, 0, 0, 0.75);
        left: 50%;
        top: 50%;
        max-width: 100%;
        transform: translate(-50%, -50%);
        padding: 20px;
        border: solid thin #777;
        z-index: 1002;
    }

    @media all and (max-width: 720px) {
        .content {
            left: 10px;
            right: 10px;
            transform: translate(0, -50%);
        }
    }
`

export class Dialog extends React.Component<{ children?: any; title: string; onClose?: Function }> {
    el = document.createElement('div')

    componentDidMount() {
        modalRoot.appendChild(this.el)
    }

    componentWillUnmount() {
        modalRoot.removeChild(this.el)
    }

    render() {
        const {children, title, onClose, ...rest} = this.props as any

        return ReactDOM.createPortal(
            <DialogStyle className="ibm" {...rest} onClick={onClose}>
                <div
                    className="content"
                    onClick={e => e.stopPropagation()}
                    onKeyDown={e => {
                        if (e.keyCode === 27 && onClose) {
                            onClose()
                        }
                    }}
                >
                    {children}
                    <div onClick={onClose}>
                        <a className="dialogClose">&nbsp;</a>
                    </div>
                </div>
            </DialogStyle>,
            this.el,
        )
    }
}

export const PageAnimation = ''

export const Route404 = (props: { location: Location }) => (
    <Card style={{maxWidth: 1024, margin: 'auto auto', marginTop: '34px'}}>
        <CardContent>
            <Typography variant="h5" style={{marginBottom: '34px'}}>
                Error: Not Found
            </Typography>
            <Typography variant="body1">
                The location <tt>{props.location.pathname}</tt> was not found. Sorry!
            </Typography>
        </CardContent>
    </Card>
)

export const CenteredContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition-delay: 0.1s;
    transition-duration: 0.1s;
    padding: 15px 15px;

    > div {
        width: 100%;
        max-width: 850px;
        display: flex;
        flex-direction: column;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 39px;
        margin-bottom: 17px;
    }
`

export const FormContainer = styled.ul`
    list-style: none;
    margin: 0;

    > li {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        label {
            flex: 1 0 120px;
            max-width: 150px;
        }

        input,
        select {
            display: inline-block;
            margin-left: 19px;
            flex: 1 0 220px;
            min-width: 212px;
            background-color: #212121;
            border: none;
            color: #d1d1d1;
            border-bottom: 2px #999 solid;
        }

        &.short > select {
            max-width: 200px;
        }

        &.short {
            max-width: 450px;
        }
    }
`

export const Horizontally = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
`

export function humanizeBandwidth(bwKbits: number): string {
    if (bwKbits === Infinity) {
        return 'Unknown'
    } else if (bwKbits > 1000) {
        return `${Math.round((bwKbits / 1000) * 10) / 10} Mbit/s`
    } else {
        return `${Math.round(bwKbits * 10) / 10} Kbit/s`
    }
}

export function colorIsLight(r: number, g: number, b: number): boolean {
    var a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255
    return a < 0.5
}

export function hexToRgb(hex: string): [number, number, number] {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : [0, 0, 0]
}

export function hashColorStyle(s: string): { backgroundColor: string; color: string } {
    let hash = 0
    let i = 0
    let chr = 0

    for (i = 0; i < s.length; i++) {
        chr = s.charCodeAt(i)
        hash = (hash << 5) - hash + chr
        hash |= 0 // Convert to 32bit integer
    }

    i = 0
    const numColors = size(colors)
    for (let v in colors) {
        if (i++ === hash % numColors) {
            const color = (colors as any)[v][400]
            return {backgroundColor: color, color: colorIsLight(...hexToRgb(color)) ? '#000' : '#fff'}
        }
    }

    return {backgroundColor: 'grey', color: 'black'}
}

export function nvl<T>(...items: Array<T | null | void>): T | null {
    for (let o of items) {
        if (o !== null && o !== undefined) {
            return o
        }
    }

    return null
}

export function px(s: number) {
    return `${s}px`
}

export function lerp(a: number, b: number, f: number): number {
    return a * (1 - f) + b * f
}

export function clamp(z: number): number {
    return Math.max(0, Math.min(1, z))
}

export function wait(t: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, t))
}

export function factorToDb(f: number) {
    return 20 * Math.log10(f)
}

export function dbToFactor(f: number) {
    return Math.pow(10, f / 20.0)
}

export function rescale(value: number, srcMin: number, srcMax: number, dstMin: number, dstMax: number) {
    const scale = (dstMax - dstMin) / (srcMax - srcMin)
    return (value - srcMin) * scale + dstMin
}

export const HSpace = styled.div`
    width: 10px;
    display: inline-block;
`

export function delay(ms: number = 10000, rv: any = true): Promise<any> {
    return new Promise(resolve => {
        const tid = setTimeout(() => {
            clearTimeout(tid)
            resolve(rv)
        }, ms)
    })
}
