import BetterLimiter from './better_limiter'
import DistopikADU from './distopik_adu'
import DistopikCnb from './distopik_cnb'
import DistopikDLA2A from './distopik_dla2a'
import DistopikDryWet from './drywet'
import DistopikInsert from './burl_insert'
import DistopikLimiter from './distopik_moslim'
import DistopikMasteringEQ from './distopik_meq'
import DistopikMidSide from './midside'
import DistopikVCA from './distopik_vca'
import DistopikVolume from './distopik_vol'
import DistopikVolume2 from './distopik_vol2'
import Eleven76 from './eleven76'
import ElysiaKaracter from './elysia-karacter'
import ElysiaMuseq from './elysia_museq'
import ElysiaNvelope from './elysia_nvelope'
import ElysiaXpressor from './elysia_xpressor'
import Fairchild670 from './fairchild_670'
import GyrafG24 from './gyraf_g24'
import Pultec from './pultec_eqp1a'
import Studer from './studer'
import TelefunkenM15 from './tape'
import TierraGravity from './tierra_gravity'
import ElysiaXfilter from './elysia_xfilter'
import DistopiKADG76 from './distopik_adg76'
import DistopikN1084 from './distopik_n1084'
import BettermakerBusCompressor from "./better_buscomp"

const Devices = {
    'bettermaker/masterlimiter': BetterLimiter,
    'bettermaker/buscomp': BettermakerBusCompressor,
    'distopik/adu': DistopikADU,
    'distopik/cnb': DistopikCnb,
    'distopik/dla2a': DistopikDLA2A,
    'distopik/drywet': DistopikDryWet,
    'distopik/insert': DistopikInsert,
    'distopik/meq': DistopikMasteringEQ,
    'distopik/midside': DistopikMidSide,
    'distopik/moslim': DistopikLimiter,
    'distopik/vca': DistopikVCA,
    'distopik/volume': DistopikVolume,
    'distopik/volume2': DistopikVolume2,
    'distopik/adg76': DistopiKADG76,
    'elysia/karacter': ElysiaKaracter,
    'elysia/museq': ElysiaMuseq,
    'elysia/nvelope': ElysiaNvelope,
    'elysia/xpressor': ElysiaXpressor,
    'elysia/xfilter': ElysiaXfilter,
    'fairchild/670': Fairchild670,
    'gyraf/g24': GyrafG24,
    'pultec/eqp1a': Pultec,
    'studer/a812': Studer,
    'telefunken/m15': TelefunkenM15,
    'ua/1176reva': Eleven76,
    'tierra/gravity': TierraGravity,
    'distopik/n1084': DistopikN1084
}

export default Devices as any
