import * as React from 'react'
import { Fragment } from 'react'
import { SvgIcon } from '@material-ui/core'
import Popover from '@material-ui/core/Popover/Popover'
import { px } from '../utils'

export default class MouseOverPopover extends React.Component<{ children: React.ReactNode; icon: any }, { anchorEl?: Element }> {
    state = {
        anchorEl: null,
    } as any

    handlePopoverOpen = (event: any) => {
        console.log('handlePopoverOpen', event, event.currentTarget)
        this.setState({ anchorEl: event.currentTarget })
    }

    handlePopoverClose = () => {
        console.log('handlePopoverClose')
        this.setState({ anchorEl: null } as any)
    }

    render() {
        const { icon: Icon, children } = this.props
        const { anchorEl } = this.state
        const open = !!anchorEl

        return (
            <Fragment>
                <span onMouseEnter={this.handlePopoverOpen}>
                    <Icon style={{ position: 'relative', top: '5px' }} />
                </span>
                <Popover
                    style={{ maxWidth: '800px' }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={this.handlePopoverClose}
                    disableRestoreFocus
                >
                    <div style={{ padding: px(20), textAlign: 'justify', minWidth: px(600) }}>{children}</div>
                </Popover>
            </Fragment>
        )
    }
}
