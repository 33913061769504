import React from 'react'
import { px, mobx } from '../../utils'
import { ModelImageKnob, Context } from '../ImageKnob'
import Store from '../../store';

export default mobx(
    class MasterVolume extends React.Component<{store: Store}> {
        static renderTitle() {
            return 'Master Volume'
        }

        render() {
            const { uiScale = 0.5, store, id } = this.props as any
            const { api } = store

            return (
                <Context.Provider value={{ api, id } as any}>
                    <div
                        style={{
                            transform: `scale(${uiScale}, ${uiScale})`,
                            transformOrigin: 'left top',
                            margin: 0,
                            padding: 0,
                            width: px(1414 * uiScale),
                            height: px(233 * uiScale),
                        }}
                        onClick={e => e.preventDefault()}
                        onDoubleClick={e => e.preventDefault()}
                    >
                        <div
                            style={{
                                width: px(1414),
                                height: px(233),
                                transformOrigin: 'left top',
                                margin: 0,
                                padding: 0,
                                background: `url(${require('./bg.jpg')})`,
                                backgroundAttachment: 'local',
                                backgroundSize: 'stretch',
                                boxShadow: 'black 0 4px 12px',
                                filter: store.globalBypass ? 'opacity(0.5)' : '',
                            }}
                            onClick={e => e.preventDefault()}
                            onDoubleClick={e => e.preventDefault()}
                        >
                            <ModelImageKnob imageUrl={require('./knob.png')} x={207} y={59} nf={65} name={'left'} initial={0.75} />
                            <ModelImageKnob imageUrl={require('./knob.png')} x={520} y={59} nf={65} name={'right'} initial={0.75} />
                            <ModelImageKnob imageUrl={require('./knob.png')} x={852} y={59} nf={65} name={'master'} initial={0.75} />
                        </div>
                    </div>
                </Context.Provider>
            )
        }
    },
)
