import React from 'react'
import { ModelImageKnob, ModelToggleKnob, Context } from '../ImageKnob'
import { px, mobx } from '../../utils'
import smallKnob from './images/knob_small_strip.png'
import freqStrip from './images/freq_strip.png'
import gainStrip from './images/gain_strip.png'
import hfShape from './images/slope.png'
import X2 from './images/dB.png'
import inOut from './images/inOut.png'
import bypassLeft from './images/bypass_left.png'
import bypassRight from './images/bypass_right.png'

import range from 'lodash/range'
import Store from '../../store'

const roleNames = ['Lf', 'Lmf', 'Mf', 'Hmf', 'Hf']

export default mobx(
    class DistopikMEQ extends React.Component<{ id: string; uiScale: number; store: Store; masonry: any }> {
        static renderTitle() {
            return 'Mastering Equalizer'
        }

        wasDual: any = null

        setLinking = (value: any) => {
            const { session } = this.props.store.player
            if (session) {
                const { model } = session.devices[this.props.id]
                model.setValue('linkPairs', value, true)
            }
        }

        render() {
            const { uiScale = 0.5, id, store } = this.props
            const { api } = store
            const curSlot = store.player.session && store.player.session.slots[store.selectedSlotIndex] as any
            const isDual = !!curSlot.settings[id]['midSideEnable']
            const height = isDual ? 1631 : 849
            const background = isDual ? require('./images/background.jpg') : require('./images/background_half.jpg')

            if (this.wasDual !== isDual) {
                if (this.props.masonry && this.props.masonry.masonry) {
                    setTimeout(() => this.props.masonry.masonry.layout(), 100)
                    this.wasDual = isDual
                }
            }

            return (
                <Context.Provider value={{ api, id } as any}>
                    <div
                        style={{
                            transform: `scale(${uiScale}, ${uiScale})`,
                            transformOrigin: 'left top',
                            margin: 0,
                            padding: 0,
                            width: px(1414 * uiScale),
                            height: px(height * uiScale),
                        }}
                        onClick={e => e.preventDefault()}
                        onDoubleClick={e => e.preventDefault()}
                    >
                        <div
                            style={{
                                width: px(1414),
                                height: px(height),
                                margin: 0,
                                padding: 0,
                                background: `url(${background})`,
                                backgroundAttachment: 'local',
                                backgroundSize: 'stretch',
                                boxShadow: 'black 0 4px 12px',
                                filter: store.globalBypass ? 'opacity(0.5)' : '',
                            }}
                            onClick={e => e.preventDefault()}
                            onDoubleClick={e => e.preventDefault()}
                        >
                            <ModelToggleKnob x={888} y={40} name={'midSideEnable'} inverted imageUrl={require('./images/mode.png')} onToggle={(value: any) => this.setLinking(!value)} />
                            <ModelToggleKnob x={1250} y={44} name={'leftBypass'} imageUrl={bypassLeft} />
                            <ModelToggleKnob x={1250} y={68} name={'rightBypass'} imageUrl={bypassRight} />

                            {range(5).map(i => (
                                <React.Fragment key={i}>
                                    {i !== 4 && <ModelImageKnob x={122 + 258 * i} y={192} nf={11} imageUrl={smallKnob} name={`left${roleNames[i]}Shape`} />}
                                    {i !== 4 && isDual && <ModelImageKnob x={122 + 258 * i} y={192 + 750} nf={11} imageUrl={smallKnob} name={`right${roleNames[i]}Shape`} />}
                                    <ModelImageKnob x={52 + 258 * i} y={342} nf={21} imageUrl={freqStrip} name={`left${roleNames[i]}Freq`} />
                                    {isDual && <ModelImageKnob x={52 + 258 * i} y={342 + 750} nf={21} imageUrl={freqStrip} name={`right${roleNames[i]}Freq`} />}
                                    <ModelImageKnob x={52 + 258 * i} y={584} nf={81} imageUrl={gainStrip} name={`left${roleNames[i]}Gain`} initial={0.5} />
                                    {isDual && <ModelImageKnob x={52 + 258 * i} y={584 + 750} nf={81} imageUrl={gainStrip} name={`right${roleNames[i]}Gain`} initial={0.5} />}
                                    <ModelToggleKnob x={88 + 258 * i} y={550} imageUrl={X2} name={`left${roleNames[i]}X2`} inverted />
                                    {isDual && <ModelToggleKnob x={88 + 258 * i} y={550 + 750} imageUrl={X2} name={`right${roleNames[i]}X2`} inverted />}
                                    <ModelToggleKnob x={226 + 258 * i} y={550} imageUrl={inOut} inverted default={1} name={`left${roleNames[i]}Bypass`} />
                                    {isDual && <ModelToggleKnob x={226 + 258 * i} y={550 + 750} imageUrl={inOut} inverted default={1} name={`right${roleNames[i]}Bypass`} />}
                                </React.Fragment>
                            ))}
                            <ModelToggleKnob x={1132} y={234} imageUrl={hfShape} name={`leftHfShape`} inverted />
                            {isDual && <ModelToggleKnob x={1132} y={234 + 750} imageUrl={hfShape} name={`rightHfShape`} inverted />}
                        </div>
                    </div>
                </Context.Provider>
            )
        }
    },
)
