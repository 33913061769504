import React from 'react'
import { dbToFactor, mobx, px } from '../../utils'
import Store from '../../store'
import Needle from '../Needle'
import Overlay from '../Overlay'
import { Context, ModelImageKnob, ModelToggleKnob } from '../ImageKnob'

const largeKnobImage = require('./knob_big_128.png')
const startAngle = -(Math.PI / 180) * 52
const endAngle = (Math.PI / 180) * 52

const vuScale = [-20, -10, -7, -5, -3, -2, -1, 0, 1, 2, 3]

class VolumeController2 extends React.Component<{ store: Store; id: string; uiScale: number }> {
    static renderTitle() {
        return 'Master Volume'
    }

    leftInput: Needle | null = null
    rightInput: Needle | null = null
    leftOutput: Needle | null = null
    rightOutput: Needle | null = null

    updateNeedle(id: string, needle: Needle | null, offset: number, factor: number) {
        if (!needle) {
            return
        }

        const { measurements, position: pos } = this.props.store.player
        const m = measurements[`insert_1.${id}`]

        if (m) {
            // add 18dB, scale to 68%
            needle.position = Math.min(1, m.getValueWithSmoothing(pos + offset, 12) * 0.68181818181 * 7.943282347242816 * factor)
        }
    }

    updateAnimation = () => {
        const { session } = this.props.store.player
        let leftFactor = 1, rightFactor = 1
        if (session) {
            const dev = session.devices[this.props.id]
            leftFactor = dbToFactor(dev.model.getValue('leftInputGain') * 30 - 21)
            rightFactor = dbToFactor(dev.model.getValue('rightInputGain') * 30 - 21)
        }

        this.updateNeedle('input_rms_0', this.leftInput, 0.125, leftFactor)
        this.updateNeedle('input_rms_1', this.rightInput, 0.125, rightFactor)
        this.updateNeedle('output_rms_0', this.leftOutput, 0.0, 1)
        this.updateNeedle('output_rms_1', this.rightOutput, 0.0, 1)
    }

    componentDidMount() {
        this.props.store.on('playingAnimation', this.updateAnimation)
    }

    componentWillUnmount() {
        this.props.store.off('playingAnimation', this.updateAnimation)
    }

    render() {
        const { store, uiScale, id } = this.props
        const { api } = store
        return (
            <Context.Provider value={{ api, id } as any}>
                <div
                    onClick={e => e.preventDefault()}
                    onDragStart={e => e.preventDefault()}
                    style={{
                        transform: `scale(${uiScale}, ${uiScale})`,
                        transformOrigin: 'left top',
                        margin: 0,
                        padding: 0,
                        width: px(1748 * uiScale),
                        height: px(766 * uiScale),
                    }}
                >
                    <div
                        onClick={e => e.preventDefault()}
                        onDoubleClick={e => e.preventDefault()}
                        onDragStart={e => e.preventDefault()}
                        style={{
                            width: px(1748),
                            height: px(766),
                            margin: 0,
                            padding: 0,
                            background: `url(${require('./bg.jpg')})`,
                            backgroundAttachment: 'local',
                            backgroundSize: 'stretch',
                            boxShadow: 'black 0 4px 12px',
                            filter: store.globalBypass ? 'opacity(0.5)' : '',
                        }}
                    >
                        <Needle startAngle={startAngle} endAngle={endAngle} ref={n => (this.leftInput = n)} x={327 - 39} y={420}
                                lineWidth={2} length={158}/>
                        <Needle startAngle={startAngle} endAngle={endAngle} ref={n => (this.rightInput = n)} x={700 - 39} y={420}
                                lineWidth={2} length={158}/>
                        <Needle startAngle={startAngle} endAngle={endAngle} ref={n => (this.leftOutput = n)} x={1134 - 39} y={420}
                                lineWidth={2} length={158}/>
                        <Needle startAngle={startAngle} endAngle={endAngle} ref={n => (this.rightOutput = n)} x={1506 - 39} y={420}
                                lineWidth={2} length={158}/>

                        <ModelImageKnob name={'leftInputGain'} imageUrl={largeKnobImage} nf={128} x={260 - 39} y={488}
                                        style={{ zIndex: 10 }} initial={0.705}/>
                        <ModelImageKnob name={'rightInputGain'} imageUrl={largeKnobImage} nf={128} x={633 - 39} y={488}
                                        style={{ zIndex: 10 }} initial={0.705}/>
                        <ModelImageKnob name={'leftOutputGain'} imageUrl={largeKnobImage} nf={128} x={1068 - 39} y={488}
                                        style={{ zIndex: 10 }} initial={0.705}/>
                        <ModelImageKnob name={'rightOutputGain'} imageUrl={largeKnobImage} nf={128} x={1441 - 39} y={488}
                                        style={{ zIndex: 10 }} initial={0.705}/>

                        <ModelToggleKnob name={'linkPairs'} imageUrl={require('./link.png')} x={446} y={537}/>
                        <ModelToggleKnob name={'linkPairs'} imageUrl={require('./link.png')} x={1251} y={537}/>

                        <Overlay x={102} y={233} imageUrl={require('./bg_mask.png')}/>
                    </div>
                </div>
            </Context.Provider>
        )
    }
}

export default mobx(VolumeController2)
