import React from 'react'
import { Context, ModelImageKnob } from '../ImageKnob'
import ToggleKnob from '../ToggleKnob'
import Needle from '../Needle'
import Overlay from '../Overlay'
import { mobx, px } from '../../utils'
import Store from '../../store'
import Rect from '../rect'
import { observable } from 'mobx'
import { Device } from '../../api'

const deflect = 0.758131701

class DistopikVCAStereo extends React.Component<{ id: string; uiScale: number; store: Store, device: Device }> {
    grLeft?: Needle
    grRight?: Needle

    lastVal: any = null

    @observable
    msMode = false

    static renderTitle() {
        return 'Gold Can VCA'
    }

    componentDidMount() {
        this.props.store.on('playingAnimation', this.updateAnimation)
        /* find our device */
        const { session } = this.props.store.player
        for (const sidecar of this.props.device.sidecars) {
            this.props.store.on(`change.${sidecar.name}.midSideEnabled`, this.onMidSideChange)

            if (session) {
                const { settings } = session.slots[this.props.store.selectedSlotIndex]
                this.msMode = !settings[sidecar.name].midSideEnabled
            }
        }
    }

    componentWillUnmount() {
        this.props.store.off('playingAnimation', this.updateAnimation)
        for (const sidecar of this.props.device.sidecars) {
            this.props.store.off(`change.${sidecar.name}.midSideEnabled`, this.onMidSideChange)
        }
    }

    onMidSideChange = (value: any) => {
        this.msMode = value
    }

    updateAnimation = () => {
        const { measurements, position: pos } = this.props.store.player
        let m = measurements[`${this.props.id}.leftGainReduction`]

        try {
            let val = m.getValue(pos)
            val = Math.min(Math.max(0, 1320 / 0xfff - val) / (1 - 1320 / 0xfff) * 2, 1)

            if (this.grLeft) {
                this.grLeft.position = val
            }

        } catch (e) {
        }

        m = measurements[`${this.props.id}.rightGainReduction`]
        try {
            let val = m.getValue(pos)
            val = Math.min(Math.max(0, 1320 / 0xfff - val) / (1 - 1320 / 0xfff) * 2, 1)

            if (this.grRight) {
                this.grRight.position = val
            }

        } catch (e) {
        }
    }

    changeToDual = () => {
        this.props.store.setSlotSettings([
            { parameter: 'linkPairs', processor: this.props.id, value: 0 },
        ], true)
    }

    toggleDual = () => {
        const { session } = this.props.store.player
        if (session) {
            const { settings } = session.slots[this.props.store.selectedSlotIndex]

            this.props.store.setSlotSettings([
                { parameter: 'linkPairs', processor: this.props.id, value: settings[`${this.props.id}.linkPairs`] ? 0 : 1 },
            ], true)
        }
    }

    changeToSingle = () => {
        this.props.store.setSlotSettings([
            { parameter: 'linkPairs', processor: this.props.id, value: 1 },
        ], true)
    }

    render() {
        const { uiScale = 0.5, store, id } = this.props
        const { api } = store

        return (
            <Context.Provider value={{ api, id } as any}>
                <div
                    onClick={e => e.preventDefault()}
                    onDragStart={e => e.preventDefault()}
                    style={{
                        transform: `scale(${uiScale}, ${uiScale})`,
                        transformOrigin: 'left top',
                        margin: 0,
                        padding: 0,
                        width: px(1414 * uiScale),
                        height: px(332 * uiScale),
                    }}
                >
                    <div
                        onClick={e => e.preventDefault()}
                        onDoubleClick={e => e.preventDefault()}
                        onDragStart={e => e.preventDefault()}
                        style={{
                            width: px(1414),
                            height: px(332),
                            margin: 0,
                            padding: 0,
                            background: `url(${require('./images/BG2.png')})`,
                            backgroundAttachment: 'local',
                            backgroundSize: 'stretch',
                            boxShadow: 'black 0 4px 12px',
                            filter: store.globalBypass ? 'opacity(0.5)' : '',
                        }}
                    >
                        {/** we need it **/}
                        <ModelImageKnob name={'leftThreshold'} x={277} y={46} nf={149} inverted imageUrl={require('./images/thresh.png')}/>
                        <ModelImageKnob name={'leftAttack'} x={429} y={46} nf={9} imageUrl={require('./images/attack.png')}/>
                        <ModelImageKnob name={'leftRatio'} x={581} y={46} nf={6} imageUrl={require('./images/ratio.png')}/>
                        <ModelImageKnob name={'leftRelease'} x={732} y={46} nf={7} imageUrl={require('./images/release.png')}/>
                        <ModelImageKnob name={'leftMakeupGain'} x={884} y={46} nf={149} imageUrl={require('./images/makeup.png')}/>
                        <ModelImageKnob name={'leftSidechainFilter'} x={1037} y={46} nf={6} imageUrl={require('./images/scfilter.png')}/>
                        <ModelImageKnob name={'linkLevel'} x={1191} y={46} nf={149} imageUrl={require('./images/link.png')} inverted/>

                        <ModelImageKnob name={'rightThreshold'} x={277} y={202} nf={149} inverted
                                        imageUrl={require('./images/thresh.png')}/>
                        <ModelImageKnob name={'rightAttack'} x={429} y={202} nf={9} imageUrl={require('./images/attack.png')}/>
                        <ModelImageKnob name={'rightRatio'} x={581} y={202} nf={6} imageUrl={require('./images/ratio.png')}/>
                        <ModelImageKnob name={'rightRelease'} x={732} y={202} nf={7} imageUrl={require('./images/release.png')}/>
                        <ModelImageKnob name={'rightMakeupGain'} x={884} y={202} nf={149} imageUrl={require('./images/makeup.png')}/>
                        <ModelImageKnob name={'rightSidechainFilter'} x={1037} y={202} nf={6} imageUrl={require('./images/scfilter.png')}/>

                        <ToggleKnob name={'bypass'} x={1188} y={202} imageUrl={require('./images/bypass.png')} disabled/>

                        <Needle x={196} y={120} length={71} startAngle={-deflect} endAngle={deflect} lineStyle="white"
                                ref={(n: any) => (this.grLeft = n)}/>

                        <Needle x={196} y={278} length={71} startAngle={-deflect} endAngle={deflect} lineStyle="white"
                                ref={(n: any) => (this.grRight = n)}/>

                        <Overlay imageUrl={require('./images/VUMeterMask.png')} x={116} y={27} style={{ zIndex: 900 }}/>
                        <Overlay imageUrl={require('./images/VUMeterMask.png')} x={116} y={185} style={{ zIndex: 900 }}/>
                        <Overlay imageUrl={this.msMode ? require('./images/msmode.png') : require('./images/lrmode.png')}
                                 x={2} y={74}/>

                        <Rect x={89} y={137} w={72} h={31} onClick={this.changeToDual}/>
                        <Rect x={164} y={137} w={32} h={31} onClick={this.toggleDual}/>
                        <Rect x={199} y={137} w={66} h={31} onClick={this.changeToSingle}/>
                    </div>
                </div>
            </Context.Provider>
        )
    }
}

export default mobx(DistopikVCAStereo)
