import React from 'react'
import styled from 'styled-components'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { observable, action } from 'mobx'

import Metering from './Metering'
import MeteringGraph from './MeteringGraph'

import { mobx } from '../utils'
import Store from '../store'

class Sidebar extends React.Component<{ store: Store; uiScale: number }> {
    isBrowserFirefox = this.props.store.player.checkIfBrowserFirefox()
    render() {
        const {
            uiScale,
            store: {
                player: { lufsEnabled, setLufsEnabled },
            },
        } = this.props
        const width = 270 * uiScale
        const historyHeight = 330 * uiScale

        console.log(uiScale)
        return (
            <SidebarStyle style={{ width: `${width}px` }}>
                <div className="sidebarFixed">
                    <Metering uiScale={uiScale} />
                    <GraphWrapper width={width}>
                        <FormControlLabel style={{ height: '30px', transform: `scale(${uiScale * 2})`, transformOrigin: 'left' }} control={<Switch checked={lufsEnabled} onChange={(_, checked) => setLufsEnabled(checked)} />} label="History" />
                        {lufsEnabled ? <MeteringGraph width={width} height={historyHeight} /> : <DisabledGraphWrapper height={historyHeight}>History meter {this.isBrowserFirefox ? 'disabled' : 'works best in Firefox'}</DisabledGraphWrapper>}
                    </GraphWrapper>
                </div>
            </SidebarStyle>
        )
    }
}

export default mobx(Sidebar) as any

const SidebarStyle = styled.div`
    position: fixed;
    top: 68px;
    left: 0;
    .sidebarFixed {
        margin-top: 5px;
        max-height: calc(100% - 120px);
    }
    @media (max-width: 650px) {
        display: none;
    }
`

const GraphWrapper = styled.div<{ width: number }>`
    width: ${p => p.width}px;
`

const DisabledGraphWrapper = styled.div<{ height: number }>`
    color: rgba(255, 255, 255, 0.8);
    font-family: 'IBM Plex Sans', 'Helvetica', 'Arial', sans-serif;
    font-size: 0.8em;
    background-color: #dcdcdc1a;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px;
`
