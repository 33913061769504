import React from 'react'
import moment from 'moment'
import { action, observable } from 'mobx'
import { Button, Typography, Table, TableHead, TableCell, TableRow, TableBody, TableSortLabel, LinearProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import BounceIcon from '@material-ui/icons/Send'
import RemoveIcon from '@material-ui/icons/Remove'
import differenceInSeconds from 'date-fns/differenceInSeconds'

import { mobx, HSpace } from '../utils'
import Store from '../store/index'

import { Bounce, CompletedBounce } from '../store/bounces'

const Cell = TableCell

class SetupOrder extends React.Component<{ store: Store }> {
    render() {
        return <div />
    }
}

interface BounceLineProps {
    id: string
    bouncing: boolean
    bounce: (id: string) => void
    removeBounce: (id: string) => void
}

const DownloadBounceLine = mobx(
    class extends React.Component<CompletedBounce & { store: Store }> {
        render() {
            return (
                <TableRow key={this.props.id}>
                    <Cell key="title" role="checkbox">
                        {this.props.title}
                    </Cell>
                    <Cell key="title">{this.props.sampleRate}</Cell>
                    <Cell key="duration">{this.props.duration}</Cell>
                    <Cell key="tools">
                        <Button variant="text" size="small" style={{ color: '#1faa00' }} onClick={() => this.props.store.downloadMediaFileOriginal((this.props as any).mediaId)}>
                            <DownloadIcon /> <HSpace /> Download
                        </Button>
                    </Cell>
                </TableRow>
            )
        }
    },
)

class BounceLineComponent extends React.Component<Bounce & { store: Store } & BounceLineProps> {
    @observable
    possibleToBounce = true

    timer: any = null

    componentDidMount() {
        this.recomputePossible()
        this.timer = setInterval(this.recomputePossible, 500)
    }

    componentWillUnmount() {
        if (this.timer != null) {
            clearInterval(this.timer)
        }
    }

    @action.bound
    recomputePossible = () => {
        const { duration, store } = this.props
        const factor = duration < 20 ? 1.5 : 1.0

        if (store.player.session) {
            const timeLeft = differenceInSeconds(store.player.session.to, new Date())
            this.possibleToBounce = this.possibleToBounce = this.props.duration * factor <= timeLeft
        } else {
            this.possibleToBounce = false
        }
    }

    render() {
        return (
            <TableRow key={this.props.id}>
                <Cell key="title" role="checkbox">
                    {this.props.title}
                </Cell>
                <Cell key="sampleRate">{this.props.sampleRate}</Cell>
                <Cell key="duration">{moment(this.props.duration * 1000).format('mm:ss')}</Cell>
                <Cell key="tools">
                    <Button variant="text" size="small" onClick={() => this.props.removeBounce(this.props.id)}>
                        <RemoveIcon />
                        <HSpace />
                        Remove
                    </Button>
                    <Button variant="text" style={{ color: '#1faa00' }} size="small" disabled={this.props.bouncing && !this.possibleToBounce} onClick={() => this.props.bounce(this.props.id)}>
                        <BounceIcon />
                        <HSpace />
                        Bounce
                    </Button>
                </Cell>
            </TableRow>
        )
    }
}

const BounceLine = mobx(BounceLineComponent) as any

class BounceListDialog extends React.Component<{ store: Store; closeDialog: any }> {
    timer: any

    componentDidMount(): void {
        this.timer = setInterval(this.updateBounceStatus, 2500)
    }

    componentWillUnmount(): void {
        clearInterval(this.timer)
    }

    updateBounceStatus = () => {
        const { store } = this.props

        if (store.lastBounceAttemptStatus != null && store.lastBounceAttemptStatus.status == 2) {
            store.executeBounce(store.lastBounceAttemptStatus.id).catch(store.applicationError)
        }
    }

    render() {
        const { store } = this.props
        const showRewind = store.lastBounceAttemptStatus ? store.lastBounceAttemptStatus.status == 2 : false
        const isFinishing = store.currentBounce ? store.currentBounce.progress >= 98 : false

        return (
            <Dialog open={true} onClose={() => {}} aria-labelledby="save-dialog-title" maxWidth={'lg'}>
                <DialogTitle>{showRewind ? <span>Waiting for rewind</span> : store.completedBounces.length && !store.isDownloading ? <span>Downloads</span> : <span>Bounces</span>}</DialogTitle>
                <DialogContent>
                    {store.isDownloading ? (
                        <React.Fragment>
                            <Typography variant="body1" style={{ paddingTop: '15px' }}>
                                {store.currentBounce ? store.currentBounce.title : 'Waiting'}
                                {isFinishing ? ' ... Finishing' : undefined}
                            </Typography>
                            {showRewind || isFinishing ? <LinearProgress color="secondary" style={{ marginTop: '15px' }} /> : <LinearProgress variant="determinate" color="secondary" value={store.currentBounce ? store.currentBounce.progress : 0} style={{ marginTop: '15px' }} />}
                            <div style={{ height: '35px' }} />
                            <Typography variant="body1" style={{ paddingTop: '35px' }}>
                                Do not leave this page until the process is completed.
                            </Typography>
                            <Typography variant="body1">Leaving the page will break the process!</Typography>
                        </React.Fragment>
                    ) : (
                        undefined
                    )}
                    {store.completedBounces.length && !store.isDownloading ? (
                        <React.Fragment>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <Cell key="title" role="checkbox">
                                            <TableSortLabel>Title</TableSortLabel>
                                        </Cell>
                                        <Cell key="sampleRate">
                                            <TableSortLabel>Sample Rate</TableSortLabel>
                                        </Cell>
                                        <Cell key="duration">
                                            <TableSortLabel>Duration</TableSortLabel>
                                        </Cell>
                                        <Cell key="tools">
                                            <TableSortLabel />
                                        </Cell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {store.completedBounces.map(b => (
                                        <DownloadBounceLine {...b} />
                                    ))}
                                </TableBody>
                            </Table>
                            <Typography variant="body1">You can download these files later from the Files tab in the booking section.</Typography>
                        </React.Fragment>
                    ) : (
                        undefined
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.props.closeDialog()} variant={'outlined'}>
                        <CancelIcon />
                        <HSpace />
                        {store.completedBounces.length ? 'Close' : 'Cancel'}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default mobx(BounceListDialog)
