import React from 'react'

import { ModelImageKnob, ModelToggleKnob, Context } from '../ImageKnob'
import { px, mobx } from '../../utils'
import Store from '../../store'
import { toJS } from 'mobx'
import Overlay from '../Overlay'

export default mobx(
    class ElysiaKaracter extends React.Component<{ id: string; store: Store; uiScale: number }> {
        static renderTitle() {
            return 'elysia karacter'
        }

        render() {
            const { uiScale = 0.5, store, id } = this.props
            const { api } = store

            const { session } = this.props.store.player
            if (session) {
                const { model } = session.devices[this.props.id]
                model.setValue('linkPairs', toJS(store.slots[store.selectedSlotIndex].settings[id].linkPairs), true)
            }

            return (
                <Context.Provider value={{ api, id } as any}>
                    <div
                        style={{
                            transform: `scale(${uiScale}, ${uiScale})`,
                            transformOrigin: 'left top',
                            margin: 0,
                            padding: 0,
                            width: px(2828 * uiScale),
                            height: px(259 * uiScale),
                        }}
                        onClick={e => e.preventDefault()}
                        onDoubleClick={e => e.preventDefault()}
                    >
                        <div
                            style={{
                                width: px(2828),
                                height: px(259),
                                margin: 0,
                                padding: 0,
                                background: `url(${require('./images/karacter-bg.png')})`,
                                backgroundAttachment: 'local',
                                backgroundSize: 'stretch',
                                boxShadow: 'black 0 4px 12px',
                                filter: store.globalBypass ? 'opacity(0.5)' : '',
                            }}
                            onClick={e => e.preventDefault()}
                            onDoubleClick={e => e.preventDefault()}
                        >
                            {/* ---- left knobs ---- */}

                            <ModelImageKnob x={318} y={73} nf={41} imageUrl={require('./images/left_drive.png')}
                                            name={'leftDrive'} initial={0.5}/>

                            <ModelImageKnob x={507} y={73} nf={41} imageUrl={require('./images/left_color.png')}
                                            name={'leftColor'}/>

                            <ModelImageKnob x={697} y={73} nf={41} imageUrl={require('./images/left_gain.png')}
                                            name={'leftGain'} initial={0.5}/>

                            <ModelImageKnob x={885} y={73} nf={41} imageUrl={require('./images/left_mix.png')}
                                            name={'leftMix'} initial={1}/>
                            {/* ---- right knobs ---- */}
                            <ModelImageKnob x={1830} y={73} nf={41} imageUrl={require('./images/right_drive.png')}
                                            name={'rightDrive'} initial={0.5}/>

                            <ModelImageKnob x={2018} y={73} nf={41} imageUrl={require('./images/right_color.png')}
                                            name={'rightColor'}/>

                            <ModelImageKnob x={2207} y={73} nf={41} imageUrl={require('./images/right_gain.png')}
                                            name={'rightGain'} initial={0.5}/>

                            <ModelImageKnob x={2397} y={73} nf={41} imageUrl={require('./images/right_mix.png')}
                                            name={'rightMix'} initial={1}/>

                            {/* ---- left toggles ---- */}
                            <ModelToggleKnob x={1066} y={108} name={'leftOn'}
                                             imageUrl={require('./images/left_active.png')}/>

                            <ModelToggleKnob x={1137} y={108} name={'leftFET'}
                                             imageUrl={require('./images/left_eq.png')}/>

                            <ModelToggleKnob x={1208} y={108} name={'leftTurbo'}
                                             imageUrl={require('./images/left_full.png')}/>

                            <ModelToggleKnob x={1279} y={108} name={'linkPairs'}
                                             imageUrl={require('./images/left_link.png')}/>

                            {/* ---- right toggles ---- */}
                            <ModelToggleKnob x={1484} y={108} name={'rightOn'}
                                             imageUrl={require('./images/right_active.png')}/>

                            <ModelToggleKnob x={1555} y={108} name={'rightFET'}
                                             imageUrl={require('./images/right_eq.png')}/>

                            <ModelToggleKnob x={1626} y={108} name={'rightTurbo'}
                                             imageUrl={require('./images/right_full.png')}/>

                            <ModelToggleKnob x={1697} y={108} name={'midSide'}
                                             imageUrl={require('./images/right_auto.png')}/>

                            <Overlay imageUrl={require('./images/elysia_on.jpg')} x={1348} y={65}/>
                        </div>
                    </div>
                </Context.Provider>
            )
        }
    },
)
