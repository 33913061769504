import React from 'react'

import {ModelImageKnob, ModelToggleKnob, Context} from '../ImageKnob'
import {px, mobx} from '../../utils'
import Store from '../../store'
import Needle from "../Needle";
import Overlay from "../Overlay";

const deflect = 0.758131701

export default mobx(
    class ElysiaNvelope extends React.Component<{ id: string; store: Store; uiScale: number }> {
        needle: Needle | null = null;

        static renderTitle() {
            return 'Tierra Gravity'
        }

        componentDidMount() {
            this.props.store.on('playingAnimation', this.updateAnimation)
        }

        componentWillUnmount() {
            this.props.store.off('playingAnimation', this.updateAnimation)
        }

        updateAnimation = () => {
            const {measurements, position: pos} = this.props.store.player
            const m = measurements[`${this.props.id}.gainReduction`]

            try {
                let val = m.getValueWithSmoothing(pos, 2) * 1.13

                if (this.needle) {
                    this.needle.position = val
                }
            } catch (e) {
            }
        }

        render() {
            const {uiScale = 0.5, store, id} = this.props
            const {api} = store

            return (
                <Context.Provider value={{api, id} as any}>
                    <div
                        style={{
                            transform: `scale(${uiScale}, ${uiScale})`,
                            transformOrigin: 'left top',
                            margin: 0,
                            padding: 0,
                            width: px(2828 * uiScale),
                            height: px(259 * uiScale),
                        }}
                        onClick={e => e.preventDefault()}
                        onDoubleClick={e => e.preventDefault()}
                    >
                        <div
                            style={{
                                width: px(2828),
                                height: px(258),
                                margin: 0,
                                padding: 0,
                                background: `url(${require('./images/bg.png')})`,
                                backgroundAttachment: 'local',
                                backgroundSize: 'stretch',
                                boxShadow: 'black 0 4px 12px',
                                filter: store.globalBypass ? 'opacity(0.5)' : '',
                            }}
                            onClick={e => e.preventDefault()}
                            onDoubleClick={e => e.preventDefault()}
                        >
                            {/* ---- left knobs ---- */}
                            <ModelImageKnob x={366} y={64} nf={61} imageUrl={require('./images/threshold.png')}
                                            name={'threshold'} initial={0.5}/>

                            <ModelImageKnob x={668} y={64} nf={3} initial={0} imageUrl={require('./images/ratio.png')}
                                            name={'ratio'}/>

                            <ModelImageKnob x={954} y={64} nf={6} imageUrl={require('./images/attack.png')}
                                            name={'attack'} initial={1}/>

                            <ModelImageKnob x={1241} y={64} nf={5} imageUrl={require('./images/release.png')}
                                            name={'release'} initial={0}/>

                            <ModelImageKnob x={1524} y={64} nf={61} imageUrl={require('./images/gain.png')}
                                            name={'gain'} initial={0.5}/>


                            {/* ---- left toggles ---- */}
                            <ModelToggleKnob x={1770} y={47} name={'bypass'}
                                             imageUrl={require('./images/bypass.png')}/>

                            <ModelToggleKnob x={1770} y={157} name={'highpass'}
                                             imageUrl={require('./images/hpf.png')}/>

                            <Needle ref={needle => this.needle = needle} x={2186} y={207} length={150} lineWidth={2}
                                    startAngle={-deflect} endAngle={deflect} lineStyle={"white"}/>

                            <Overlay imageUrl={require('./images/vumask.png')} x={2012} y={0} style={{zIndex: 900}}/>
                        </div>
                    </div>
                </Context.Provider>
            )
        }
    },
)
