import React from 'react'
import styled from 'styled-components'
import {action, observable, toJS} from 'mobx'
import flatten from 'lodash/flatten'
import HHMMSS from 'hh-mm-ss'

import {mobx, px, dbToFactor} from '../../utils'
import Store from '../../store'
import {Context, ModelImageKnob, ModelToggleKnob} from '../ImageKnob'
import '../fonts/LED.css'

import bgImg from './img/bg.png'
import bypassLImg from './img/bypass_l.png'
import bypassRImg from './img/bypass_r.png'
import inputLImg from './img/input_l.png'
import inputRImg from './img/input_r.png'
import outputLImg from './img/output_l.png'
import outputRImg from './img/output_r.png'
import reelsImg from './img/reels.png'
import vuGlassImg from './img/vu_glass.png'
import NoninteractiveAnimation from '../NonInteractiveAnimation'
import Needle from '../Needle'
import Overlay from '../Overlay'

const AbsoluteSpan = styled.span<{ x: number; y: number }>`
    font-family: 'Digitopik2';
    font-size: 26px;
    color: rgba(255, 0, 0, 0.75);
    position: absolute;
    text-shadow: -2px 2px #00000080;
    left: ${props => px(props.x)};
    top: ${props => px(props.y)};
`

const startAngle = -(Math.PI / 180) * 52
const endAngle = (Math.PI / 180) * 50

interface Props {
    store: Store
    id: string
    uiScale?: number
}

class Studer extends React.Component<Props> {
    @observable
    private position: number = 0

    private timer: any
    private playState: number = 0

    volumeControllerId?: string

    leftInput: Needle | null = null
    rightInput: Needle | null = null
    leftOutput: Needle | null = null
    rightOutput: Needle | null = null

    static renderTitle() {
        return 'Studer A812'
    }

    constructor(props: Props) {
        super(props)
        this.volumeControllerId = this.getMyVolumeControlId()
    }

    componentDidMount() {
        this.timer = setInterval(this.updateLights, 200)

        this.props.store.on('playingAnimation', this.updateAnimation)
        if (this.props.store.currentSlotSettings && !this.props.store.currentSlotSettings["distopik/volume2_1"]) {
            console.log('Will setSlotSettings')
            this.props.store.setSlotSettings([
                {processor: 'distopik/volume2_1', parameter: 'leftInputGain', value: 0.705},
                {processor: 'distopik/volume2_1', parameter: 'rightInputGain', value: 0.705},
                {processor: 'distopik/volume2_1', parameter: 'leftOutputGain', value: 0.705},
                {processor: 'distopik/volume2_1', parameter: 'rightOutputGain', value: 0.705},
                {processor: 'distopik/volume2_1', parameter: 'linkPairs', value: 1}
            ], true, true)
        }
    }

    componentWillUnmount() {
        if (this.timer !== null) {
            clearInterval(this.timer)
            this.timer = undefined
        }

        this.props.store.off('playingAnimation', this.updateAnimation)
    }

    updateNeedle(id: string, needle: Needle | null, offset: number, factor: number) {
        if (!needle) {
            return
        }

        const {measurements, position: pos} = this.props.store.player
        const m = measurements[`insert_1.${id}`]

        if (m) {
            // add 18dB, scale to 68%
            needle.position = Math.min(1, m.getValueWithSmoothing(pos + offset, 12) * 0.68181818181 * 7.943282347242816 * factor)
        }
    }

    updateAnimation = () => {
        const {session} = this.props.store.player
        let leftFactor = 1,
            rightFactor = 1
        if (session && this.volumeControllerId) {
            const dev = session.devices[this.volumeControllerId]
            leftFactor = dbToFactor(dev.model.getValue('leftInputGain') * 30 - 21)
            rightFactor = dbToFactor(dev.model.getValue('rightInputGain') * 30 - 21)
        }

        this.updateNeedle('input_rms_0', this.leftInput, 0.125, leftFactor)
        this.updateNeedle('input_rms_1', this.rightInput, 0.125, rightFactor)
        this.updateNeedle('output_rms_0', this.leftOutput, 0.0, 1)
        this.updateNeedle('output_rms_1', this.rightOutput, 0.0, 1)
    }

    getMyVolumeControlId = () => {
        const {session} = this.props.store.player

        if (session) {
            const deviceIndex = session.deviceOrder.findIndex(d => {
                const name = flatten([d])[0]
                return name === this.props.id
            })
            if (deviceIndex > 0) {
                const previousIndex = deviceIndex - 1
                const previousId = session.deviceOrder[previousIndex]
                if (typeof previousId === 'string') {
                    const previousDevice = session.devices[previousId]
                    if (previousDevice.type === 'distopik/volume2') {
                        return previousId
                    }
                }
            }
        }
    }

    @action.bound
    updateLights = () => {
        let m = this.props.store.player.measurements[`${this.props.id}.playState`]
        if (m) {
            const {position} = this.props.store.player
            const value = m.getValue(position)
            if (value != null) {
                this.playState = Math.round(value)
            }
        }

        m = this.props.store.player.measurements[`${this.props.id}.position`]
        if (m) {
            const {position} = this.props.store.player
            const value = m.getValue(position)
            if (value != null) {
                this.position = value
            }
        }
    }

    render() {
        const {uiScale = 0.5, store, id} = this.props
        const {api, isPlaying} = store

        return (
            <Context.Provider value={{api, id} as any}>
                <div
                    style={{
                        transform: `scale(${uiScale}, ${uiScale})`,
                        transformOrigin: 'left top',
                        margin: 0,
                        padding: 0,
                        width: px(1414 * uiScale),
                        height: px(874 * uiScale),
                    }}
                    onClick={e => e.preventDefault()}
                    onDoubleClick={e => e.preventDefault()}
                >
                    <div
                        style={{
                            width: px(1414),
                            height: px(874),
                            transformOrigin: 'left top',
                            margin: 0,
                            padding: 0,
                            background: `url(${bgImg})`,
                            backgroundAttachment: 'local',
                            backgroundSize: 'stretch',
                            boxShadow: 'black 0 4px 12px',
                            filter: store.globalBypass ? 'opacity(0.5)' : '',
                        }}
                        onClick={e => e.preventDefault()}
                        onDoubleClick={e => e.preventDefault()}
                    >
                        {/* volume controller context */}
                        {this.volumeControllerId !== undefined && (
                            <Context.Provider value={{api, id: this.volumeControllerId} as any}>
                                <Needle startAngle={startAngle} endAngle={endAngle} ref={n => (this.leftInput = n)}
                                        x={174 + 114} y={112 + 114} lineWidth={2} length={114} height={81}/>
                                <Needle startAngle={startAngle} endAngle={endAngle} ref={n => (this.rightInput = n)}
                                        x={430 + 106} y={112 + 114} lineWidth={2} length={114} height={81}/>
                                <Needle startAngle={startAngle} endAngle={endAngle} ref={n => (this.leftOutput = n)}
                                        x={748 + 106} y={112 + 114} lineWidth={2} length={114} height={81}/>
                                <Needle startAngle={startAngle} endAngle={endAngle} ref={n => (this.rightOutput = n)}
                                        x={1004 + 106} y={112 + 114} lineWidth={2} length={114} height={81}/>

                                <ModelImageKnob style={{zIndex: 10}} name={'leftInputGain'} imageUrl={inputLImg} nf={61}
                                                x={238} y={241} initial={0.705}/>
                                <ModelImageKnob style={{zIndex: 10}} name={'rightInputGain'} imageUrl={inputRImg}
                                                nf={61} x={509} y={241} initial={0.705}/>
                                <ModelImageKnob style={{zIndex: 10}} name={'leftOutputGain'} imageUrl={outputLImg}
                                                nf={61} x={818} y={241} initial={0.705}/>
                                <ModelImageKnob style={{zIndex: 10}} name={'rightOutputGain'} imageUrl={outputRImg}
                                                nf={61} x={1087} y={241} initial={0.705}/>

                                <ModelToggleKnob name={'linkPairs'} imageUrl={bypassLImg} x={386} y={247}/>
                                <ModelToggleKnob name={'linkPairs'} imageUrl={bypassRImg} x={966} y={247}/>
                            </Context.Provider>
                        )}

                        <Overlay imageUrl={vuGlassImg} x={169} y={63}/>

                        <NoninteractiveAnimation animate={isPlaying} x={10} y={387} nf={48} imageUrl={reelsImg}
                                                 rewinding={!isPlaying && this.playState == 0x89}/>

                        <AbsoluteSpan x={652} y={312}>
                            {HHMMSS.fromS(Math.round(45 * 60 - this.position), 'hh:mm:ss').replace(/[:]/g, '.')}
                        </AbsoluteSpan>
                    </div>
                </div>
            </Context.Provider>
        )
    }
}

export default mobx(Studer)
