import React from 'react'
import styled from 'styled-components'

import { mobx } from '../utils'
import Store from '../store'

type State = {
    timeUntilSessionEnd?: number
    extending: boolean
    error?: string
}

export default mobx(
    class extends React.Component<{ store: Store; onTimeChange: Function; hidden: boolean; right: boolean; margin: boolean }, State> {
        timer: any = null
        state = { timeUntilSessionEnd: undefined, extending: false, error: null } as any

        componentDidMount() {
            this.timer = setInterval(this.checkTimeLeft, 100)
        }

        componentWillUnmount() {
            if (this.timer != null) {
                clearInterval(this.timer)
            }
        }

        checkTimeLeft = () => {
            const { timeUntilSessionEnd, warned60SecondsBefore } = this.props.store
            if (timeUntilSessionEnd !== this.state.timeUntilSessionEnd) {
                this.props.onTimeChange && this.props.onTimeChange()

                if (!warned60SecondsBefore) {
                    this.warnForDownload(timeUntilSessionEnd)
                }

                this.setState({ timeUntilSessionEnd })
            }
        }

        warnForDownload = (secondsLeft: any) => {
            const { store } = this.props as any
            if (store.currentSlotMedia && Math.floor(store.currentSlotMedia.duration) === store.timeUntilSessionEnd - 60) {
                store.warned60SecondsBefore = true
                store.warning = 'There\'s only 20 seconds until you won\'t be able to make a download in this session!'
            }
        }

        handleExtend = async () => {
            try {
                this.setState({ extending: true })
                await this.props.store.extendSession()
            } catch (e) {
                this.setState({
                    error: 'could not extend, someone has already taken the next time slot or you do not have enough MAT available',
                })
            } finally {
                this.setState({ extending: false })
            }
        }

        secondsToString = (seconds: any) => {
            const zeroPad = (x: any, l = 2) => (('' + x) as any).padStart(l, '0')

            const sec = ((seconds * 1000) / 1000) | 0
            const min = (sec / 60) | 0

            return `${zeroPad(min)}:${zeroPad(sec % 60)}`
        }

        clearError = () => {
            this.setState({ error: null } as any)
        }

        render() {
            const { store, hidden, right, margin } = this.props
            const { timeUntilSessionEnd } = this.state

            return (
                <CountdownContainer hidden={hidden} right={right} visible={true} margin={margin}>
                    <table>
                        <tbody>
                        <tr>
                            <td style={{ textAlign: 'right', color: '#999' }}>Latency</td>
                            <td style={{ textAlign: 'left' }}>{Math.round(store.latency)} ms</td>
                            <td style={{ textAlign: 'right', color: '#999' }}>Session</td>
                            <td style={{ textAlign: 'left' }}>{store.player.session && store.player.session.title ? `${store.player.session.title}` : undefined}</td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: 'right', color: '#999' }}>Tokens</td>
                            <td style={{ textAlign: 'left' }}>{store.owner.totalTokens} MAT</td>
                            <td style={{ textAlign: 'right', color: '#999' }}>Time left</td>
                            <td style={{ textAlign: 'left' }}>{timeUntilSessionEnd && ` ${this.secondsToString(timeUntilSessionEnd)}`}</td>
                        </tr>
                        </tbody>
                    </table>

                    <div className={`extendContainer${timeUntilSessionEnd && timeUntilSessionEnd <= 180 ? ' visible' : ''}`}>
                        <button className={`extendButton${timeUntilSessionEnd && timeUntilSessionEnd <= 180 ? ' visible' : ''}`}
                                onClick={this.handleExtend}>
                            extend session
                        </button>
                        {this.state.error && (
                            <div>
                                {this.state.error}
                                <button onClick={this.clearError}>Dismiss</button>
                            </div>
                        )}
                    </div>
                </CountdownContainer>
            )
        }
    },
) as any

const CountdownContainer = styled.div<{ margin: any; right: any; hidden: boolean; visible: boolean }>`
  ${({ margin }: any) => margin && 'margin: 16px 0px;'}
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  ${({ right }: any) => right && 'justify-content: flex-end;'} ${({ right }: any) => !right && 'padding: 0 10px 0 0;'}
  text-align: center;
  font-family: 'IBM Plex Sans', 'Helvetica', 'Arial', sans-serif;
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.8em;

  .timeLeft {
    ${({ right, visible }: any) => (!right || visible) && 'padding: 0 5px 0 0;'};
  }
  .extendContainer {
    max-width: 0px;
    overflow: hidden;
    transition: 0.5s;
    .extendButton {
      transition: 0.5s;
      transition-delay: 0s;
      border-radius: 0;
      white-space: nowrap;
      opacity: 0;
      background: none;
      color: rgba(255, 255, 255, 0.8);
      cursor: pointer;
      &.visible {
        opacity: 1;
        transition-delay: 0.5s;
      }
      &:hover {
        transition-delay: 0s;
        transition: 0.25s;
        background: rgb(80, 80, 80);
        color: rgba(255, 255, 255, 1);
      }
    }
    &.visible {
      max-width: 150px;
      transition: 1s;
    }
  }
`
