import React from 'react'

import { Context } from '../ImageKnob'
import { mobx, px } from '../../utils'
import Store from '../../store'
import { toJS } from 'mobx'
import LA2AUnit from './unit'

export default mobx(
    class DLA2A extends React.Component<{ id: string; uiScale: number; store: Store }> {
        static renderTitle() {
            return 'Dual LA-2A'
        }

        render() {
            let { uiScale = 0.5, store, id } = this.props
            const { api } = store

            const { session } = this.props.store.player
            const isStereo = !toJS(store.slots[store.selectedSlotIndex].settings[id].linkPairs)
            const bypassed = store.globalBypass
            const numUnits = isStereo ? 2 : 1

            return (
                <Context.Provider value={{ api, id } as any}>
                    <div
                        style={{
                            transform: `scale(${uiScale}, ${uiScale})`,
                            transformOrigin: 'left top',
                            margin: 0,
                            padding: 0,
                            width: px(2828 * uiScale),
                            height: px(671 * numUnits * uiScale),
                        }}
                        onClick={e => e.preventDefault()}
                        onDoubleClick={e => e.preventDefault()}
                    >
                        <LA2AUnit key={'la2aLeft'} id={id} uiScale={uiScale} prefix={'left'}/>
                        {isStereo ?
                            <div style={{ position: 'relative', top: px(0) }}>
                                <LA2AUnit key={'la2aRight'} id={id} uiScale={uiScale} prefix={'right'}/>
                            </div> : undefined}
                    </div>
                </Context.Provider>
            )
        }
    },
)
