import * as React from 'react'
import {
    AppBar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Radio,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    Toolbar,
    Typography,
} from '@material-ui/core'

import SaveIcon from '@material-ui/icons/Save'
import LoadIcon from '@material-ui/icons/Inbox'
import CancelIcon from '@material-ui/icons/Cancel'
import HelpIcon from '@material-ui/icons/HelpOutlined'

import find from 'lodash/find'
import assign from 'lodash/assign'

import ProductLogo from './mixanalog'
import Store from '../store'
import { Preset } from '../api'
import { HSpace, mobx, px } from '../utils'
import Countdown from '../play/Countdown'
import MouseOverPopover from '../settings/MouseOverPopover'
import { action, observable } from 'mobx'
import ActionBarInsert from './actionbar'
import { track } from '../store/analytics'

class HeaderClass extends React.Component<{ store: Store; children?: React.ReactNode }> {
    @observable
    showSaveDialog = false
    @observable
    showRecallDialog = false
    @observable
    savePresetTitle = ''
    @observable
    presets: Array<Preset> = []
    @observable
    selectedPresetId: string = ''

    @action.bound
    toggleSaveDialog = () => {
        this.showSaveDialog = !this.showSaveDialog
        this.props.store.changeDialogVisible(this.showSaveDialog)

        if (this.showSaveDialog && this.props.store.player.session) {
            track('Save Dialog Shown', { productId: this.props.store.player.session.product })
        }
    }

    @action.bound
    toggleRecallDialog = () => {
        this.showRecallDialog = !this.showRecallDialog
        this.props.store.changeDialogVisible(this.showRecallDialog)

        if (this.showRecallDialog) {
            this.updatePresetList()

            if (this.props.store.player.session) {
                track('Recall Dialog Shown', { productId: this.props.store.player.session.product })
            }
        }
    }

    @action.bound
    updatePresetList = () => {
        this.props.store.api.listPresets((this.props as any).store.player.session.product).then(this.setPresets, this.toggleRecallDialog)
    }

    @action.bound
    deletePreset = (id: any) => {
        if (this.props.store.player.session) {
            track('Deleted Preset', { productId: this.props.store.player.session.product })
        }

        this.props.store.api.deletePreset(id).then(this.updatePresetList, console.error)
    }

    @action.bound
    setPresets = (presets: Array<Preset>) => {
        this.presets = presets
    }

    @action.bound
    savePreset = () => {
        const { store } = this.props

        if (store.isSlotIndexPopulated(store.selectedSlotIndex)) {
            const { settings } = store.slots[store.selectedSlotIndex]

            store.api.savePreset(this.savePresetTitle, (this.props as any).store.player.session.product, settings).then(this.afterSaveSuccessful, this.toggleSaveDialog)
        } else {
            this.toggleSaveDialog()
        }
    }

    @action.bound
    afterSaveSuccessful = (p: Preset) => {
        const { store } = this.props

        const slot = store.slots[store.selectedSlotIndex]
        if (slot) {
            slot.presetId = p.id
        }

        if (this.props.store.player.session) {
            track('Saved Preset', { productId: this.props.store.player.session.product, title: p.title })
        }

        this.toggleSaveDialog()
    }

    @action.bound
    selectPresetId = (id: any) => {
        this.selectedPresetId = id
    }

    @action.bound
    recallPreset = async () => {
        try {
            const { store } = this.props
            const preset = find(this.presets, { id: this.selectedPresetId })
            if (preset && store.isSlotIndexPopulated(store.selectedSlotIndex)) {
                const slot = store.slots[store.selectedSlotIndex]
                assign(slot.settings, preset.settings)

                await store.storeSlotToServerAndRefresh()
            }
        } finally {
            this.toggleRecallDialog()
        }
    }

    render() {
        const { children } = this.props

        return (
            <div>
                <AppBar position="sticky" color="primary" style={{ zIndex: 100 }}>
                    <Toolbar style={{ display: 'flex', padding: 0 }}>
                        <ProductLogo/>
                        <ActionBarInsert toggleSaveDialog={this.toggleSaveDialog} toggleRecallDialog={this.toggleRecallDialog}/>
                        <Countdown hidden={false}/>
                    </Toolbar>
                </AppBar>
                {children}
                <Dialog open={this.showSaveDialog} onClose={this.toggleSaveDialog} aria-labelledby="save-dialog-title" fullWidth>
                    <DialogTitle id="save-dialog-title">
                        Save Preset
                        <HSpace/>
                        <MouseOverPopover icon={HelpIcon}>
                            <Typography variant={'body1'}>By clicking save below, you will save the settings of all gear on this slot for
                                later recall. If you input a name that already exists, the preset under that name will be
                                overwritten.</Typography>
                        </MouseOverPopover>
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Preset name"
                            type="text"
                            fullWidth
                            style={{ marginTop: px(15) }}
                            onKeyPress={ev => {
                                if (ev.key === 'Enter') {
                                    ev.preventDefault()
                                    this.savePreset()
                                }
                            }}
                            onChange={e => (this.savePresetTitle = e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.toggleSaveDialog} variant={'outlined'}>
                            <CancelIcon/>
                            <HSpace/>
                            Cancel
                        </Button>
                        <Button onClick={this.savePreset} variant={'contained'}>
                            <SaveIcon/>
                            <HSpace/>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.showRecallDialog} onClose={this.toggleRecallDialog} aria-labelledby="recall-dialog-title" fullWidth>
                    <DialogTitle id="recall-dialog-title">Recall Preset</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Select the preset which you would like to instantly recall into the current
                            slot.</DialogContentText>
                        <Table>
                            <TableBody>
                                {this.presets.map(p => (
                                    <TableRow key={p.id}>
                                        <TableCell padding="checkbox">
                                            <Radio checked={this.selectedPresetId === p.id} onChange={() => this.selectPresetId(p.id)}/>
                                        </TableCell>
                                        <TableCell padding="default">{p.title}</TableCell>
                                        <TableCell padding="default">
                                            {p.ownerId ? <Button onClick={() => this.deletePreset(p.id)}>Delete</Button> : undefined}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.toggleRecallDialog} variant={'outlined'}>
                            <CancelIcon/>
                            <HSpace/>
                            Cancel
                        </Button>
                        <Button onClick={this.recallPreset} variant={'contained'}>
                            <LoadIcon/>
                            <HSpace/>
                            Load
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default mobx(HeaderClass)
