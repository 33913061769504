import React from 'react'
import { Typography } from '@material-ui/core'

import './styles.scss'
import { mobx, px } from '../../utils'
import { Context, ModelImageKnob } from '../ImageKnob'
import Store from '../../store'
import { CnbFeatures } from '../features'
import DraggableButton from '../../components/componentHeader/DraggableButton'

const imageUrl = require('../distopik_moslim/images/leftOutputGain.png')

interface Props {
    store: Store
    id: string
    uiScale: number
    enabledFeatures: Array<CnbFeatures>
}

class CNB extends React.Component<Props, { msEnable: boolean }> {
    state = { msEnable: false }

    componentDidMount(): void {
        const settings = this.props.store.currentSlotSettings
        if (settings) {
            this.onMidSideChange(settings[this.props.id].midSideEnabled)
        }

        this.props.store.on(`change.${this.props.id}.midSideEnabled`, this.onMidSideChange)
    }

    componentWillUnmount(): void {
        this.props.store.off(`change.${this.props.id}.midSideEnabled`, this.onMidSideChange)
    }

    onMidSideChange = (value: any) => {
        console.log(value)
        this.setState({ msEnable: !!value })
    }

    handleMidSideClick = (midSideEnable?: boolean) => {
        this.props.store.setSlotSettings(
            [
                {
                    processor: this.props.id,
                    parameter: 'midSideEnabled',
                    value: midSideEnable ? 1 : 0,
                },
            ],
            true,
        )
    }

    render() {
        const { uiScale = 0.5, store, id, enabledFeatures } = this.props
        const { msEnable } = this.state
        const { api } = store
        let x = 0
        let features: any[] = []

        if (enabledFeatures.indexOf('dry/wet') >= 0) {
            features.push(
                <div className="dryWetContainer" key="dryWet">
                    <DraggableButton label="DRY" name="leftDry" />
                    <DraggableButton label="WET" name="leftWet" />
                </div>,
            )
        }

        if (enabledFeatures.indexOf('mid/side') >= 0) {
            features.push(
                <div className="midSideContainer" key="midSide">
                    <button className={`headerButton${msEnable ? '' : ' active'}`} onClick={() => this.handleMidSideClick(false)}>
                        L/R
                    </button>
                    <button className={`headerButton${msEnable ? ' active' : ''}`} onClick={() => this.handleMidSideClick(true)}>
                        M/S
                    </button>
                </div>,
                //     <div key="midside" style={{ position: 'relative', display: 'inline-block' }}>
                //         <Typography inline variant={'caption'}>
                //             M/S
                //         </Typography>
                //         <div style={{ position: 'relative', display: 'inline-block', paddingTop: '3px' }}>
                //             <input
                //                 type={'checkbox'}
                //                 onChange={e => {
                //                     console.log('MS', this.props.id, e.target.checked, 'sending', e.target.checked ? 1 : 0)
                //                     this.props.store.setSlotSettings(
                //                         [
                //                             {
                //                                 processor: this.props.id,
                //                                 parameter: 'midSideEnabled',
                //                                 value: e.target.checked ? 1 : 0 /* this.state.msEnable ? 1 : 0, */,
                //                             },
                //                         ],
                //                         true,
                //                     )
                //                 }}
                //                 checked={this.state.msEnable}
                //             />
                //         </div>
                //     </div>,
            )
        }

        return <Context.Provider value={{ api, id } as any}>{features}</Context.Provider>
    }
}

export default mobx(CNB)
