import React from 'react'
import styled from 'styled-components'
import flatten from 'lodash/flatten'
import { toJS, observable, action } from 'mobx'

import { Context } from './ImageKnob'
import { mobx, nvl } from '../utils'

const slideResolution = 200

const SlidableDisplay = styled.div<any>`
    width: ${({ width }: any) => `${width}px`};
    height: ${({ height }: any) => `${height}px`};
    position: absolute;
    left: ${({ x }: any) => `${x}px`};
    top: ${({ y }: any) => `${y}px`};
    color: ${({ color }) => color || '#3aaa35'};
    text-align: center;
    font-size: ${({ fontSize }: any) => fontSize || '1.9em'};
    line-height: ${({ fontSize }: any) => (fontSize === '1em' ? '1.9em' : '1.3em')};
    font-family: 'IBM Plex Sans', 'Helvetica', 'Arial', sans-serif;
    cursor: ns-resize;
    user-select: none;
    touch-action: none;
`

const doNothing = () => {}

class SliderKnob extends React.Component<any> {
    render() {
        const { width, height, x, y, onMouseDown, onDoubleClick, onTouchStart, onTouchEnd, onTouchMove, display, fontSize, color, ...others } = this.props
        return (
            <SlidableDisplay {...others} width={width} height={height} x={x} y={y} onMouseDown={onMouseDown} onDoubleClick={onDoubleClick} onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd} fontSize={fontSize} color={color}>
                <span style={{ position: 'relative', top: '5px' }}>{display != null ? display.toString() : ''}</span>
            </SlidableDisplay>
        )
    }
}

class SliderKnobParent extends React.Component<any> {
    @observable
    value: number = 0

    @observable
    guiValue: number = 0

    disposed = false
    targets: any[] = []
    prevY: any
    prevX: any

    componentDidMount() {
        const { store, name } = this.props as any
        const { session } = store.player
        this.targets = []

        if (session) {
            for (const id of flatten([toJS((this.props as any).instance)])) {
                for (const name of flatten([toJS((this.props as any).name)])) {
                    const target = session.devices[id].model.parameters[name]

                    this.targets.push(target)
                    store.on(`change.${id}.${name}`, this.changeHandler)
                }
            }

            if (this.props.store.isSlotIndexPopulated(this.props.store.selectedSlotIndex)) {
                const slot = this.props.store.slots[this.props.store.selectedSlotIndex]
                for (const id of flatten([toJS(this.props.instance)])) {
                    for (const name of flatten([toJS(this.props.name)])) {
                        if (slot.settings[id] != null && slot.settings[id][name] != null) {
                            this.changeHandler(slot.settings[id][name], false)
                        }
                    }
                }
            }
        }
    }

    componentWillUnmount() {
        this.disposed = true
        this.targets = []
        this.onMouseUp()

        const { store, name } = this.props as any
        const { session } = store.player
        if (session) {
            for (const id of flatten([toJS((this.props as any).instance)])) {
                for (const name of flatten([toJS((this.props as any).name)])) {
                    store.off(`change.${id}.${name}`, this.changeHandler)
                }
            }
        }
    }

    @action.bound
    changeHandler = (newValue: any, fromGui: boolean) => {
        if (this.value != newValue) {
            this.guiValue = this.modelToGui(newValue)
        }

        this.value = newValue
    }

    onMouseDown = () => {
        this.prevY = null
        window.onmousemove = this.onMouseMove
        window.onmouseup = this.onMouseUp
    }

    onMouseUp = () => {
        window.onmousemove = doNothing
        window.onmouseup = doNothing
    }

    onDoubleClick = (e: MouseEvent) => {
        e.preventDefault()

        for (const id of flatten([(this.props as any).instance])) {
            this.props.store.player.session.devices[id].model.setValue(this.props.name, this.targets[0].getDefaultValue(), false)
        }
    }

    onMouseMove = ({ movementX, movementY }: MouseEvent) => {
        if (movementX !== 0 || movementY !== 0) {
            this.updatePosition(movementX, movementY)
        }
    }

    updatePosition(dx: number, dy: number): void {
        const { innerWidth, innerHeight } = window
        const { isAlt, altFactor = 1 } = this.props
        if (this.props.inverted) {
            dx *= -1
            dy *= -1
        }

        let min = this.targets[0].spec.min || 0
        let max = this.targets[0].spec.max || 1

        if (this.props.guiConstraints) {
            min = this.props.guiConstraints.min
            max = this.props.guiConstraints.max
        }
        const constraintDiff = Math.abs(max - min)

        dx *= nvl(this.props.scaling, 1) || 1
        dy *= nvl(this.props.scaling, 1) || 1

        const delta = (dx / innerWidth + dy / innerHeight) * Math.PI * (isAlt ? altFactor : 1)
        const adjustedForConstraints = delta * constraintDiff

        const newValue = this.guiValue - adjustedForConstraints
        const constrained = Math.min(Math.max(newValue, min), max)

        if (constrained != this.guiValue) {
            this.guiValue = constrained
            for (const id of flatten([(this.props as any).instance])) {
                const newModelValue = this.guiToModel(this.guiValue)
                this.props.store.player.session.devices[id].model.setValue(this.props.name, newModelValue, true)
            }
            if (this.props.onChange) {
                this.props.onChange(constrained)
            }
        }
    }

    onTouchStart = (e: TouchEvent) => {
        e.preventDefault()
        e.stopPropagation()
    }

    onTouchMove = (e: TouchEvent) => {
        e.preventDefault()
        e.stopPropagation()

        let dx = 0
        let dy = 0

        for (let i = 0; i < e.changedTouches.length; i++) {
            const t = e.changedTouches[i]

            if (this.prevX == null) {
                this.prevX = t.pageX
            } else {
                dx = this.prevX - t.pageX
                this.prevX = t.pageX
            }

            if (this.prevY == null) {
                this.prevY = t.pageY
            } else {
                dy = t.pageY - this.prevY
                this.prevY = t.pageY
            }

            this.updatePosition(dx, dy)
            break
        }
    }

    onTouchEnd = (e: any) => {
        e.preventDefault()
        e.stopPropagation()

        this.prevX = this.prevY = undefined
    }

    guiToModel = (v: number) => {
        if (this.props.guiToModel) {
            return this.props.guiToModel(v)
        } else {
            return Math.round(v)
        }
    }

    modelToGui = (v: number) => {
        if (this.props.modelToGui) {
            return this.props.modelToGui(v)
        } else {
            return v
        }
    }

    render() {
        return <SliderKnob {...this.props} display={(this.props as any).toDisplay(this.value)} onMouseDown={this.onMouseDown} onDoubleClick={this.onDoubleClick} onTouchStart={this.onTouchStart} onTouchEnd={this.onTouchEnd} onTouchMove={this.onTouchMove} />
    }
}

const KModelSliderKnob = mobx(SliderKnobParent)

export const ModelSliderKnob = (props: any) => <Context.Consumer>{({ id }) => <KModelSliderKnob {...props} instance={id} />}</Context.Consumer>
