import React from 'react'

import {ModelImageKnob, ModelToggleKnob, Context} from '../ImageKnob'
import {px, mobx} from '../../utils'
import Store from '../../store'

export default mobx(
    class ElysiaNvelope extends React.Component<{ id: string; store: Store; uiScale: number }> {
        static renderTitle() {
            return 'elysia xfilter'
        }

        render() {
            const {uiScale = 0.5, store, id} = this.props
            const {api} = store

            return (
                <Context.Provider value={{api, id} as any}>
                    <div
                        style={{
                            transform: `scale(${uiScale}, ${uiScale})`,
                            transformOrigin: 'left top',
                            margin: 0,
                            padding: 0,
                            width: px(2828 * uiScale),
                            height: px(259 * uiScale),
                        }}
                        onClick={e => e.preventDefault()}
                        onDoubleClick={e => e.preventDefault()}
                    >
                        <div
                            style={{
                                width: px(2828),
                                height: px(259),
                                margin: 0,
                                padding: 0,
                                background: `url(${require('./images/bg.png')})`,
                                backgroundAttachment: 'local',
                                backgroundSize: 'stretch',
                                boxShadow: 'black 0 4px 12px',
                                filter: store.globalBypass ? 'opacity(0.5)' : '',
                            }}
                            onClick={e => e.preventDefault()}
                            onDoubleClick={e => e.preventDefault()}
                        >
                            {/* ---- left knobs ---- */}
                            <ModelImageKnob x={310} y={70} nf={61} imageUrl={require('./images/low_shelf.png')}
                                            name={'lowGain'} initial={0.5}/>

                            <ModelImageKnob x={504} y={70} nf={21} imageUrl={require('./images/low_shelf_freq.png')}
                                            name={'lowFrequency'} initial={10}/>

                            <ModelImageKnob x={692} y={70} nf={61} imageUrl={require('./images/low_mid.png')}
                                            name={'lowMidGain'} initial={0.5}/>

                            <ModelImageKnob x={880} y={70} nf={21} imageUrl={require('./images/low_mid_freq.png')}
                                            name={'lowMidFrequency'} initial={10}/>

                            {/* ---- right knobs ---- */}
                            <ModelImageKnob x={1826} y={70} nf={61} imageUrl={require('./images/hi_mid.png')}
                                            name={'highMidGain'} initial={0.5}/>

                            <ModelImageKnob x={2014} y={70} nf={21} imageUrl={require('./images/hi_mid_freq.png')}
                                            name={'highMidFrequency'} initial={10}/>

                            <ModelImageKnob x={2202} y={70} nf={61} imageUrl={require('./images/hi_shelf.png')}
                                            name={'highGain'} initial={0.5}/>

                            <ModelImageKnob x={2390} y={70} nf={21} imageUrl={require('./images/hi_shelf_freq.png')}
                                            name={'highFrequency'} initial={10}/>

                            {/* ---- left toggles ---- */}
                            <ModelToggleKnob x={1066} y={108} name={'bypass'}
                                             imageUrl={require('./images/bypass.png')} />

                            <ModelToggleKnob x={1137} y={108} name={'lowCut'}
                                             imageUrl={require('./images/low_cut.png')}/>

                            <ModelToggleKnob x={1208} y={108} name={'lowMidQ'}
                                             imageUrl={require('./images/low_mid_q.png')}/>

                            {/* ---- right toggles ---- */}
                            <ModelToggleKnob x={1555} y={108} name={'highMidQ'}
                                             imageUrl={require('./images/high_mid_q.png')}/>

                            <ModelToggleKnob x={1626} y={108} name={'highCut'}
                                             imageUrl={require('./images/high_cut.png')}/>

                            <ModelToggleKnob x={1697} y={108} name={'passiveMassage'}
                                             imageUrl={require('./images/passive_massage.png')}/>

                            {/*<Overlay imageUrl={require('./images/elysia_on.jpg')} x={1348} y={65}/>*/}
                        </div>
                    </div>
                </Context.Provider>
            )
        }
    },
)
