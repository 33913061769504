import {mobx, px} from "../../utils";
import React from "react";
import Store from "../../store";
import {Device} from "../../api";
import {Context} from "../ImageKnob";

export default mobx(
    class DistopikN1084 extends React.Component<{ id: string; uiScale: number; store: Store, device: Device }> {
        static renderTitle() {
            return '1084 Stereo Line Amplifier'
        }

        render() {
            const {uiScale = 0.5, store, id} = this.props
            const {api} = store

            return (
                <Context.Provider value={{api, id} as any}>
                    <div
                        onClick={e => e.preventDefault()}
                        onDragStart={e => e.preventDefault()}
                        style={{
                            transform: `scale(${uiScale}, ${uiScale})`,
                            transformOrigin: 'left top',
                            margin: 0,
                            padding: 0,
                            width: px(1079 * uiScale),
                            height: px(510 * uiScale),
                        }}
                    >
                        <div
                            onClick={e => e.preventDefault()}
                            onDoubleClick={e => e.preventDefault()}
                            onDragStart={e => e.preventDefault()}
                            style={{
                                width: px(1079),
                                height: px(510),
                                margin: 0,
                                padding: 0,
                                background: `url(${require('./bg.jpg')})`,
                                backgroundAttachment: 'local',
                                backgroundSize: 'stretch',
                                boxShadow: 'black 0 4px 12px',
                                filter: store.globalBypass ? 'opacity(0.5)' : '',
                            }}
                        >
                        </div>
                    </div>
                </Context.Provider>)
        }
    }
)
