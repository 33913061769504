import React from 'react'
import { mobx } from '../../../utils'
import Store from '../../../store'

import { computed } from 'mobx'

interface Props {
    store: Store
    deviceType: string
}

class BypassButton extends React.Component<Props> {
    @computed
    get isBypassed() {
        const { store, deviceType } = this.props
        if (!store.player.session) {
            return false
        }

        return !store.player.session.orderPreferences.includes(deviceType)
    }

    handleBypassClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!this.props.store.player.deviceOrderData || !this.props.store.player.session) return
        const {
            store: {
                player: {
                    session: { orderPreferences },
                    deviceOrderData,
                },
            },
            deviceType,
        } = this.props

        let sessionOrderPreferences = deviceOrderData.filter(d => d.deviceType !== 'distopik/insert').map(d => d.deviceType)

        const index = sessionOrderPreferences.findIndex(d => d === deviceType)
        if (index !== -1) {
            if (!this.isBypassed) {
                sessionOrderPreferences = sessionOrderPreferences.filter(d => d !== deviceType)
            }
            sessionOrderPreferences = sessionOrderPreferences.filter(d => d === deviceType || orderPreferences.includes(d))
            this.props.store.changeOrderPreferences(sessionOrderPreferences)
        }
    }

    render() {
        return (
            <button className={`headerButton${this.isBypassed ? ' bypassActive' : ''}`} onClick={this.handleBypassClick}>
                BYPASS
            </button>
        )
    }
}

export default mobx(BypassButton)
